import { StripeElementsOptions } from '@stripe/stripe-js';

export const getStripeAmount = (amount: number) => {
  const amtStr = amount + '';
  const split = amtStr.split('.');
  const dollars = split[0];
  let cents = split[1] || '00';
  if (cents.length === 1) cents += '0';
  else if (cents.length > 2) cents = cents.slice(0, 2);
  return Number(dollars + cents);
};

export const getDollarsFromStripeAmount = (amount: number) => {
  const dollars = amount / 100;
  return Number(dollars.toFixed(2));
};

export const STRIPE_ELEMENT_OPTIONS: StripeElementsOptions = {
  appearance: {
    theme: 'flat',
    rules: {
      '.Input': {
        borderRadius: '4rem',
        backgroundColor: '#F4F4F4',
      },
      '.Input:focus': {
        outline: '2px solid #242E5F',
        boxShadow: 'none',
        backgroundColor: '#FFF',
      },
    },
    variables: {
      colorPrimary: '#242E5F',
    },
  },
  loader: 'always',
};
