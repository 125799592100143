import { ChevronLeftIcon, ChevronRightIcon } from '@c/icons';
import SafeImage from '@ui/SafeImage';
import { useEffect, useState } from 'react';
import BaseModal from './BaseModal';
interface ProductViewerModalProps {
  isOpen: boolean;
  dismiss: () => void;
  images: {
    full: string;
    thumb: string;
  }[];
  initialImage?: number;
}
const ProductViewerModal = ({
  isOpen,
  dismiss,
  images,
  initialImage = 0,
}: ProductViewerModalProps) => {
  const [currentImage, setCurrentImage] = useState(initialImage);
  const [zoomedIn, setZoomedIn] = useState(false);

  useEffect(() => {
    setZoomedIn(false);
  }, [currentImage]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'ArrowLeft') {
        if (currentImage > 0) {
          setCurrentImage(currentImage - 1);
        } else {
          setCurrentImage(images.length - 1);
        }
      } else if (e.key === 'ArrowRight') {
        if (currentImage < images.length - 1) {
          setCurrentImage(currentImage + 1);
        } else {
          setCurrentImage(0);
        }
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [currentImage, images.length]);

  const full = images[currentImage].full;
  const thumb = images[currentImage].thumb;
  return (
    <BaseModal isOpen={isOpen} dismiss={dismiss} isFullScreen>
      <div className="flex h-full w-full flex-col items-center overflow-auto">
        <div className="flex w-full grow justify-center">
          {/* left button */}
          <button
            onClick={() => {
              if (currentImage > 0) {
                setCurrentImage(currentImage - 1);
              } else {
                setCurrentImage(images.length - 1);
              }
            }}
          >
            <ChevronLeftIcon width={44} height={44} />
          </button>
          {/* image */}
          <div className="relative z-10 max-w-[80%] grow overflow-auto">
            <SafeImage
              key={full}
              onClick={() => setZoomedIn(!zoomedIn)}
              alt="current image"
              src={full}
              blurDataURL={thumb}
              draggable={false}
              fill
              className={`object-contain ${
                zoomedIn ? 'scale-[175%] cursor-zoom-out' : 'cursor-zoom-in'
              }`}
            />
          </div>
          {/* right button */}
          <button
            onClick={() => {
              if (currentImage < images.length - 1) {
                setCurrentImage(currentImage + 1);
              } else {
                setCurrentImage(0);
              }
            }}
          >
            <ChevronRightIcon width={44} height={44} />
          </button>
        </div>
        <div className="mt-[1.6rem] flex justify-center gap-[1.6rem] overflow-x-auto">
          {images.map((image, index) => (
            <SafeImage
              onClick={() => setCurrentImage(index)}
              className={`h-[8rem] w-[8rem] cursor-pointer transition-opacity ${
                index !== currentImage ? 'opacity-50' : ''
              }`}
              draggable={false}
              key={image.thumb}
              height={100}
              width={100}
              src={image.thumb}
              alt={`image ${index + 1}`}
            />
          ))}
        </div>
      </div>
    </BaseModal>
  );
};

export default ProductViewerModal;
