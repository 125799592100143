import {
  Category,
  Subcategory,
  SubcategoryCameraAccessories,
  SubcategoryCameraLensesFilters,
  SubcategoryCameraTripodsSupports,
  SubcategoryComputersElectronics,
  SubcategoryDigitalCameras,
  SubcategoryDronesAerialImaging,
  SubcategoryLightingStudio,
  SubcategoryVideoCamerasCamcorders,
  SubcategoryVideoProductionEditingEquipment,
  SubcategoryVintageCameraEquipment,
} from '@util/types/categories';

export const CATEGORY_LABEL: Record<Category, string> = {
  'digital-cameras': 'Digital Cameras',
  'camera-lenses-filters': 'Camera Lenses & Filters',
  'video-cameras-camcorders': 'Video Cameras & Camcorders',
  'lighting-studio': 'Lighting & Studio',
  'drones-aerial-imaging': 'Drones & Aerial Imaging',
  'camera-tripods-supports': 'Camera Tripods & Supports',
  'video-production-editing-equipment': 'Video Production & Editing Equipment',
  'vintage-camera-equipment': 'Vintage Camera Equipment',
  'camera-accessories': 'Camera Accessories',
  'computers-electronics': 'Computers & Electronics',
};

export const LABEL_CATEGORY: Record<string, Category> = {
  'Digital Cameras': 'digital-cameras',
  'Camera Lenses & Filters': 'camera-lenses-filters',
  'Video Cameras & Camcorders': 'video-cameras-camcorders',
  'Lighting & Studio': 'lighting-studio',
  'Drones & Aerial Imaging': 'drones-aerial-imaging',
  'Camera Tripods & Supports': 'camera-tripods-supports',
  'Video Production & Editing Equipment': 'video-production-editing-equipment',
  'Vintage Camera Equipment': 'vintage-camera-equipment',
  'Camera Accessories': 'camera-accessories',
  'Computers & Electronics': 'computers-electronics',
};

const SUBCATEGORY_DIGITAL_CAMERAS_LABEL: Record<
  SubcategoryDigitalCameras,
  string
> = {
  'digital-slr-cameras': 'Digital SLR Cameras',
  'mirrorless-system-cameras': 'Mirrorless System Cameras',
  'digital-medium-format-cameras': 'Digital Medium Format Cameras',
  'point-shoot-cameras': 'Point & Shoot Cameras',
};

const SUBCATEGORY_CAMERA_LENSES_FILTERS_LABEL: Record<
  SubcategoryCameraLensesFilters,
  string
> = {
  'slr-lenses': 'SLR Lenses',
  'mirrorless-system-lenses': 'Mirrorless System Lenses',
  'digital-cinema-lenses': 'Digital Cinema Lenses',
  'camera-lens-filters': 'Camera Lens Filters',
  'conversion-camera-lenses-adapters': 'Conversion Camera Lenses & Adapters',
  'camera-lens-filter-rings-holders': 'Camera Lens Filter Rings & Holders',
  'camera-lens-adapters-mounts-tubes': 'Camera Lens Adapters, Mounts, & Tubes',
  'camera-lens-hoods': 'Camera Lens Hoods',
  'camera-lens-caps': 'Camera Lens Caps',
};

const SUBCATEGORY_VIDEO_CAMERAS_CAMCORDERS_LABEL: Record<
  SubcategoryVideoCamerasCamcorders,
  string
> = {
  'professional-video-cinema-cameras': 'Professional Video & Cinema Cameras',
  'prosumer-camcorders': 'Prosumer Camcorders',
  'consumer-camcorders': 'Consumer Camcorders',
  'action-cameras': 'Action Cameras',
  '360-3d-cameras': '360 & 3D Cameras',
  'trail-wildlife-cameras': 'Trail & Wildlife Cameras',
};

const SUBCATEGORY_LIGHTING_STUDIO_LABEL: Record<
  SubcategoryLightingStudio,
  string
> = {
  'camera-flashes': 'Camera Flashes',
  'continuous-lighting': 'Continuous Lighting',
  'strobe-lighting': 'Strobe Lighting',
  'light-softboxes-reflectors': 'Light Softboxes & Reflectors',
  'lighting-accessories': 'Lightin Accessories',
  backgrounds: 'Backgrounds',
  'background-supports': 'Background Supports',
};

const SUBCATEGORY_DRONES_AERIAL_IMAGING_LABEL: Record<
  SubcategoryDronesAerialImaging,
  string
> = {
  drones: 'Drones',
  'drone-parts-accessories': 'Drone Parts & Accessories',
};

const SUBCATEGORY_CAMERA_TRIPODS_SUPPORTS_LABEL: Record<
  SubcategoryCameraTripodsSupports,
  string
> = {
  'tripods-monopods': 'Tripods & Monopods',
  'camera-stabilizers-gimbals': 'Camera Stabilizers & Gimbals',
  'tripod-heads-for-camera-video': 'Tripod Heads for Camera & Video',
  'camera-sliders': 'Camera Sliders',
  'camera-jibs-booms': 'Camera Jibs & Booms',
  'quick-release-systems-plates': 'Quick Release Systems & Plates',
};

const SUBCATEGORY_VIDEO_PRODUCTION_EDITING_EQUIPMENT_LABEL: Record<
  SubcategoryVideoProductionEditingEquipment,
  string
> = {
  'professional-video-switchers': 'Professional Video Switchers',
  'video-production-monitors': 'Video Production Monitors',
  'audio-for-video-equipment': 'Audio for Video Equipment',
  'video-recorders-players': 'Video Recorders & Players',
  'video-production-converters-scalers-processors':
    'Video Production Converters, Scalers & Processors',
  'video-production-accessories': 'Video Production Accessories',
};

const SUBCATEGORY_VINTAGE_CAMERA_EQUIPMENT_LABEL: Record<
  SubcategoryVintageCameraEquipment,
  string
> = {
  'vintage-film-cameras': 'Vintage Film Cameras',
  'vintage-lenses': 'Vintage Lenses',
  'vintage-rangefinder-cameras': 'Vintage Rangefinder Cameras',
  'vintage-view-cameras': 'Vintage View Cameras',
  'vintage-box-cameras': 'Vintage Box Cameras',
  'vintage-movie-cameras': 'Vintage Movie Cameras',
  'darkroom-developing-equipment': 'Darkroom & Developing Equipment',
  film: 'Film',
  'vintage-instant-cameras': 'Vintage Instant Cameras',
  'slide-movie-projectors': 'Slide & Movie Projectors',
};

const SUBCATEGORY_CAMERA_ACCESSORIES_LABEL: Record<
  SubcategoryCameraAccessories,
  string
> = {
  'camera-cages-rigs': 'Camera Cages & Rigs',
  'memory-cards': 'Memory Cards',
  'camera-bags-cases': 'Camera Bags & Cases',
  'batteries-chargers': 'Batteries & Chargers',
  'books-apparel': 'Books & Apparel',
};

const SUBCATEGORY_COMPUTERS_ELECTRONICS_LABEL: Record<
  SubcategoryComputersElectronics,
  string
> = {
  'video-editing-desktops': 'Video Editing Desktops',
  'video-editing-laptops': 'Video Editing Laptops',
  'video-editing-monitors': 'Video Editing Monitors',
  'hard-drives': 'Hard Drives',
  'smart-phones': 'Smart Phones',
  // 'luts-presets': 'LUTs & Presets',
};

export const SUBCATEGORY_LABEL: Record<Subcategory, string> = {
  ...SUBCATEGORY_DIGITAL_CAMERAS_LABEL,
  ...SUBCATEGORY_CAMERA_LENSES_FILTERS_LABEL,
  ...SUBCATEGORY_VIDEO_CAMERAS_CAMCORDERS_LABEL,
  ...SUBCATEGORY_LIGHTING_STUDIO_LABEL,
  ...SUBCATEGORY_DRONES_AERIAL_IMAGING_LABEL,
  ...SUBCATEGORY_CAMERA_TRIPODS_SUPPORTS_LABEL,
  ...SUBCATEGORY_VIDEO_PRODUCTION_EDITING_EQUIPMENT_LABEL,
  ...SUBCATEGORY_VINTAGE_CAMERA_EQUIPMENT_LABEL,
  ...SUBCATEGORY_CAMERA_ACCESSORIES_LABEL,
  ...SUBCATEGORY_COMPUTERS_ELECTRONICS_LABEL,
};

export const LABEL_SUBCATEGORY: Record<string, Subcategory> = {
  ...Object.entries(SUBCATEGORY_DIGITAL_CAMERAS_LABEL).reduce(
    (acc, [key, value]) => ({ ...acc, [value]: key }),
    {}
  ),
  ...Object.entries(SUBCATEGORY_CAMERA_LENSES_FILTERS_LABEL).reduce(
    (acc, [key, value]) => ({ ...acc, [value]: key }),
    {}
  ),
  ...Object.entries(SUBCATEGORY_VIDEO_CAMERAS_CAMCORDERS_LABEL).reduce(
    (acc, [key, value]) => ({ ...acc, [value]: key }),
    {}
  ),
  ...Object.entries(SUBCATEGORY_LIGHTING_STUDIO_LABEL).reduce(
    (acc, [key, value]) => ({ ...acc, [value]: key }),
    {}
  ),
  ...Object.entries(SUBCATEGORY_DRONES_AERIAL_IMAGING_LABEL).reduce(
    (acc, [key, value]) => ({ ...acc, [value]: key }),
    {}
  ),
  ...Object.entries(SUBCATEGORY_CAMERA_TRIPODS_SUPPORTS_LABEL).reduce(
    (acc, [key, value]) => ({ ...acc, [value]: key }),
    {}
  ),
  ...Object.entries(
    SUBCATEGORY_VIDEO_PRODUCTION_EDITING_EQUIPMENT_LABEL
  ).reduce((acc, [key, value]) => ({ ...acc, [value]: key }), {}),
  ...Object.entries(SUBCATEGORY_VINTAGE_CAMERA_EQUIPMENT_LABEL).reduce(
    (acc, [key, value]) => ({ ...acc, [value]: key }),
    {}
  ),
  ...Object.entries(SUBCATEGORY_CAMERA_ACCESSORIES_LABEL).reduce(
    (acc, [key, value]) => ({ ...acc, [value]: key }),
    {}
  ),
  ...Object.entries(SUBCATEGORY_COMPUTERS_ELECTRONICS_LABEL).reduce(
    (acc, [key, value]) => ({ ...acc, [value]: key }),
    {}
  ),
};
