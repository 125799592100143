import { ReactNode, useState } from 'react';

export type TabItem = {
  title: string;
  hidden?: boolean;
  contents: ReactNode;
  unreads?: number;
};
type TabsProps = {
  full?: boolean;
  tabItems: TabItem[];
  hidden?: boolean;
  unreads?: number;
  initialTab?: number;
};

export function TabButton({
  title,
  hidden,
  onClick,
  isCurrent,
  unreads,
}: {
  title: string;
  hidden?: boolean;
  onClick: () => void;
  isCurrent: boolean;
  unreads?: number;
}) {
  return (
    <button
      type="button"
      onClick={onClick}
      className={`grow whitespace-nowrap px-[1.6rem] py-[2rem] font-semibold transition-colors hover:text-brand-primary focus:text-brand-primary focus:outline-none lg:px-[3.2rem] ${
        isCurrent ? 'border-b-2 border-brand-primary' : 'text-brand-black'
      }

      ${hidden ? 'hidden' : 'block'}
        `}
    >
      <div className="relative">
        {title}
        {!!unreads && (
          <div className="absolute -right-[0.4rem] -top-[0.4rem] flex h-[1.5rem] w-[1.5rem] items-center justify-center rounded-full bg-brand-red text-lg font-semibold leading-[1.6rem] text-brand-white">
            {unreads}
          </div>
        )}
      </div>
    </button>
  );
}

const Tabs = ({ tabItems: tabs, full, unreads, initialTab }: TabsProps) => {
  const [current, setCurrent] = useState<number>(initialTab ?? 0);
  const tabNavItems = tabs.map((tab) => tab.title);

  // useEffect(() => {
  //   // append the current tab to the url
  //   const curr = tabs[current]?.title;
  //   if (!curr) return;
  //   const url = new URL(window.location.href);
  //   url.searchParams.set('tab', tabs[current].title.toLowerCase());
  //   window.history.replaceState({}, '', url.toString());
  // }, [current, tabs]);

  return (
    <div>
      <div
        className={`flex justify-between w-${
          full ? 'full' : 'min'
        } overflow-x-auto`}
      >
        {tabNavItems.map((item, i) => {
          const isCurrent = current === i;
          return (
            <TabButton
              key={item}
              hidden={tabs[i].hidden}
              title={item}
              onClick={() => setCurrent(i)}
              isCurrent={isCurrent}
              unreads={unreads}
            />
          );
        })}
      </div>
      <hr className="w-full" />
      {/* Render all tabs and conditionally hide so that it doesn't have to re-mount the contents on tab change */}
      {tabs.map((tab, i) => {
        return (
          <div
            key={tab.title}
            className={`${current === i ? 'contents' : 'hidden'}`}
          >
            {tab.contents}
          </div>
        );
      })}
    </div>
  );
};

export default Tabs;
