export const Smartphone = () => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M44.5258 58H24.457C20.882 58 17.957 55.075 17.957 51.5V12.5C17.957 8.925 20.882 6 24.457 6H44.607C48.182 6 51.107 8.925 51.107 12.5V51.5C51.0258 55.075 48.1008 58 44.5258 58Z"
      fill="#EF9021"
    />
    <path
      d="M37.2891 55.5623H31.7641C31.0328 55.5623 30.3828 54.9123 30.3828 54.1811C30.3828 53.4498 31.0328 52.7998 31.7641 52.7998H37.2891C38.0203 52.7998 38.6703 53.4498 38.6703 54.1811C38.5891 54.9936 38.0203 55.5623 37.2891 55.5623Z"
      fill="#F4F4F4"
    />
    <path
      d="M28.3364 15.75C36.7524 15.75 43.6718 21.5374 43.6718 28.6514C43.6718 31.495 42.5688 34.3851 40.546 36.625L43.4024 39.8892C43.6835 40.2108 43.7517 40.6675 43.5738 41.0571C43.3971 41.4468 43.0095 41.697 42.5816 41.697H27.2462C21.0323 41.697 13.001 36.3462 13.001 28.6514C13.001 21.5374 19.9204 15.75 28.3364 15.75ZM34.9503 30.6497C36.1523 30.6497 37.1306 29.6712 37.1306 28.4693C37.1306 27.2673 36.1522 26.2889 34.9503 26.2889C33.7483 26.2889 32.7699 27.2674 32.7699 28.4693C32.7698 29.6712 33.7482 30.6497 34.9503 30.6497ZM28.3364 30.6497C29.5384 30.6497 30.5168 29.6712 30.5168 28.4693C30.5168 27.2673 29.5383 26.2889 28.3364 26.2889C27.1345 26.2889 26.156 27.2674 26.156 28.4693C26.156 29.6712 27.1344 30.6497 28.3364 30.6497ZM21.7225 30.6497C22.9245 30.6497 23.9029 29.6712 23.9029 28.4693C23.9029 27.2673 22.9245 26.2889 21.7225 26.2889C20.5206 26.2889 19.5422 27.2674 19.5422 28.4693C19.5422 29.6712 20.5205 30.6497 21.7225 30.6497Z"
      fill="#242E5F"
    />
    <path
      d="M21.7633 30.7395C23.0197 30.7395 24.0383 29.7209 24.0383 28.4645C24.0383 27.208 23.0197 26.1895 21.7633 26.1895C20.5068 26.1895 19.4883 27.208 19.4883 28.4645C19.4883 29.7209 20.5068 30.7395 21.7633 30.7395Z"
      fill="white"
    />
    <path
      d="M28.3336 30.7395C29.59 30.7395 30.6086 29.7209 30.6086 28.4645C30.6086 27.208 29.59 26.1895 28.3336 26.1895C27.0771 26.1895 26.0586 27.208 26.0586 28.4645C26.0586 29.7209 27.0771 30.7395 28.3336 30.7395Z"
      fill="white"
    />
    <path
      d="M34.8922 30.7395C36.1486 30.7395 37.1672 29.7209 37.1672 28.4645C37.1672 27.208 36.1486 26.1895 34.8922 26.1895C33.6357 26.1895 32.6172 27.208 32.6172 28.4645C32.6172 29.7209 33.6357 30.7395 34.8922 30.7395Z"
      fill="white"
    />
  </svg>
);
