export const Printer = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 6.75V3.625H15V6.75"
      stroke="#242E5F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 12.375H5V17.6875H15V12.375Z"
      stroke="#242E5F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 14.25H2.1875V8C2.1875 7.3125 2.79687 6.75 3.53906 6.75H16.4609C17.2031 6.75 17.8125 7.3125 17.8125 8V14.25H15"
      stroke="#242E5F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.6875 10.5C15.2053 10.5 15.625 10.0803 15.625 9.5625C15.625 9.04473 15.2053 8.625 14.6875 8.625C14.1697 8.625 13.75 9.04473 13.75 9.5625C13.75 10.0803 14.1697 10.5 14.6875 10.5Z"
      fill="#242E5F"
    />
  </svg>
);
