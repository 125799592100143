export const EmptyWallet = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.0335 11.2923C14.6835 11.634 14.4835 12.1256 14.5335 12.6506C14.6085 13.5506 15.4335 14.209 16.3335 14.209H17.9168V15.2006C17.9168 16.9256 16.5085 18.334 14.7835 18.334H5.21683C3.49183 18.334 2.0835 16.9256 2.0835 15.2006V9.59233C2.0835 7.86733 3.49183 6.45898 5.21683 6.45898H14.7835C16.5085 6.45898 17.9168 7.86733 17.9168 9.59233V10.7923H16.2335C15.7668 10.7923 15.3418 10.9756 15.0335 11.2923Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.0835 10.3421V6.53379C2.0835 5.54212 2.69183 4.65875 3.61683 4.30875L10.2335 1.80875C11.2668 1.41708 12.3752 2.18377 12.3752 3.29211V6.45876"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.7992 11.641V13.3577C18.7992 13.816 18.4325 14.191 17.9658 14.2077H16.3325C15.4325 14.2077 14.6075 13.5493 14.5325 12.6493C14.4825 12.1243 14.6825 11.6327 15.0325 11.291C15.3408 10.9743 15.7658 10.791 16.2325 10.791H17.9658C18.4325 10.8077 18.7992 11.1826 18.7992 11.641Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.8335 10H11.6668"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
