import { CloseIcon } from '@c/icons';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useEffect } from 'react';
import { cva } from 'class-variance-authority';
import { useRouter } from 'next/router';

const styles = cva(
  'text-[1.5rem] flex flex-col relative bg-brand-white text-left text-brand-black shadow-xl transition-all max-h-screen ',
  {
    variants: {
      isFullScreen: {
        true: 'w-full h-full',
        false:
          'w-fit lg:max-w-[50%] min-h-[32rem] min-w-[32rem] p-[2.4rem] rounded-2xl',
      },
      overflowType: {
        auto: 'overflow-auto',
        visible: 'overflow-y-visible',
      },
    },
  }
);
interface BaseModalProps {
  isOpen: boolean;
  dismiss?: () => void;
  children?: React.ReactNode;
  title?: React.ReactNode;
  hideCloseIcon?: boolean;
  isFullScreen?: boolean;
  disableBackdropDismiss?: boolean;
  noPadding?: boolean;
  overflowType?: 'auto' | 'visible';
}

function BaseModal({
  children,
  title,
  hideCloseIcon,
  isOpen,
  dismiss,
  isFullScreen = false,
  disableBackdropDismiss = false,
  overflowType = 'auto',
}: BaseModalProps) {
  const router = useRouter();

  useEffect(() => {
    //Close modal when navigating back
    router.beforePopState(({ as }) => {
      if (as !== router.asPath) {
        dismiss?.();
      }
      return true;
    });
    return () => {
      router.beforePopState(() => true);
    };
  }, [router, dismiss]);
  const showTitleSection = !!title || !hideCloseIcon;
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={() => dismiss?.()}
        static={disableBackdropDismiss}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {disableBackdropDismiss ? (
            <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
          ) : (
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          )}
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex h-full items-center justify-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className={styles({ isFullScreen, overflowType })}>
                {showTitleSection && (
                  <div
                    className={`mb-[2.4rem] flex items-center ${
                      !!title ? 'justify-between' : 'justify-end'
                    }`}
                  >
                    {!!title && <Dialog.Title>{title}</Dialog.Title>}
                    {!hideCloseIcon && (
                      <button
                        className="m-4"
                        type="button"
                        onClick={() => dismiss?.()}
                      >
                        <CloseIcon />
                      </button>
                    )}
                  </div>
                )}
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default BaseModal;
