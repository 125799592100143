import { functions, httpsCallable } from '@util/firebase';
import { PaymentDocument } from '@util/types/firestore/payment';
import Stripe from 'stripe';
import {
  CreatePaymentArgs,
  ExternalAccount,
  PayoutResponse,
  StripeAccountResponse,
  StripeTransaction,
  UpdatePaymentArgs,
} from './payments.types';

interface PaymentIntent {
  data: string;
  pi_id: string;
}

export async function createPayment(
  args: CreatePaymentArgs
): Promise<PaymentIntent> {
  const res = await httpsCallable<CreatePaymentArgs, PaymentIntent>(
    functions,
    'createPaymentIntent'
  )(args);
  return res.data;
}

export async function updatePayment(
  args: UpdatePaymentArgs
): Promise<PaymentIntent> {
  const res = await httpsCallable<UpdatePaymentArgs, PaymentIntent>(
    functions,
    'updatePaymentIntent'
  )(args);
  return res.data;
}

type PayWithBalanceArgs = Omit<UpdatePaymentArgs, 'pi_id'>;
export async function payWithBalance(
  args: PayWithBalanceArgs
): Promise<Stripe.Response<Stripe.Charge>> {
  const res = await httpsCallable<
    PayWithBalanceArgs,
    Stripe.Response<Stripe.Charge>
  >(
    functions,
    'payWithBalance'
  )(args);
  return res.data;
}

export async function createSetupIntent(): Promise<PaymentIntent> {
  const res = await httpsCallable<{}, PaymentIntent>(
    functions,
    'stripe-createSetupIntent'
  )({});
  return res.data;
}

export async function getPaymentMethods(): Promise<PaymentDocument[]> {
  // Making an HTTPS call to a cloud function named 'getPaymentMethods'
  // The await keyword is used to wait for the asynchronous call to resolve
  const res = await httpsCallable<{}, any>(functions, 'getPaymentMethods')();

  // Mapping the response data to an array of PaymentDocument objects
  // Each item in the response data is transformed to fit the PaymentDocument structure
  const cards: PaymentDocument[] = res.data.data.map((pm: any) => ({
    id: pm.id, // The unique identifier of the payment method
    last4: pm.card.last4, // The last four digits of the card number
    brand: pm.card.brand, // The brand of the card (e.g., Visa, MasterCard)
    name: pm.billing_details.name, // The name on the billing details
    expMonth: `${pm.card.exp_month}`, // The card's expiration month, converted to a string
    expYear: `${pm.card.exp_year}`, // The card's expiration year, also converted to a string
  }));

  // Returning the array of PaymentDocument objects
  return cards;
}

export async function deleteCard(pm_id: string): Promise<any> {
  const res = await httpsCallable<{}, any>(
    functions,
    'deleteCard'
  )({
    payment_method: pm_id,
  });
  return res.data;
}

export async function getBalance() {
  const res = await httpsCallable<
    {},
    { available_balance: number; actual_pending: number; currency: string }
  >(
    functions,
    'getBalance'
  )({});
  return res.data;
}

export async function getTransactionHistory() {
  const res = await httpsCallable<{}, { data: StripeTransaction[] }>(
    functions,
    'listTransactions'
  )({});
  return res.data?.data ?? [];
}

export async function payoutSeller(
  amount: number,
  method: 'standard' | 'instant',
  destination: string,
  currency = 'usd',
  requirements?: { [x: string]: { [x: string]: string } }
): Promise<PayoutResponse> {
  const res = await httpsCallable<{}, PayoutResponse>(
    functions,
    'payoutSeller'
  )({
    amount,
    method,
    destination,
    currency,
    requirements,
  });
  return res.data;
}

export async function getStripeAccount() {
  const res = await httpsCallable<{}, StripeAccountResponse>(
    functions,
    'getSeller'
  )({});
  return res.data;
}

export async function updateSellerAccount(
  requirements: Record<string, string>[]
) {
  const res = await httpsCallable<{}, { status: boolean }>(
    functions,
    'updateStripeAccount'
  )({
    requirements,
  });
  return res.data;
}

export async function addSellerPayoutMethod(account_id: string, id: string) {
  const res = await httpsCallable<{}, ExternalAccount>(
    functions,
    'addSellerPayoutMethod'
  )({ account_id, id });
  return res.data;
}

export async function removeSellerPayoutMethod(account_id: string, id: string) {
  const res = await httpsCallable<{}, ExternalAccount>(
    functions,
    'removeExternalAccount'
  )({ account_id, id });
  return res.data;
}

export async function chargeAccount(
  account_id: string,
  amount: number,
  description = ''
) {
  const res = await httpsCallable<{}, string>(
    functions,
    'chargeAccount'
  )({ account_id, amount, description });
  // returns the charge_id
  return res.data;
}

export async function chargeCreditCard(
  paymentMethodId: string,
  customerId: string,
  amount: number,
  description = ''
) {
  const res = await httpsCallable<{}, string>(
    functions,
    'stripe-chargeCreditCard'
  )({ paymentMethodId, customerId, amount, description });
  // returns the charge_id
  return res.data;
}

export async function createTransfer(
  account_id: string,
  amount: number,
  description = ''
) {
  const res = await httpsCallable<{}, string>(
    functions,
    'createTransfer'
  )({ account_id, amount, description });
  // returns the charge_id
  return res.data;
}

// type Status =
// | 'canceled'
// | 'processing'
// | 'requires_action'
// | 'requires_capture'
// | 'requires_confirmation'
// | 'requires_payment_method'
// | 'succeeded'
// | 'failed'
// | 'pending';
export async function getPaymentInfo(payment_id: string) {
  const res = await httpsCallable<{}, Stripe.Charge | Stripe.PaymentIntent>(
    functions,
    'getPaymentInfo'
  )({ payment_id });
  return res.data;
}
