import SafeImage from './SafeImage';
import { cva, VariantProps } from 'class-variance-authority';
import { UserDocument } from '../../utils/types/firestore/users';
import { useNotifications } from '@util/hooks/useNotifcations';

const styles = cva(
  'relative rounded-full overflow-hidden flex items-center justify-center',
  {
    variants: {
      size: {
        extraSmall: 'h-[3.2rem] w-[3.2rem]',
        small: 'h-touch w-touch',
        medium: 'h-[5.6rem] w-[5.6rem]',
        mlarge: 'h-[8rem] w-[8rem]',
        large: 'h-[9.6rem] w-[9.6rem]',
      },
    },
    defaultVariants: {
      size: 'medium',
    },
  }
);
interface AvatarProps extends VariantProps<typeof styles> {
  size?: 'extraSmall' | 'small' | 'medium' | 'mlarge' | 'large';
  user?: UserDocument;
  showNotificationCount?: boolean;
}

// const getUserInitials = (user: UserDocument) => {
//   const names = user.name?.split(' ');
//   if (!names?.length) return user?.email?.[0].toUpperCase() ?? '##';
//   const i = names.map((n) => n[0]);
//   if (i.length === 1) return i[0].toUpperCase();
//   return `${i[0]}${i[i.length - 1]}`.toUpperCase();
// };

const Avatar = ({ user, size, showNotificationCount }: AvatarProps) => {
  let src, alt, initials;
  if (user) {
    src = user.photo;
    alt = user.username;
    initials = user.username?.slice(0, 2);
  }
  const { total } = useNotifications();
  return (
    <div className="relative">
      <div className={styles({ size })}>
        {src ? (
          <SafeImage
            src={src}
            alt={alt || 'Profile Picture'}
            width={40}
            height={40}
            unoptimized
            fill
            key={src}
            style={{ objectFit: 'cover' }}
            itemProp="image"
          />
        ) : (
          <div className="absolute inset-0 flex items-center justify-center bg-brand-secondary">
            <span className="text-[1.5rem] font-bold uppercase text-brand-white">
              {initials || 'NA'}
            </span>
          </div>
        )}
      </div>
      {showNotificationCount && total > 0 && (
        <div className="absolute right-0 top-0 flex h-[1.6rem] w-[1.6rem] items-center justify-center rounded-full bg-brand-red text-[1.2rem] font-medium text-brand-white ">
          {total}
        </div>
      )}
    </div>
  );
};

export default Avatar;
