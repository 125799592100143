import { z } from 'zod';

export const ChatMessagesDocumentSchema = z.object({
  uid: z.string(),
  content: z.string(),
  is_img: z.boolean().default(false),
  created_at: z.number().optional(),
  thumbnail: z.string().optional(),
  action: z.object({
    type: z.string(),
    disabled: z.boolean().default(false),
    click: z.string()
  }).optional(),
  skip_notification: z.boolean().optional(),
});

export const ChatDocumentSchema = z.object({
  product_id: z.string().optional(),
  offer_id: z.string().optional(),
  is_offer: z.boolean().default(false),
  order_id: z.string().optional(),
  order_num: z.number().optional(),
  case_num: z.number().optional(),
  seller_id: z.string(),
  buyer_id: z.string(),

  is_bot: z.boolean().default(false),

  id: z.string().optional(),
  unread: z.record(z.boolean()),
  read: z.boolean().optional(),
  last_time: z.number().optional().default(0),
  messages: z.array(ChatMessagesDocumentSchema).optional(),

  created_at: z.number(),
  flagged: z.boolean().optional().default(false),
  from_web: z.boolean().optional().default(true),
  uids: z.array(z.string()).length(2).optional(), // [buyer_id, seller_id]
});

export type ChatMessageDocument = z.infer<typeof ChatMessagesDocumentSchema>;
export type ChatDocument = z.infer<typeof ChatDocumentSchema>;
