import {
  Category,
  CategoryRoute,
  Subcategory,
  SubcategoryRoute,
} from '@util/types/categories';

export const CATEGORIES = [
  'digital-cameras',
  'camera-lenses-filters',
  'video-cameras-camcorders',
  'lighting-studio',
  'drones-aerial-imaging',
  'camera-tripods-supports',
  'video-production-editing-equipment',
  'vintage-camera-equipment',
  'camera-accessories',
  'computers-electronics',
] as const;
export const CATEGORY_ROUTES = [
  'digital-cameras-9',
  'camera-lenses-filters-12',
  'video-cameras-camcorders-27',
  'lighting-studio-20',
  'drones-aerial-imaging-10',
  'camera-tripods-supports-35',
  'video-production-editing-equipment-65',
  'vintage-camera-equipment-40',
  'camera-accessories-76',
  'computers-electronics-3',
] as const;

export const ROUTE_CATEGORY_MAP: Record<CategoryRoute, Category> = {
  'digital-cameras-9': 'digital-cameras',
  'camera-lenses-filters-12': 'camera-lenses-filters',
  'video-cameras-camcorders-27': 'video-cameras-camcorders',
  'lighting-studio-20': 'lighting-studio',
  'drones-aerial-imaging-10': 'drones-aerial-imaging',
  'camera-tripods-supports-35': 'camera-tripods-supports',
  'video-production-editing-equipment-65': 'video-production-editing-equipment',
  'vintage-camera-equipment-40': 'vintage-camera-equipment',
  'camera-accessories-76': 'camera-accessories',
  'computers-electronics-3': 'computers-electronics',
};
export const CATEGORY_ROUTE_MAP: Record<Category, CategoryRoute> = {
  'digital-cameras': 'digital-cameras-9',
  'camera-lenses-filters': 'camera-lenses-filters-12',
  'video-cameras-camcorders': 'video-cameras-camcorders-27',
  'lighting-studio': 'lighting-studio-20',
  'drones-aerial-imaging': 'drones-aerial-imaging-10',
  'camera-tripods-supports': 'camera-tripods-supports-35',
  'video-production-editing-equipment': 'video-production-editing-equipment-65',
  'vintage-camera-equipment': 'vintage-camera-equipment-40',
  'camera-accessories': 'camera-accessories-76',
  'computers-electronics': 'computers-electronics-3',
};

export const SUBCATEGORY_ROUTE_MAP: Record<Subcategory, SubcategoryRoute> = {
  //
  'digital-slr-cameras': 'digital-slr-cameras-73',
  'mirrorless-system-cameras': 'mirrorless-system-cameras-74',
  'digital-medium-format-cameras': 'digital-medium-format-cameras-64',
  'point-shoot-cameras': 'point-shoot-cameras-75',
  //
  'slr-lenses': 'slr-lenses-14',
  'mirrorless-system-lenses': 'mirrorless-system-lenses-115',
  'digital-cinema-lenses': 'digital-cinema-lenses-142',
  'camera-lens-filters': 'camera-lens-filters-13',
  'conversion-camera-lenses-adapters': 'conversion-camera-lenses-adapters-19',
  'camera-lens-filter-rings-holders': 'camera-lens-filter-rings-holders-15',
  'camera-lens-adapters-mounts-tubes': 'camera-lens-adapters-mounts-tubes-16',
  'camera-lens-hoods': 'camera-lens-hoods-17',
  'camera-lens-caps': 'camera-lens-caps-18',
  //
  'professional-video-cinema-cameras': 'professional-video-cinema-cameras-30',
  'prosumer-camcorders': 'prosumer-camcorders-28',
  'consumer-camcorders': 'consumer-camcorders-29',
  'action-cameras': 'action-cameras-33',
  '360-3d-cameras': '360-3d-cameras-86',
  'trail-wildlife-cameras': 'trail-wildlife-cameras-318',
  //
  'camera-flashes': 'camera-flashes-21',
  'continuous-lighting': 'continuous-lighting-69',
  'strobe-lighting': 'strobe-lighting-116',
  'light-softboxes-reflectors': 'light-softboxes-reflectors-24',
  'lighting-accessories': 'lighting-accessories-26',
  backgrounds: 'backgrounds-22',
  'background-supports': 'background-supports-23',
  //
  drones: 'drones-107',
  'drone-parts-accessories': 'drone-parts-accessories-32',
  //
  'tripods-monopods': 'tripods-monopods-36',
  'camera-stabilizers-gimbals': 'camera-stabilizers-gimbals-37',
  'tripod-heads-for-camera-video': 'tripod-heads-for-camera-video-38',
  'camera-sliders': 'camera-sliders-131',
  'camera-jibs-booms': 'camera-jibs-booms-262',
  'quick-release-systems-plates': 'quick-release-systems-plates-39',
  //
  'professional-video-switchers': 'professional-video-switchers-320',
  'video-production-monitors': 'video-production-monitors-71',
  'audio-for-video-equipment': 'audio-for-video-equipment-66',
  'video-recorders-players': 'video-recorders-players-72',
  'video-production-converters-scalers-processors':
    'video-production-converters-scalers-processors-67',
  'video-production-accessories': 'video-production-accessories-68',
  //
  'vintage-film-cameras': 'vintage-film-cameras-50',
  'vintage-lenses': 'vintage-lenses-317',
  'vintage-rangefinder-cameras': 'vintage-rangefinder-cameras-49',
  'vintage-view-cameras': 'vintage-view-cameras-41',
  'vintage-box-cameras': 'vintage-box-cameras-43',
  'vintage-movie-cameras': 'vintage-movie-cameras-44',
  'darkroom-developing-equipment': 'darkroom-developing-equipment-51',
  film: 'film-321',
  'vintage-instant-cameras': 'vintage-instant-cameras-46',
  'slide-movie-projectors': 'slide-movie-projectors-55',
  //
  'camera-cages-rigs': 'camera-cages-rigs-140',
  'memory-cards': 'memory-cards-87',
  'camera-bags-cases': 'camera-bags-cases-77',
  'batteries-chargers': 'batteries-chargers-139',
  'books-apparel': 'books-apparel-144',
  //
  'video-editing-desktops': 'video-editing-desktops-81',
  'video-editing-laptops': 'video-editing-laptops-82',
  'video-editing-monitors': 'video-editing-monitors-84',
  'hard-drives': 'hard-drives-83',
  'smart-phones': 'smart-phones-85',
  // 'luts-presets': 'luts-presets-216',
};
export const ROUTE_SUBCATEGORY_MAP: Record<SubcategoryRoute, Subcategory> = {
  'digital-slr-cameras-73': 'digital-slr-cameras',
  'mirrorless-system-cameras-74': 'mirrorless-system-cameras',
  'digital-medium-format-cameras-64': 'digital-medium-format-cameras',
  'point-shoot-cameras-75': 'point-shoot-cameras',
  //
  'slr-lenses-14': 'slr-lenses',
  'mirrorless-system-lenses-115': 'mirrorless-system-lenses',
  'digital-cinema-lenses-142': 'digital-cinema-lenses',
  'camera-lens-filters-13': 'camera-lens-filters',
  'conversion-camera-lenses-adapters-19': 'conversion-camera-lenses-adapters',
  'camera-lens-filter-rings-holders-15': 'camera-lens-filter-rings-holders',
  'camera-lens-adapters-mounts-tubes-16': 'camera-lens-adapters-mounts-tubes',
  'camera-lens-hoods-17': 'camera-lens-hoods',
  'camera-lens-caps-18': 'camera-lens-caps',
  //
  'professional-video-cinema-cameras-30': 'professional-video-cinema-cameras',
  'prosumer-camcorders-28': 'prosumer-camcorders',
  'consumer-camcorders-29': 'consumer-camcorders',
  'action-cameras-33': 'action-cameras',
  '360-3d-cameras-86': '360-3d-cameras',
  'trail-wildlife-cameras-318': 'trail-wildlife-cameras',
  //
  'camera-flashes-21': 'camera-flashes',
  'continuous-lighting-69': 'continuous-lighting',
  'strobe-lighting-116': 'strobe-lighting',
  'light-softboxes-reflectors-24': 'light-softboxes-reflectors',
  'lighting-accessories-26': 'lighting-accessories',
  'backgrounds-22': 'backgrounds',
  'background-supports-23': 'background-supports',
  //
  'drones-107': 'drones',
  'drone-parts-accessories-32': 'drone-parts-accessories',
  //
  'tripods-monopods-36': 'tripods-monopods',
  'camera-stabilizers-gimbals-37': 'camera-stabilizers-gimbals',
  'tripod-heads-for-camera-video-38': 'tripod-heads-for-camera-video',
  'camera-sliders-131': 'camera-sliders',
  'camera-jibs-booms-262': 'camera-jibs-booms',
  'quick-release-systems-plates-39': 'quick-release-systems-plates',
  //
  'professional-video-switchers-320': 'professional-video-switchers',
  'video-production-monitors-71': 'video-production-monitors',
  'audio-for-video-equipment-66': 'audio-for-video-equipment',
  'video-recorders-players-72': 'video-recorders-players',
  'video-production-converters-scalers-processors-67':
    'video-production-converters-scalers-processors',
  'video-production-accessories-68': 'video-production-accessories',
  //
  'vintage-film-cameras-50': 'vintage-film-cameras',
  'vintage-lenses-317': 'vintage-lenses',
  'vintage-rangefinder-cameras-49': 'vintage-rangefinder-cameras',
  'vintage-view-cameras-41': 'vintage-view-cameras',
  'vintage-box-cameras-43': 'vintage-box-cameras',
  'vintage-movie-cameras-44': 'vintage-movie-cameras',
  'darkroom-developing-equipment-51': 'darkroom-developing-equipment',
  'film-321': 'film',
  'vintage-instant-cameras-46': 'vintage-instant-cameras',
  'slide-movie-projectors-55': 'slide-movie-projectors',
  //
  'camera-cages-rigs-140': 'camera-cages-rigs',
  'memory-cards-87': 'memory-cards',
  'camera-bags-cases-77': 'camera-bags-cases',
  'batteries-chargers-139': 'batteries-chargers',
  'books-apparel-144': 'books-apparel',
  //
  'video-editing-desktops-81': 'video-editing-desktops',
  'video-editing-laptops-82': 'video-editing-laptops',
  'video-editing-monitors-84': 'video-editing-monitors',
  'hard-drives-83': 'hard-drives',
  'smart-phones-85': 'smart-phones',
  // 'luts-presets-216': 'luts-presets',
};

export const SUBCATEGORY_DIGITAL_CAMERAS = [
  'digital-slr-cameras',
  'mirrorless-system-cameras',
  'digital-medium-format-cameras',
  'point-shoot-cameras',
] as const;
export const SUBCATEGORY_DIGITAL_CAMERAS_ROUTES = [
  'digital-slr-cameras-73',
  'mirrorless-system-cameras-74',
  'digital-medium-format-cameras-64',
  'point-shoot-cameras-75',
] as const;

export const SUBCATEGORY_CAMERA_LENSES_FILTERS = [
  'slr-lenses',
  'mirrorless-system-lenses',
  'digital-cinema-lenses',
  'camera-lens-filters',
  'conversion-camera-lenses-adapters',
  'camera-lens-filter-rings-holders',
  'camera-lens-adapters-mounts-tubes',
  'camera-lens-hoods',
  'camera-lens-caps',
] as const;
export const SUBCATEGORY_CAMERA_LENSES_FILTERS_ROUTES = [
  'slr-lenses-14',
  'mirrorless-system-lenses-115',
  'digital-cinema-lenses-142',
  'camera-lens-filters-13',
  'conversion-camera-lenses-adapters-19',
  'camera-lens-filter-rings-holders-15',
  'camera-lens-adapters-mounts-tubes-16',
  'camera-lens-hoods-17',
  'camera-lens-caps-18',
] as const;

export const SUBCATEGORY_VIDEO_CAMERAS_CAMCORDERS = [
  'professional-video-cinema-cameras',
  'prosumer-camcorders',
  'consumer-camcorders',
  'action-cameras',
  '360-3d-cameras',
  'trail-wildlife-cameras',
] as const;
export const SUBCATEGORY_VIDEO_CAMERAS_CAMCORDERS_ROUTES = [
  'professional-video-cinema-cameras-30',
  'prosumer-camcorders-28',
  'consumer-camcorders-29',
  'action-cameras-33',
  '360-3d-cameras-86',
  'trail-wildlife-cameras-318',
] as const;

export const SUBCATEGORY_LIGHTING_STUDIO = [
  'camera-flashes',
  'continuous-lighting',
  'strobe-lighting',
  'light-softboxes-reflectors',
  'lighting-accessories',
  'backgrounds',
  'background-supports',
] as const;
export const SUBCATEGORY_LIGHTING_STUDIO_ROUTES = [
  'camera-flashes-21',
  'continuous-lighting-69',
  'strobe-lighting-116',
  'light-softboxes-reflectors-24',
  'lighting-accessories-26',
  'backgrounds-22',
  'background-supports-23',
] as const;

export const SUBCATEGORY_DRONES_AERIAL_IMAGING = [
  'drones',
  'drone-parts-accessories',
] as const;
export const SUBCATEGORY_DRONES_AERIAL_IMAGING_ROUTES = [
  'drones-107',
  'drone-parts-accessories-32',
] as const;

export const SUBCATEGORY_CAMERA_TRIPODS_SUPPORTS = [
  'tripods-monopods',
  'camera-stabilizers-gimbals',
  'tripod-heads-for-camera-video',
  'camera-sliders',
  'camera-jibs-booms',
  'quick-release-systems-plates',
] as const;
export const SUBCATEGORY_CAMERA_TRIPODS_SUPPORTS_ROUTES = [
  'tripods-monopods-36',
  'camera-stabilizers-gimbals-37',
  'tripod-heads-for-camera-video-38',
  'camera-sliders-131',
  'camera-jibs-booms-262',
  'quick-release-systems-plates-39',
] as const;

export const SUBCATEGORY_VIDEO_PRODUCTION_EDITING_EQUIPMENT = [
  'professional-video-switchers',
  'video-production-monitors',
  'audio-for-video-equipment',
  'video-recorders-players',
  'video-production-converters-scalers-processors',
  'video-production-accessories',
] as const;
export const SUBCATEGORY_VIDEO_PRODUCTION_EDITING_EQUIPMENT_ROUTES = [
  'professional-video-switchers-320',
  'video-production-monitors-71',
  'audio-for-video-equipment-66',
  'video-recorders-players-72',
  'video-production-converters-scalers-processors-67',
  'video-production-accessories-68',
] as const;

export const SUBCATEGORY_VINTAGE_CAMERA_EQUIPMENT = [
  'vintage-film-cameras',
  'vintage-lenses',
  'vintage-rangefinder-cameras',
  'vintage-view-cameras',
  'vintage-box-cameras',
  'vintage-movie-cameras',
  'darkroom-developing-equipment',
  'film',
  'vintage-instant-cameras',
  'slide-movie-projectors',
] as const;
export const SUBCATEGORY_VINTAGE_CAMERA_EQUIPMENT_ROUTES = [
  'vintage-film-cameras-50',
  'vintage-lenses-317',
  'vintage-rangefinder-cameras-49',
  'vintage-view-cameras-41',
  'vintage-box-cameras-43',
  'vintage-movie-cameras-44',
  'darkroom-developing-equipment-51',
  'film-321',
  'vintage-instant-cameras-46',
  'slide-movie-projectors-55',
] as const;

export const SUBCATEGORY_CAMERA_ACCESSORIES = [
  'camera-cages-rigs',
  'memory-cards',
  'camera-bags-cases',
  'batteries-chargers',
  'books-apparel',
] as const;
export const SUBCATEGORY_CAMERA_ACCESSORIES_ROUTES = [
  'camera-cages-rigs-140',
  'memory-cards-87',
  'camera-bags-cases-77',
  'batteries-chargers-139',
  'books-apparel-144',
] as const;

export const SUBCATEGORY_COMPUTERS_ELECTRONICS = [
  'video-editing-desktops',
  'video-editing-laptops',
  'video-editing-monitors',
  'hard-drives',
  'smart-phones',
  // 'luts-presets',
] as const;
export const SUBCATEGORY_COMPUTERS_ELECTRONICS_ROUTES = [
  'video-editing-desktops-81',
  'video-editing-laptops-82',
  'video-editing-monitors-84',
  'hard-drives-83',
  'smart-phones-85',
  // 'luts-presets-216',
] as const;

export const SUBCATEGORIES = [
  ...SUBCATEGORY_DIGITAL_CAMERAS,
  ...SUBCATEGORY_CAMERA_LENSES_FILTERS,
  ...SUBCATEGORY_VIDEO_CAMERAS_CAMCORDERS,
  ...SUBCATEGORY_LIGHTING_STUDIO,
  ...SUBCATEGORY_DRONES_AERIAL_IMAGING,
  ...SUBCATEGORY_CAMERA_TRIPODS_SUPPORTS,
  ...SUBCATEGORY_VIDEO_PRODUCTION_EDITING_EQUIPMENT,
  ...SUBCATEGORY_VINTAGE_CAMERA_EQUIPMENT,
  ...SUBCATEGORY_CAMERA_ACCESSORIES,
  ...SUBCATEGORY_COMPUTERS_ELECTRONICS,
];

export const SUBCATEGORY_ROUTES = [
  ...SUBCATEGORY_DIGITAL_CAMERAS_ROUTES,
  ...SUBCATEGORY_CAMERA_LENSES_FILTERS_ROUTES,
  ...SUBCATEGORY_VIDEO_CAMERAS_CAMCORDERS_ROUTES,
  ...SUBCATEGORY_LIGHTING_STUDIO_ROUTES,
  ...SUBCATEGORY_DRONES_AERIAL_IMAGING_ROUTES,
  ...SUBCATEGORY_CAMERA_TRIPODS_SUPPORTS_ROUTES,
  ...SUBCATEGORY_VIDEO_PRODUCTION_EDITING_EQUIPMENT_ROUTES,
  ...SUBCATEGORY_VINTAGE_CAMERA_EQUIPMENT_ROUTES,
  ...SUBCATEGORY_CAMERA_ACCESSORIES_ROUTES,
  ...SUBCATEGORY_COMPUTERS_ELECTRONICS_ROUTES,
];

export const CATEGORY_SUBCATEGORY_MAP: Record<Category, Subcategory[]> = {
  'digital-cameras': [
    'digital-slr-cameras',
    'mirrorless-system-cameras',
    'digital-medium-format-cameras',
    'point-shoot-cameras',
  ],
  'camera-lenses-filters': [
    'slr-lenses',
    'mirrorless-system-lenses',
    'digital-cinema-lenses',
    'camera-lens-filters',
    'conversion-camera-lenses-adapters',
    'camera-lens-filter-rings-holders',
    'camera-lens-adapters-mounts-tubes',
    'camera-lens-hoods',
    'camera-lens-caps',
  ],
  'video-cameras-camcorders': [
    'professional-video-cinema-cameras',
    'prosumer-camcorders',
    'consumer-camcorders',
    'action-cameras',
    '360-3d-cameras',
    'trail-wildlife-cameras',
  ],
  'lighting-studio': [
    'camera-flashes',
    'continuous-lighting',
    'strobe-lighting',
    'light-softboxes-reflectors',
    'lighting-accessories',
    'backgrounds',
    'background-supports',
  ],
  'drones-aerial-imaging': ['drones', 'drone-parts-accessories'],
  'camera-tripods-supports': [
    'tripods-monopods',
    'camera-stabilizers-gimbals',
    'tripod-heads-for-camera-video',
    'camera-sliders',
    'camera-jibs-booms',
    'quick-release-systems-plates',
  ],
  'video-production-editing-equipment': [
    'professional-video-switchers',
    'video-production-monitors',
    'audio-for-video-equipment',
    'video-recorders-players',
    'video-production-converters-scalers-processors',
    'video-production-accessories',
  ],
  'vintage-camera-equipment': [
    'vintage-film-cameras',
    'vintage-lenses',
    'vintage-rangefinder-cameras',
    'vintage-view-cameras',
    'vintage-box-cameras',
    'vintage-movie-cameras',
    'darkroom-developing-equipment',
    'film',
    'vintage-instant-cameras',
    'slide-movie-projectors',
  ],
  'camera-accessories': [
    'camera-cages-rigs',
    'memory-cards',
    'camera-bags-cases',
    'batteries-chargers',
    'books-apparel',
  ],
  'computers-electronics': [
    'video-editing-desktops',
    'video-editing-laptops',
    'video-editing-monitors',
    'hard-drives',
    'smart-phones',
    // 'luts-presets',
  ],
};
