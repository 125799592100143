import { z } from 'zod';

export const PaymentStatuses = [
  'Paid',
  'Unpaid',
  'Cancelled',
  'Refunded',
] as const;
export type PaymentStatus = (typeof PaymentStatuses)[number];

export const paymentDocumentSchema = z.object({
  type: z.number().min(0).max(6).default(0), // 0-card 1-apple pay, 2-paypal, 3-google pay, 4-mx balance, 5-klarna, 6-Affirm
  id: z.string().optional(),
  name: z.string().optional().nullable(),
  brand: z.string().optional(),
  last4: z.string().optional(),
  expMonth: z.string().optional(),
  expYear: z.string().optional(),
  saved: z.boolean().default(true),
});

export const bankAccountDocumentSchema = z.object({
  routing_number: z.string().optional(),
  account_number: z.string().optional(),
  account_holder_type: z.string().default('individual'),
  currency: z.string().default('usd'),
  account_holder_name: z.string().optional(),
  country: z.string().default('US'),
});

export type PaymentDocument = z.infer<typeof paymentDocumentSchema>;
export type BankAccountDocument = z.infer<typeof bankAccountDocumentSchema>;
