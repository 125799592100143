import { useQuery } from '@tanstack/react-query';
import { AUTH } from '@util/firebase';
import { getHostUrl } from '@util/index';
import { UserDocument } from '@util/types/firestore/users';
import { FirebaseError } from 'firebase/app';
import {
  AuthErrorCodes,
  createUserWithEmailAndPassword,
  FacebookAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  User,
  UserCredential,
  sendEmailVerification as _sendEmailVerification,
  sendPasswordResetEmail as _sendPasswordResetEmail,
  updatePassword,
  ActionCodeSettings,
  confirmPasswordReset,
  applyActionCode,
  fetchSignInMethodsForEmail,
  EmailAuthProvider,
  reauthenticateWithCredential,
  updateEmail,
  signInWithCustomToken,
  onIdTokenChanged,
  
} from 'firebase/auth';
import { useRouter } from 'next/router';
import { useCallback, useContext, useEffect } from 'react';
import { createContext, ReactNode, useState } from 'react';
import { getUserDocument, listenToUserDocument } from '@util/firestore/users';
import { useOnlineStatus } from '@util/hooks/useOnlineStatus';
import Cookie from 'js-cookie';
import { logError } from '@util/logError';
interface AuthProviderProps {
  children: ReactNode;
}

export interface Credentials {
  email: string;
  password: string;
}

export type FirebaseAuthFn<T extends Credentials | AuthProvider> = (
  args: T
) => Promise<FirebaseError | UserCredential>;

export const EMAIL_ERRORS = [
  AuthErrorCodes.USER_DELETED,
  AuthErrorCodes.EMAIL_EXISTS,
  AuthErrorCodes.INVALID_EMAIL,
  AuthErrorCodes.UNVERIFIED_EMAIL,
  AuthErrorCodes.INVALID_RECIPIENT_EMAIL,
];

export const PASSWORD_ERRORS = [
  AuthErrorCodes.WEAK_PASSWORD,
  AuthErrorCodes.INVALID_PASSWORD,
];

type AuthProvider = 'google' | 'facebook' | 'apple';

interface AuthContext {
  user: User | null | undefined;
  userDoc: UserDocument | null;
  isLoadingUserDoc: boolean;
  isLoggingIn: boolean;
  logout: () => Promise<void>;
  loginEmailPassword: FirebaseAuthFn<Credentials>;
  loginSocial: FirebaseAuthFn<AuthProvider>;
  createAccountEmailPassword: FirebaseAuthFn<Credentials>;
  signInWithToken: FirebaseAuthFn<Credentials>;
  sendEmailVerification: () => Promise<void>;
  sendPasswordResetEmail: (email: string) => void;
  resetPassword: (oobCode: string, password: string) => Promise<void>;
  updateUserPassword: (oldPassword: string, password: string) => Promise<void>;
  isAccountEmailPassword: () => boolean;
  verifyEmail: (actionCode: string) => Promise<void>;
  fetchProviderForEmail: (email: string) => Promise<string | null>;
  updateUserEmail: (email: string) => void;
}
const AuthContext = createContext({} as AuthContext);

const initAuthProvider = (p: AuthProvider) => {
  if (p === 'apple') return new OAuthProvider('apple.com');
  if (p === 'facebook') return new FacebookAuthProvider();
  return new GoogleAuthProvider();
};

const AuthProvider = ({ children }: AuthProviderProps) => {
  const [user, setUser] = useState<User | null | undefined>();
  const [userDoc, setUserDoc] = useState<UserDocument | null>(null);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const router = useRouter();
  const { setOnlineStatus } = useOnlineStatus();

  // get user document
  const { isLoading: isLoadingUserDoc } = useQuery({
    queryKey: ['user', user?.uid ?? ''],
    queryFn: () => getUserDocument({ uid: user?.uid ?? '' }),
    onSuccess: (doc) => {
      if (user) {
        if (doc) {
          setUserDoc(doc);
          setOnlineStatus(doc.uid);
        } else router.push('/signup');
      }
    },
  });

  const sendEmailVerification = async () => {
    if (!AUTH.currentUser) throw new Error('No AUTH.currentUser');
    const actionCodeSettings: ActionCodeSettings = {
      url: `${getHostUrl()}/verify-email?verified=true`,
    };
    await _sendEmailVerification(AUTH.currentUser, actionCodeSettings);
  };

  const verifyEmail = useCallback((actionCode: string) => {
    return applyActionCode(AUTH, actionCode);
  }, []);

  useEffect(() => {
    const unsubscribe = onIdTokenChanged(AUTH, (user) => {
      // set user from email/password login
      setUser(user);
      if (user && user.uid) {
        // Listen to changes in the user document
        const unsubscribeUserDoc = listenToUserDocument(user.uid, (doc: UserDocument | null) => {
          if (doc) {
            // Update the userDoc state with the updated user document
            setUserDoc(doc);
          }
        });
        user.getIdToken().then((token) => {
          Cookie.set('auth', token, { expires: 1 });
        });
  
        // Return a cleanup function to stop listening to user document changes
        return () => {
          unsubscribeUserDoc();
        };
      } else if (user === null) {
        setUserDoc(null);
        Cookie.remove('auth');
      }
    });
  
    return () => {
      unsubscribe();
    };
  }, [setUser, setUserDoc]);

  const handleAuthError = (e: unknown) => {
    if (e instanceof FirebaseError) {
      return e;
    }
    logError(e);
    throw new Error('Encountered unknown error in auth flow');
  };

  const loginSocial: FirebaseAuthFn<AuthProvider> = async (args) => {
    try {
      setIsLoggingIn(true);
      const provider = initAuthProvider(args);
      const result = await signInWithPopup(AUTH, provider);
      setUser(result.user);
      setIsLoggingIn(false);
      return result;
    } catch (e) {
      setIsLoggingIn(false);
      return handleAuthError(e);
    }
  };

  const loginEmailPassword: FirebaseAuthFn<Credentials> = async (args) => {
    try {
      setIsLoggingIn(true);
      const userCredential = await signInWithEmailAndPassword(
        AUTH,
        args.email,
        args.password
      );
      setUser(userCredential.user);
      setIsLoggingIn(false);
      return userCredential;
    } catch (e) {
      setIsLoggingIn(false);
      return handleAuthError(e);
    }
  };

  // admin feature
  const signInWithToken: FirebaseAuthFn<Credentials> = async (args) => {
    try {
      setIsLoggingIn(true);
      const userCredential = await signInWithCustomToken(AUTH, args.email);
      setUser(userCredential.user);
      setIsLoggingIn(false);
      return userCredential;
    } catch (e) {
      setIsLoggingIn(false);
      return handleAuthError(e);
    }
  };

  const createAccountEmailPassword: FirebaseAuthFn<Credentials> = async (
    args
  ) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        AUTH,
        args.email,
        args.password
      );
      return userCredential;
    } catch (e) {
      return handleAuthError(e);
    }
  };

  const logout = async () => {
    await signOut(AUTH);
    setUser(null);
    setUserDoc(null);
    Cookie.remove('auth');
  };

  const sendPasswordResetEmail = async (email: string) => {
    const actionCodeSettings: ActionCodeSettings = {
      url: `${getHostUrl()}/reset-password`,
    };
    await _sendPasswordResetEmail(AUTH, email, actionCodeSettings);
  };

  const resetPassword = async (oobCode: string, password: string) => {
    await confirmPasswordReset(AUTH, oobCode, password);
  };

  const updateUserPassword = async (
    oldPassword: string,
    newPassword: string
  ) => {
    if (AUTH.currentUser?.email && isAccountEmailPassword()) {
      const credential = EmailAuthProvider.credential(
        AUTH.currentUser.email,
        oldPassword
      );
      try {
        await reauthenticateWithCredential(AUTH.currentUser, credential);
        await updatePassword(AUTH.currentUser, newPassword);
      } catch (err) {
        throw new Error('Incorrect password');
      }
    }
  };

  const isAccountEmailPassword = () => {
    if (AUTH.currentUser) {
      return AUTH.currentUser.providerData.some(
        (p) => p.providerId === 'password'
      );
    }
    return false;
  };

  const fetchProviderForEmail = async (email: string) => {
    try {
      const methods = await fetchSignInMethodsForEmail(AUTH, email);
      if (methods?.length) {
        const method =
          methods[0] === 'password'
            ? 'your email and password'
            : methods[0].charAt(0).toUpperCase() +
              methods[0].substring(1).replace('.com', '');
        return method;
      }
    } catch (_e) {
      // invalid email
    }
    return null;
  };

  const updateUserEmail = (email: string) => {
    if (AUTH.currentUser) {
      updateEmail(AUTH.currentUser, email);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        userDoc,
        isLoadingUserDoc,
        isLoggingIn,
        logout,
        loginEmailPassword,
        loginSocial,
        signInWithToken,
        createAccountEmailPassword,
        sendEmailVerification,
        sendPasswordResetEmail,
        resetPassword,
        updateUserPassword,
        isAccountEmailPassword,
        verifyEmail,
        fetchProviderForEmail,
        updateUserEmail,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  return useContext(AuthContext);
}

export default AuthProvider;
