import Avatar from '@ui/Avatar';
import Chip from '@ui/Chip';
import SafeImage from '@ui/SafeImage';
import { offerStateToText, timeAgo } from '@util/index';
import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getOffer } from '@util/firestore/offers';
import { getProductById } from '@util/firestore/products';
import { getUserDocument } from '@util/firestore/users';
import { ChatDocument } from '@util/types/firestore/chat';
import { useAuth } from 'context/AuthContext';
import { FlagIcon, GearIcon } from '@c/icons';

interface MessagePreviewProps {
  onClick: any;
  selected?: boolean;
  chat: ChatDocument;
}

const MessagePreview = ({ selected, onClick, chat }: MessagePreviewProps) => {
  const { data: offer } = useQuery({
    queryKey: ['offer', chat.offer_id],
    queryFn: () => getOffer(chat.offer_id!),
    enabled: !!chat.offer_id,
  });
  const { data: productDoc } = useQuery({
    queryKey: ['product', chat.product_id],
    queryFn: () => getProductById(chat.product_id!),
    enabled: !!chat.product_id,
  });
  const { data: sellerDoc } = useQuery({
    queryKey: ['user', chat.uids?.[1]],
    queryFn: () => getUserDocument({ uid: chat.uids?.[1]! }),
    enabled: !!chat.uids?.[1],
  });
  const { data: buyerDoc } = useQuery({
    queryKey: ['user', chat.uids?.[0]],
    queryFn: () => getUserDocument({ uid: chat.uids?.[0]! }),
    enabled: !!chat.uids?.[0],
  });

  const { userDoc } = useAuth();

  const getChipColor = (status: string) => {
    switch (status) {
      case 'accepted':
        return 'success';
      case 'declined':
        return 'error';
      case 'pending':
        return 'pending';
      case 'cancelled':
        return 'gray';
      default:
        return 'gray';
    }
  };

  const getTitle = () => {
    if (chat.buyer_id==='GearBot') return 'GearBot'
    if (chat.case_num) return 'Support #' + chat.case_num.toString().padStart(5, '0');
    if (chat.is_offer) return productDoc?.title;
    else if (sellerDoc?.uid === userDoc?.uid) return buyerDoc?.username;

    return sellerDoc?.username;
  };

  const isUnread = useMemo(() => {
    return userDoc?.uid ? !!chat.unread?.[userDoc.uid] : false;
  }, [chat.unread, userDoc?.uid]);

  const getMessageImage = () => {
    //  Gear focus icon for support messages
    if (userDoc?.roles?.support) {
      if (chat.case_num) {
        return (
          <Avatar
            size="extraSmall"
            user={
              (sellerDoc?.uid === userDoc.uid ? buyerDoc : sellerDoc) ??
              undefined
            }
          />
        );
      }
      return (
        <div className="flex gap-x-2">
          {sellerDoc?.uid === userDoc.uid ? (
            <Avatar size="extraSmall" user={buyerDoc ?? undefined} />
          ) : (
            <Avatar size="extraSmall" user={sellerDoc ?? undefined} />
          )}
        </div>
      );
    }

    if (chat.case_num) {
      return (
        <div className="flex items-center justify-center">
          <GearIcon />
        </div>
      );
    }

    if (chat.is_bot) {
      return (
        <div className="h-[4.4rem] w-[4.4rem] overflow-clip rounded-2xl">
          <SafeImage
            width={44}
            height={44}
            src="https://gearfocus.b-cdn.net/gearbot.png"
            alt="GearBot Automated Message"
          />
        </div>
      );
    }

    // Profile image for regular messages
    if (!chat.is_offer && !chat.product_id && sellerDoc) {
      return <Avatar user={sellerDoc} />;
    }

    // Product image for offers or messages about a product
    return (
      <div className="h-[4.4rem] w-[4.4rem] overflow-clip rounded-2xl">
        <SafeImage
          width={44}
          height={44}
          src={productDoc?.images[0]?.thumb}
          alt={productDoc?.title ?? 'Buy New and Used Cameras and Lenses With Gear Focus'}
          key={productDoc?.id}
        />
      </div>
    );
  };

  const getLastText = () => {
    if (!chat.messages || chat.messages.length === 0) return 'No messages';
    const lastMessage = chat.messages[chat.messages.length - 1];
    
    if (lastMessage.is_img) return 'Image';
    if (lastMessage.uid === userDoc?.uid) {
      return 'You: ' + (lastMessage.content || '');
    }

    return (lastMessage.content || '').slice(0, 35) + "...";
  };


  const getChatTopic = () => {
    if (chat.case_num) {
      return 'Support';
    }
    if (chat.is_offer) {
      return `Offer: ${offer && offerStateToText(offer?.state)}`;
    }
    if (chat.product_id) {
      return `Product: ${productDoc?.title}`;
    }
  };

  return (
    <div
      className={`relative flex cursor-pointer justify-between gap-[1.6rem] px-[1.6rem] py-[1.6rem] hover:bg-brand-lightest-gray ${
        selected ? 'bg-brand-lightest-gray' : ''
      }`}
      onClick={() => onClick?.()}
    >
      {chat.flagged && (
        <div className="absolute left-2 top-2 text-brand-red">
          <FlagIcon />
        </div>
      )}

      <div className="flex items-center overflow-hidden">
        {getMessageImage()}
        <div className="ml-5 overflow-hidden">
          {userDoc?.roles?.admin && (
            <>
              <p className="w-full overflow-hidden text-ellipsis whitespace-nowrap text-[1.3rem] text-brand-gray">
                B: {chat.buyer_id==='GearBot' ? 'GearBot' : buyerDoc?.username}
              </p>
              <p className="w-full overflow-hidden text-ellipsis whitespace-nowrap text-[1.3rem] text-brand-gray">
                S: {sellerDoc?.username}
              </p>
              <p className="w-full overflow-hidden text-ellipsis whitespace-nowrap text-[1.3rem] text-brand-gray">
                {getChatTopic()}
              </p>
            </>
          )}
          <h1 className="w-full overflow-hidden text-ellipsis whitespace-nowrap text-[1.3rem] font-semibold">
            {getTitle()}
          </h1>
          <p className="w-full overflow-hidden text-ellipsis whitespace-nowrap text-[1.3rem] text-brand-gray">
            {getLastText()}
          </p>
        </div>
      </div>
      <div className="flex flex-col items-end justify-between gap-3">
        <p className="whitespace-nowrap text-[1.3rem] font-medium text-brand-gray">
          {timeAgo(chat.last_time)}
        </p>
        {isUnread && (
          <div className="flex h-[2rem] w-[2rem] items-center justify-center rounded-full bg-brand-red font-semibold leading-[1.6rem] text-brand-white">
            1
          </div>
        )}
        {offer && (
          <Chip
            text={offerStateToText(offer.state)}
            color={getChipColor(offerStateToText(offer.state).toLowerCase())}
          />
        )}
      </div>
    </div>
  );
};

export default MessagePreview;
