export const Truck = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.3496 2.92V11.23C14.3496 12.25 13.5196 13.08 12.4996 13.08H3.34961C2.79961 13.08 2.34961 12.63 2.34961 12.08V5.69C2.34961 3.65 3.99961 2 6.03961 2H13.4196C13.9396 2 14.3496 2.41 14.3496 2.92Z"
      fill="currentColor"
    />
    <path
      d="M21.8496 15.5C22.1296 15.5 22.3496 15.72 22.3496 16V17C22.3496 18.66 21.0096 20 19.3496 20C19.3496 18.35 17.9996 17 16.3496 17C14.6996 17 13.3496 18.35 13.3496 20H11.3496C11.3496 18.35 9.99961 17 8.34961 17C6.69961 17 5.34961 18.35 5.34961 20C3.68961 20 2.34961 18.66 2.34961 17V15C2.34961 14.45 2.79961 14 3.34961 14H12.8496C14.2296 14 15.3496 12.88 15.3496 11.5V6C15.3496 5.45 15.7996 5 16.3496 5H17.1896C17.9096 5 18.5696 5.39 18.9296 6.01L19.5696 7.13C19.6596 7.29 19.5396 7.5 19.3496 7.5C17.9696 7.5 16.8496 8.62 16.8496 10V13C16.8496 14.38 17.9696 15.5 19.3496 15.5H21.8496Z"
      fill="currentColor"
    />
    <path
      d="M8.34961 22C9.45418 22 10.3496 21.1046 10.3496 20C10.3496 18.8954 9.45418 18 8.34961 18C7.24504 18 6.34961 18.8954 6.34961 20C6.34961 21.1046 7.24504 22 8.34961 22Z"
      fill="currentColor"
    />
    <path
      d="M16.3496 22C17.4542 22 18.3496 21.1046 18.3496 20C18.3496 18.8954 17.4542 18 16.3496 18C15.245 18 14.3496 18.8954 14.3496 20C14.3496 21.1046 15.245 22 16.3496 22Z"
      fill="currentColor"
    />
    <path
      d="M22.3496 12.53V14H19.3496C18.7996 14 18.3496 13.55 18.3496 13V10C18.3496 9.45 18.7996 9 19.3496 9H20.6396L22.0896 11.54C22.2596 11.84 22.3496 12.18 22.3496 12.53Z"
      fill="currentColor"
    />
  </svg>
);

export const TruckFeature = () => (
  <svg
    width="64"
    height="72"
    viewBox="0 0 64 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_2095_43673)">
      <circle cx="28" cy="24" r="20" fill="url(#paint0_linear_2095_43673)" />
      <circle cx="28" cy="24" r="22" stroke="white" strokeWidth="4" />
    </g>
    <path
      d="M32.9987 29.4173C32.6672 29.4173 32.3492 29.2856 32.1148 29.0512C31.8804 28.8168 31.7487 28.4988 31.7487 28.1673C31.7487 27.8358 31.8804 27.5179 32.1148 27.2834C32.3492 27.049 32.6672 26.9173 32.9987 26.9173C33.3302 26.9173 33.6482 27.049 33.8826 27.2834C34.117 27.5179 34.2487 27.8358 34.2487 28.1673C34.2487 28.4988 34.117 28.8168 33.8826 29.0512C33.6482 29.2856 33.3302 29.4173 32.9987 29.4173ZM34.2487 21.9173L35.882 24.0006H32.1654V21.9173H34.2487ZM22.9987 29.4173C22.6672 29.4173 22.3492 29.2856 22.1148 29.0512C21.8804 28.8168 21.7487 28.4988 21.7487 28.1673C21.7487 27.8358 21.8804 27.5179 22.1148 27.2834C22.3492 27.049 22.6672 26.9173 22.9987 26.9173C23.3302 26.9173 23.6482 27.049 23.8826 27.2834C24.117 27.5179 24.2487 27.8358 24.2487 28.1673C24.2487 28.4988 24.117 28.8168 23.8826 29.0512C23.6482 29.2856 23.3302 29.4173 22.9987 29.4173ZM34.6654 20.6673H32.1654V17.334H20.4987C19.5737 17.334 18.832 18.0757 18.832 19.0007V28.1673H20.4987C20.4987 28.8304 20.7621 29.4662 21.2309 29.9351C21.6998 30.4039 22.3357 30.6673 22.9987 30.6673C23.6617 30.6673 24.2976 30.4039 24.7665 29.9351C25.2353 29.4662 25.4987 28.8304 25.4987 28.1673H30.4987C30.4987 28.8304 30.7621 29.4662 31.2309 29.9351C31.6998 30.4039 32.3357 30.6673 32.9987 30.6673C33.6617 30.6673 34.2976 30.4039 34.7665 29.9351C35.2353 29.4662 35.4987 28.8304 35.4987 28.1673H37.1654V24.0006L34.6654 20.6673Z"
      fill="white"
    />
    <defs>
      <filter
        id="filter0_d_2095_43673"
        x="0"
        y="0"
        width="64"
        height="72"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="4" dy="16" />
        <feGaussianBlur stdDeviation="4" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2095_43673"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2095_43673"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_2095_43673"
        x1="8"
        y1="4"
        x2="48"
        y2="44"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EF9021" />
        <stop offset="1" stopColor="#EF5F21" />
      </linearGradient>
    </defs>
  </svg>
);
