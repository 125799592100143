import { BaseIconProps } from './types';

export const VerifyEmail = ({ height = 100, width = 100 }: BaseIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M86.4607 65.3523C84.8269 66.9821 82.6121 67.8956 80.3042 67.8914H17.6956C15.3877 67.8955 13.1729 66.9821 11.5391 65.3523L39.9217 36.9697L44.7216 40.4479C47.2645 42.3265 50.7353 42.3265 53.2782 40.4479L58.0782 36.9697L86.4607 65.3523Z"
        fill="#EF9021"
      />
      <path
        opacity="0.1"
        d="M86.4607 65.3523C84.8269 66.9821 82.6121 67.8956 80.3042 67.8914H17.6956C15.3877 67.8955 13.1729 66.9821 11.5391 65.3523L39.9217 36.9697L44.7216 40.4479C47.2645 42.3265 50.7353 42.3265 53.2782 40.4479L58.0782 36.9697L86.4607 65.3523Z"
        fill="#080C0F"
      />
      <path
        d="M87.3323 15.8219L58.0802 36.9697L53.2802 40.4479C50.7374 42.3265 47.2665 42.3265 44.7237 40.4479L39.9237 36.9697L10.6367 15.8567C12.2657 13.5787 14.897 12.2305 17.6976 12.2393H80.3063C83.0863 12.2417 85.6976 13.5732 87.3323 15.8219Z"
        fill="#EF9021"
      />
      <path
        opacity="0.3"
        d="M87.3284 15.8219L58.0763 36.9697L53.2763 40.4479C50.7335 42.3265 47.2626 42.3265 44.7198 40.4479L39.9198 36.9697L10.6328 15.8567C12.2618 13.5787 14.8931 12.2305 17.6937 12.2393H80.3024C83.0824 12.2417 85.6937 13.5732 87.3284 15.8219Z"
        fill="white"
      />
      <path
        d="M10.6349 15.8564L39.9219 36.9694L11.5393 65.352C9.90953 63.7182 8.996 61.5034 9.00008 59.1955V20.9346C8.99241 19.1113 9.56486 17.3328 10.6349 15.8564Z"
        fill="#EF9021"
      />
      <path
        d="M88.9998 20.9348V59.1957C89.0039 61.5036 88.0905 63.7184 86.4607 65.3522L58.0781 36.9696L87.3302 15.8218C88.4163 17.3053 89.0009 19.0963 88.9998 20.9348Z"
        fill="#EF9021"
      />
      <path
        d="M49.0015 88.7607C59.5669 88.7607 68.1318 80.1958 68.1318 69.6304C68.1318 59.065 59.5669 50.5 49.0015 50.5C38.4361 50.5 29.8711 59.065 29.8711 69.6304C29.8711 80.1958 38.4361 88.7607 49.0015 88.7607Z"
        fill="#080C0F"
      />
      <path
        d="M45.5198 78.3259C45.0583 78.3267 44.6153 78.1441 44.2885 77.818L37.332 70.8614C36.6646 70.1706 36.6839 69.0696 37.3747 68.4022C38.0487 67.7512 39.1171 67.7512 39.7911 68.4022L45.5198 74.1276L56.4625 63.185C57.1533 62.5177 58.2543 62.5369 58.9217 63.2277C59.5727 63.9017 59.5727 64.9702 58.9217 65.6442L46.7477 77.8181C46.4218 78.1432 45.9803 78.3259 45.5198 78.3259Z"
        fill="#FAFAFA"
      />
    </svg>
  );
};
export const CheckEmail = ({ height = 100, width = 100 }: BaseIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M88.0669 86.1669C86.4844 88.0669 84.1397 89.1659 81.6669 89.1669H18.3336C15.8608 89.1659 13.5161 88.0669 11.9336 86.1669L42.5003 60.7002L45.667 62.8669C48.2756 64.6669 51.7251 64.6669 54.3337 62.8669L57.5004 60.7002L88.0669 86.1669Z"
        fill="#EF9021"
      />
      <path
        opacity="0.1"
        d="M88.0669 86.1669C86.4844 88.0669 84.1397 89.1659 81.6669 89.1669H18.3336C15.8608 89.1659 13.5161 88.0669 11.9336 86.1669L42.5003 60.7002L45.667 62.8669C48.2756 64.6669 51.7251 64.6669 54.3337 62.8669L57.5004 60.7002L88.0669 86.1669Z"
        fill="#080C0F"
      />
      <path
        d="M88.5332 39.467L57.5 60.7003L54.3333 62.867C51.7247 64.667 48.2752 64.667 45.6666 62.867L42.4999 60.7003L11.4668 39.4336C13.0284 37.1819 15.5932 35.8372 18.3335 35.8336H81.6667C84.4193 35.8191 86.9968 37.1828 88.5332 39.467Z"
        fill="#EF9021"
      />
      <path
        opacity="0.1"
        d="M88.5332 39.467L57.5 60.7003L54.3333 62.867C51.7247 64.667 48.2752 64.667 45.6666 62.867L42.4999 60.7003L11.4668 39.4336C13.0284 37.1819 15.5932 35.8372 18.3335 35.8336H81.6667C84.4193 35.8191 86.9968 37.1828 88.5332 39.467Z"
        fill="#080C0F"
      />
      <path
        d="M42.5001 60.7006L11.9334 86.1673C10.6823 84.672 9.9978 82.7837 10.0001 80.834V44.1674C9.98999 42.4763 10.5023 40.8231 11.4669 39.4341L42.5001 60.7006Z"
        fill="#EF9021"
      />
      <path
        d="M89.9999 44.1673V80.8339C90.0021 82.7836 89.3177 84.6719 88.0667 86.1672L57.5 60.7005L88.5332 39.4673C89.4929 40.8463 90.0049 42.4871 89.9999 44.1673Z"
        fill="#EF9021"
      />
      <path
        d="M81.667 10.834H38.3337C37.4132 10.834 36.667 11.5802 36.667 12.5007V30.8339H18.3337C17.4132 30.8339 16.667 31.5802 16.667 32.5007V42.5006C16.6668 43.0514 16.9389 43.5667 17.3937 43.8774L44.7269 62.5706C47.9071 64.7503 52.1002 64.7503 55.2802 62.5706L82.6134 43.8706C83.0637 43.5599 83.3328 43.0478 83.3334 42.5006V12.5007C83.3336 11.5802 82.5875 10.834 81.667 10.834Z"
        fill="#F4F4F4"
      />
      <path
        d="M38.9704 10.9604C38.3477 10.702 37.6307 10.844 37.1537 11.3204L17.1537 31.3204C16.502 31.9704 16.5007 33.0257 17.1507 33.6774C17.4642 33.9916 17.8899 34.1679 18.3337 34.1671H38.3337C39.2541 34.1671 40.0004 33.4208 40.0004 32.5004V12.5004C40.0002 11.8259 39.5936 11.2181 38.9704 10.9604Z"
        fill="#D9D9D9"
      />
    </svg>
  );
};
export const ForgotPassword = ({
  height = 100,
  width = 100,
}: BaseIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.7219 54.9482V42.124C37.7219 38.8672 39.0156 35.7439 41.3185 33.441C43.6214 31.1381 46.7448 29.8443 50.0016 29.8443C53.2583 29.8443 56.3817 31.1381 58.6846 33.441C60.9875 35.7439 62.2813 38.8672 62.2813 42.124V54.9482H69.0781V42.124C69.0781 37.0642 67.0681 32.2116 63.4903 28.6338C59.9124 25.0559 55.0598 23.0459 50 23.0459C44.9402 23.0459 40.0876 25.0559 36.5097 28.6338C32.9319 32.2116 30.9219 37.0642 30.9219 42.124V54.9482H37.7219Z"
        fill="#080C0F"
      />
      <path
        d="M78.343 53.5298H21.657C19.9221 53.5298 18.5156 54.9362 18.5156 56.6712V73.8118C18.5156 75.5468 19.9221 76.9532 21.657 76.9532H78.343C80.0779 76.9532 81.4844 75.5468 81.4844 73.8118V56.6712C81.4844 54.9362 80.0779 53.5298 78.343 53.5298Z"
        fill="#EF9021"
      />
      <path
        d="M32.5297 61.21L29.8133 64.7826V64.8436L34.1289 64.2725V66.2162L29.8133 65.7021V65.7592L32.5578 69.1904L30.7563 70.2193L29.0375 66.217H28.9859L27.1258 70.2482L25.4961 69.2186L28.2125 65.7318V65.6748L23.9805 66.217V64.2686L28.1844 64.8154V64.7584L25.4961 61.2701L27.2398 60.2693L29.0133 64.2428H29.0695L30.8156 60.2412L32.5297 61.21Z"
        fill="white"
      />
      <path
        d="M46.4961 61.21L43.7797 64.7826V64.8436L48.0953 64.2725V66.2162L43.7797 65.7021V65.7592L46.5242 69.1904L44.7219 70.2146L43.0031 66.2123H42.9469L41.0867 70.2436L39.457 69.2139L42.1734 65.7271V65.6701L37.9414 66.2123V64.2686L42.1453 64.8154V64.7584L39.457 61.2701L41.2008 60.2693L42.9742 64.2428H43.0305L44.7766 60.2412L46.4961 61.21Z"
        fill="white"
      />
      <path
        d="M60.4602 61.21L57.7438 64.7826V64.8436L62.0594 64.2725V66.2162L57.7438 65.7021V65.7592L60.4883 69.1904L58.6867 70.2193L56.968 66.217H56.9117L55.0516 70.2482L53.4219 69.2186L56.1383 65.7318V65.6748L51.9062 66.217V64.2686L56.1102 64.8154V64.7584L53.4219 61.2701L55.1656 60.2693L56.9391 64.2428H56.9953L58.7414 60.2412L60.4602 61.21Z"
        fill="white"
      />
      <path
        d="M74.4258 61.21L71.7094 64.7826V64.8436L76.025 64.2725V66.2162L71.7094 65.7021V65.7592L74.4563 69.1857L72.6516 70.2146L70.9328 66.2123H70.8766L69.0164 70.2436L67.3867 69.2139L70.1031 65.7271V65.6701L65.8711 66.2123V64.2686L70.075 64.8154V64.7584L67.3867 61.2701L69.1305 60.2693L70.9039 64.2428H70.9602L72.7063 60.2412L74.4258 61.21Z"
        fill="white"
      />
    </svg>
  );
};
export const ResetPassword = ({ height = 100, width = 100 }: BaseIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.7204 45.9639V33.1396C37.7204 29.8829 39.0142 26.7595 41.317 24.4566C43.6199 22.1537 46.7433 20.86 50.0001 20.86C53.2569 20.86 56.3803 22.1537 58.6831 24.4566C60.986 26.7595 62.2798 29.8829 62.2798 33.1396V45.9639H69.0767V33.1396C69.0767 28.0798 67.0667 23.2272 63.4888 19.6494C59.911 16.0715 55.0584 14.0615 49.9985 14.0615C44.9387 14.0615 40.0861 16.0715 36.5083 19.6494C32.9304 23.2272 30.9204 28.0798 30.9204 33.1396V45.9639H37.7204Z"
        fill="#080C0F"
      />
      <path
        d="M72.093 44.5454H27.907C26.1721 44.5454 24.7656 45.9519 24.7656 47.6868V78.1087C24.7656 79.8436 26.1721 81.2501 27.907 81.2501H72.093C73.8279 81.2501 75.2344 79.8436 75.2344 78.1087V47.6868C75.2344 45.9519 73.8279 44.5454 72.093 44.5454Z"
        fill="#EF9021"
      />
      <path
        d="M46.3541 71.8188C45.7325 71.8186 45.1365 71.5716 44.6971 71.132L38.8377 65.2727C38.6197 65.0553 38.4467 64.7971 38.3285 64.5128C38.2104 64.2285 38.1493 63.9238 38.1489 63.6159C38.1485 63.3081 38.2088 63.0032 38.3262 62.7186C38.4436 62.434 38.616 62.1754 38.8334 61.9574C39.0508 61.7395 39.309 61.5665 39.5932 61.4483C39.8775 61.3301 40.1823 61.2691 40.4901 61.2687C40.798 61.2683 41.1029 61.3285 41.3874 61.446C41.672 61.5634 41.9307 61.7357 42.1486 61.9531L46.3541 66.1586L57.8517 54.6633C58.2938 54.2364 58.8858 54.0001 59.5003 54.0055C60.1149 54.0108 60.7027 54.2573 61.1373 54.6918C61.5718 55.1264 61.8183 55.7142 61.8236 56.3288C61.829 56.9433 61.5927 57.5353 61.1658 57.9774L48.0111 71.132C47.5717 71.5716 46.9756 71.8186 46.3541 71.8188Z"
        fill="white"
      />
    </svg>
  );
};
