import Chat from '@c/Chat';
import useRealtimeChats from '@util/hooks/useRealtimeChats';
import { ChatDocument, ChatMessageDocument } from '@util/types/firestore/chat';
import {
  ReactNode,
  createContext,
  useContext,
  useState,
  useEffect,
} from 'react';
import { useAuth } from './AuthContext';
import {
  sendMessageToChat,
  markAsRead,
  getChat,
} from '@util/firestore/messages';
import ChatModal from '@c/modals/ChatModal';
import { useRouter } from 'next/router';
import { UserDocument } from '@util/types/firestore/users';

type ChatProviderProps = {
  children: ReactNode;
};
type ChatContext = {
  chatOpen: boolean;
  openChatDrawer: (chat: ChatDocument) => void;
  setChatOpen: React.Dispatch<React.SetStateAction<boolean>>;
  chatModalOpen: boolean;
  setChatModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  openChatModalWithRecipientAndProductId: ({
    recipientUid,
    productId,
  }: {
    recipientUid: string;
    productId?: string;
  }) => void;
  getChatCount: () => number;
  getUnreadCount: () => number;
  sendMessage: (message: ChatMessageDocument, chatId: string) => void;
  chats: ChatDocument[] | null;
};
const ChatContext = createContext({} as ChatContext);

export function useChat() {
  return useContext(ChatContext);
}

export function ChatProvider({ children }: ChatProviderProps) {
  const [chatOpen, setChatOpen] = useState(false);
  const { userDoc } = useAuth();
  const router = useRouter();
  const [user, setUser] = useState<UserDocument | null>(null);
  const { realtimeChats: chats } = useRealtimeChats(user?.uid);
  useEffect(() => setUser(userDoc), [userDoc]);

  function getChatCount() {
    return chats?.length ?? 0;
  }

  function getUnreadCount() {
    if (!user?.uid || !chats) return 0;
    return chats.reduce(
      (acc, chat) => (chat.unread?.[user.uid] ? acc + 1 : acc),
      0
    );
  }

  // function getUnreadCount() {
  //   // TODO: figure out how to determine this from the messages/chat schema
  //   // for now just returning the total number of messages
  //   let unreadCount = 0;
  //   chats?.forEach((chat) => {
  //     if (!chat.unread) return;
  //     Object.keys(chat.unread).forEach((seller_id) => {
  //       if (seller_id !== user?.uid) {
  //         unreadCount += chat.unread?.[seller_id] ? 1 : 0;
  //       }
  //     });
  //   });
  //   return unreadCount;
  // }

  async function sendMessage(message: ChatMessageDocument, chatId: string) {
    if (userDoc?.roles?.admin) {
      const chat = await getChat(chatId);
      if (chat) {
        sendMessageToChat(message, chat, user?.uid);
      }
      return;
    }
    const selectedChatDoc = chats?.find((chat) => chat.id === chatId);
    if (!selectedChatDoc) return;
    sendMessageToChat(message, selectedChatDoc, user?.uid);
  }

  const [chatModalOpen, setChatModalOpen] = useState(false);
  const [recipientUid, setRecipientUid] = useState('');
  const [productId, setProductId] = useState('');

  function openChatModalWithRecipientAndProductId({
    recipientUid,
    productId,
  }: {
    recipientUid: string;
    productId?: string;
  }): void {
    if (user) {
      if (user.uid !== recipientUid) {
        setRecipientUid(recipientUid);
        if (productId) setProductId(productId);
        setChatModalOpen(true);
      }
    } else
      router.push({
        pathname: '/login',
        query: { redirect: router.asPath },
      });
  }

  const [selectedChat, setSelectedChat] = useState<ChatDocument | undefined>();

  function openChatDrawer(chat: ChatDocument) {
    if (chat?.id && user?.uid && chat.unread?.[user.uid])
      markAsRead(chat.id, user.uid);
    setSelectedChat(chat);
    setChatOpen(true);
  }

  return (
    <ChatContext.Provider
      value={{
        chatOpen,
        setChatOpen,
        openChatDrawer,
        chatModalOpen,
        setChatModalOpen,
        openChatModalWithRecipientAndProductId,
        getChatCount,
        getUnreadCount,
        sendMessage,
        chats,
      }}
    >
      <Chat chat={selectedChat} />
      <ChatModal
        isOpen={chatModalOpen}
        dismiss={() => setChatModalOpen(false)}
        recipientUid={recipientUid}
        productId={productId}
      />
      {children}
    </ChatContext.Provider>
  );
}
