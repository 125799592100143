export const EmailFeature = () => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M56.362 55.1463C55.3492 56.3623 53.8486 57.0657 52.266 57.0663H11.7327C10.1501 57.0657 8.64952 56.3623 7.63672 55.1463L27.1994 38.8477L29.2261 40.2344C30.8956 41.3864 33.1033 41.3864 34.7728 40.2344L36.7995 38.8477L56.362 55.1463Z"
      fill="#EF9021"
    />
    <path
      opacity="0.1"
      d="M56.362 55.1463C55.3492 56.3623 53.8486 57.0657 52.266 57.0663H11.7327C10.1501 57.0657 8.64952 56.3623 7.63672 55.1463L27.1994 38.8477L29.2261 40.2344C30.8956 41.3864 33.1033 41.3864 34.7728 40.2344L36.7995 38.8477L56.362 55.1463Z"
      fill="#202330"
    />
    <path
      d="M56.6623 25.2591L36.8011 38.8483L34.7744 40.235C33.1049 41.387 30.8972 41.387 29.2277 40.235L27.201 38.8483L7.33984 25.2377C8.33924 23.7966 9.98074 22.936 11.7345 22.9337H52.2678C54.0295 22.9244 55.6791 23.7972 56.6623 25.2591Z"
      fill="#EF9021"
    />
    <path
      opacity="0.1"
      d="M56.6623 25.2591L36.8011 38.8483L34.7744 40.235C33.1049 41.387 30.8972 41.387 29.2277 40.235L27.201 38.8483L7.33984 25.2377C8.33924 23.7966 9.98074 22.936 11.7345 22.9337H52.2678C54.0295 22.9244 55.6791 23.7972 56.6623 25.2591Z"
      fill="#202330"
    />
    <path
      d="M27.1985 38.8489L7.63583 55.1475C6.83513 54.1905 6.39703 52.982 6.39853 51.7342V28.2676C6.39203 27.1853 6.71993 26.1273 7.33723 25.2383L27.1985 38.8489Z"
      fill="#EF9021"
    />
    <path
      d="M57.5968 28.2678V51.7344C57.5982 52.9822 57.1602 54.1907 56.3595 55.1477L36.7969 38.849L56.6581 25.2598C57.2723 26.1424 57.6 27.1925 57.5968 28.2678Z"
      fill="#EF9021"
    />
    <path
      d="M52.268 6.93359H24.5346C23.9455 6.93359 23.4679 7.41119 23.4679 8.00029V19.7336H11.7347C11.1456 19.7336 10.668 20.2112 10.668 20.8003V27.2003C10.6679 27.5528 10.842 27.8826 11.1331 28.0814L28.6263 40.045C30.6616 41.44 33.3452 41.44 35.3804 40.045L52.8737 28.0771C53.1619 27.8782 53.3341 27.5505 53.3345 27.2003V8.00029C53.3346 7.41119 52.8571 6.93359 52.268 6.93359Z"
      fill="#F4F4F4"
    />
    <path
      d="M24.9401 7.01513C24.5416 6.84973 24.0827 6.94063 23.7774 7.24553L10.9774 20.0455C10.5603 20.4615 10.5595 21.1369 10.9755 21.554C11.1761 21.7551 11.4486 21.8679 11.7326 21.8674H24.5326C25.1217 21.8674 25.5993 21.3898 25.5993 20.8007V8.00073C25.5992 7.56903 25.339 7.18003 24.9401 7.01513Z"
      fill="#D9D9D9"
    />
  </svg>
);
