import { roleSchema, userDocumentSchema } from '@util/types/firestore/users';
import { z } from 'zod';

const uidSchema = z.string().optional();

export const getUserArgsSchema = z.object({
  uid: uidSchema,
});
export const createUserDocumentSchema = userDocumentSchema;
export const updateUserRoleArgsSchema = z.object({
  uid: uidSchema,
  newRole: roleSchema,
});
export const updateUserDocArgsSchema = userDocumentSchema.partial();

export const passwordSchema = z
  .string({ required_error: 'password is required' })
  .min(8, 'must be 8 or more characters')
  .regex(/[A-Z]/, 'upper & lowercase required')
  .regex(/[a-z]/, 'upper & lowercase required')
  .regex(/[0-9]/, 'at least one number required');

export const emailSchema = z
  .string({ required_error: 'email is required' })
  .email('must enter a valid email');

export const usernameSchema = z
  .string({ required_error: 'username is required' })
  .min(4, 'must be 4 or more characters')
  .regex(/^[A-Za-z0-9\-\'\ ]+$/, 'special characters not allowed');

export type CreateUserDocumentArgs = z.infer<typeof createUserDocumentSchema>;
export type GetUserArgs = z.infer<typeof getUserArgsSchema>;
export type UpdateUserRoleArgs = z.infer<typeof updateUserRoleArgsSchema>;
export type UpdateUserDocArgs = z.infer<typeof updateUserDocArgsSchema>;
