import { getAnalytics, isSupported } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { initializeFirestore } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getStorage } from 'firebase/storage';

const clientCredentials = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
};

export const firebaseApp = initializeApp(clientCredentials);
const db = initializeFirestore(firebaseApp, {
  experimentalAutoDetectLongPolling: true,
});
const AUTH = getAuth(firebaseApp);
const functions = getFunctions(firebaseApp);
const storage = getStorage(firebaseApp);
const realtimeDatabase = getDatabase(firebaseApp);
const analyticsPromise = isSupported().then((yes) =>
  yes ? getAnalytics(firebaseApp) : null
);
// ugly ass code to keep the firestore emulator from throwing errors when nextjs HMRs
// https://stackoverflow.com/questions/65066963/firebase-firestore-emulator-error-host-has-been-set-in-both-settings-and-usee
const EMULATORS_STARTED = 'EMULATORS_STARTED';
function startEmulators() {
  if (!(global as any)[EMULATORS_STARTED]) {
    (global as any)[EMULATORS_STARTED] = true;
    // connectAuthEmulator(AUTH, 'http://localhost:9099');
    // connectFunctionsEmulator(functions, 'localhost', 5001);
    // connectFirestoreEmulator(db, 'localhost', 8080);
  }
}
if (process.env.NODE_ENV === 'development') {
  startEmulators();
}
if (typeof window !== 'undefined') {
  if ( process.env.NODE_ENV === 'development' && process.env.DEBUG_APP === 'true') {
    // check console for token, set it here and in firebase console
    (self as any).FIREBASE_APPCHECK_DEBUG_TOKEN = true;
  }

  if ( process.env.NODE_ENV === 'production') {
    initializeAppCheck(firebaseApp, {
      provider: new ReCaptchaV3Provider(
        process.env.NEXT_PUBLIC_CAPTCHA_SITE_KEY || ''
      ),
      isTokenAutoRefreshEnabled: true,
    });
  }
}
export {
  db,
  AUTH,
  httpsCallable,
  functions,
  storage,
  realtimeDatabase,
  analyticsPromise,
};
