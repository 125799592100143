import localFont from 'next/font/local';

const GeneralSans = localFont({
  src: '../../../public/fonts/GeneralSans-Variable.ttf',
  display: 'swap',
  weight: '100 900',
  variable: '--font-general-sans',
});

type Props = {
  children: React.ReactNode;
};

export default function RootLayout({ children }: Props) {
  return (
    <div
      className={`bg-brand-white text-[1.5rem] text-brand-black ${GeneralSans.className} min-w-default`}
    >
      {children}
    </div>
  );
}
