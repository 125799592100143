import FancySpinner from '@ui/FancySpinner';
import BaseModal from './BaseModal';
type Props = {
  open: boolean;
};

const PaymentProcessingModal = ({ open }: Props) => {
  return (
    <BaseModal hideCloseIcon={true} isOpen={open} dismiss={() => null}>
      <div className="flex grow flex-col items-center justify-center">
        <FancySpinner />
        <p className="text-center text-[2.4rem] font-semibold text-brand-secondary sm:px-[12rem]">
          Your order is processing. <br /> Please do not refresh the page.
        </p>
      </div>
    </BaseModal>
  );
};

export default PaymentProcessingModal;
