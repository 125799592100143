import { FedExIcon, USPSIcon } from '@c/icons';
import { UPS } from '@c/icons/carriers';
import { z } from 'zod';

const carrierCodeSchemas = [
  z.literal('usps'),
  z.literal('fedex'),
  z.literal('ups'),
  z.literal('dhl_express'),
  z.literal('dhl_global_mail'),
  z.literal('canada_post'),
  z.literal('australia_post'),
  z.literal('firstmile'),
  z.literal('asendia'),
  z.literal('ontrac'),
  z.literal('apc'),
  z.literal('newgistics'),
  z.literal('globegistics'),
  z.literal('rr_donnelley'),
  z.literal('imex'),
  z.literal('access_worldwide'),
  z.literal('purolator_ca'),
  z.literal('sendle'),
] as const;

export const carrierCodeSchema = z.union(carrierCodeSchemas);

const carrierSchema = z.object({
  name: z.string(),
  code: carrierCodeSchema,
  icon: z.any().optional(),
});

export type CarrierCode = z.infer<typeof carrierCodeSchema>;

export type Carrier = z.infer<typeof carrierSchema>;

export const ALL_CARRIERS: Carrier[] = [
  { name: 'USPS', code: 'usps', icon: <USPSIcon /> },
  { name: 'FedEx', code: 'fedex', icon: <FedExIcon /> },
  { name: 'UPS', code: 'ups', icon: <UPS /> },
  { name: 'DHL Express', code: 'dhl_express' },
  { name: 'DHL ECommerce', code: 'dhl_global_mail' },
  { name: 'Canada Post', code: 'canada_post' },
  { name: 'Australia Post', code: 'australia_post' },
  { name: 'First Mile', code: 'firstmile' },
  { name: 'Asendia', code: 'asendia' },
  { name: 'OnTrac', code: 'ontrac' },
  { name: 'APC', code: 'apc' },
  { name: 'Newgistics', code: 'newgistics' },
  { name: 'Globegistics', code: 'globegistics' },
  { name: 'RR Donnelley', code: 'rr_donnelley' },
  { name: 'IMEX', code: 'imex' },
  { name: 'Access Worldwide', code: 'access_worldwide' },
  { name: 'Purolator Canada', code: 'purolator_ca' },
  { name: 'Sendle', code: 'sendle' },
];

export const CARRIER_CODE_TO_CARRIER: Record<CarrierCode, Carrier> = {
  usps: { name: 'USPS', code: 'usps', icon: <USPSIcon /> },
  fedex: { name: 'FedEx', code: 'fedex', icon: <FedExIcon /> },
  ups: { name: 'UPS', code: 'ups', icon: <UPS /> },
  dhl_express: { name: 'DHL Express', code: 'dhl_express', icon: null },
  dhl_global_mail: {
    name: 'DHL ECommerce',
    code: 'dhl_global_mail',
    icon: null,
  },
  canada_post: { name: 'Canada Post', code: 'canada_post', icon: null },
  australia_post: {
    name: 'Australia Post',
    code: 'australia_post',
    icon: null,
  },
  firstmile: { name: 'First Mile', code: 'firstmile', icon: null },
  asendia: { name: 'Asendia', code: 'asendia', icon: null },
  ontrac: { name: 'OnTrac', code: 'ontrac', icon: null },
  apc: { name: 'APC', code: 'apc', icon: null },
  newgistics: { name: 'Newgistics', code: 'newgistics', icon: null },
  globegistics: { name: 'Globegistics', code: 'globegistics', icon: null },
  rr_donnelley: { name: 'RR Donnelley', code: 'rr_donnelley', icon: null },
  imex: { name: 'IMEX', code: 'imex', icon: null },
  access_worldwide: {
    name: 'Access Worldwide',
    code: 'access_worldwide',
    icon: null,
  },
  purolator_ca: { name: 'Purolator Canada', code: 'purolator_ca', icon: null },
  sendle: { name: 'Sendle', code: 'sendle', icon: null },
};
