export const ChattingApp = () => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M57.5991 46.7733H40.2125C40.1058 46.7733 40.0525 46.7733 39.9458 46.88L25.5991 54.4C25.0658 54.6667 24.4258 54.1333 24.6924 53.4933L27.4124 47.7867C27.5724 47.3067 27.3058 46.7733 26.7724 46.7733H17.0124C16.6924 46.7733 16.4258 46.5067 16.4258 46.1867V20.5867C16.4258 20.2667 16.6924 20 17.0124 20H57.5458C57.8658 20 58.1325 20.2667 58.1325 20.5867V46.1333C58.2391 46.5067 57.9725 46.7733 57.5991 46.7733Z"
      fill="#EF9021"
    />
    <path
      d="M23.1996 27.0935H51.3596C51.7863 27.0935 52.2129 26.8268 52.3729 26.4535C52.5863 25.7601 52.1063 25.1201 51.4129 25.1201H23.3063C22.8796 25.1201 22.4529 25.3868 22.2929 25.7601C22.0263 26.4535 22.5596 27.0935 23.1996 27.0935Z"
      fill="white"
    />
    <path
      d="M51.4667 31.7871H23.3067C22.88 31.7871 22.4533 32.0538 22.2933 32.4271C22.08 33.1204 22.56 33.7604 23.2533 33.7604H51.4133C51.84 33.7604 52.2667 33.4938 52.4267 33.1204C52.64 32.4804 52.1067 31.7871 51.4667 31.7871Z"
      fill="white"
    />
    <path
      d="M51.4667 38.3467H23.3067C22.88 38.3467 22.4533 38.6133 22.2933 38.9867C22.08 39.68 22.56 40.32 23.2533 40.32H51.4133C51.84 40.32 52.2667 40.0533 52.4267 39.68C52.64 38.9867 52.1067 38.3467 51.4667 38.3467Z"
      fill="white"
    />
    <path
      d="M6.18631 27.5735H17.9196C17.973 27.5735 18.0263 27.5735 18.0796 27.6269L27.733 32.6935C28.1063 32.8535 28.533 32.5335 28.3196 32.1069L26.5596 28.2669C26.453 27.9469 26.613 27.5735 26.9863 27.5735H33.5463C33.813 27.5735 33.973 27.4135 33.973 27.1469V9.97354C33.973 9.70688 33.813 9.54688 33.5463 9.54688H6.23964C5.97298 9.54688 5.81298 9.70688 5.81298 9.97354V27.1469C5.75964 27.3602 5.97298 27.5735 6.18631 27.5735Z"
      fill="#242E5F"
    />
    <path
      d="M12.5431 14.3994H27.4569C27.7493 14.3994 28 14.7686 28 15.1994C28 15.6302 27.7493 15.9994 27.4569 15.9994H12.5431C12.2507 15.9994 12 15.6302 12 15.1994C12.0418 14.7071 12.2507 14.3994 12.5431 14.3994Z"
      fill="white"
    />
    <path
      d="M12.5431 20.7998H27.4569C27.7493 20.7998 28 21.169 28 21.5998C28 22.0306 27.7493 22.3998 27.4569 22.3998H12.5431C12.2507 22.3998 12 22.0306 12 21.5998C12 21.169 12.2507 20.7998 12.5431 20.7998Z"
      fill="white"
    />
  </svg>
);
