import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import Button from '@ui/Button';
import Input from '@ui/Input';
import Spinner from '@ui/Spinner';
import { createSetupIntent } from '@util/firestore/payments/payments.service';
import { STRIPE_ELEMENT_OPTIONS } from '@util/get-stripe';
import { UserDocument } from '@util/types/firestore/users';
import { updateUserByKey } from '@util/firestore/users';
import { useAuth } from 'context/AuthContext';
import { useStripeContext } from 'context/StripeContext';
import React from 'react';
import BaseModal from './BaseModal';

interface PaymentFormProps {
  userDoc: UserDocument;
  dismiss: (paymentMethodId?: string) => void;
}

const PaymentForm = ({ userDoc, dismiss }: PaymentFormProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = React.useState(false);
  const [cardError, setCardError] = React.useState<string | null>(null);
  const [cardName, setCardName] = React.useState('');

  const queryClient = useQueryClient();

  const handleSubmit = async () => {
    if (!stripe || !elements) return;

    setIsLoading(true);

    const res = await stripe.confirmSetup({
      elements,
      confirmParams: {
        payment_method_data: {
          billing_details: {
            name: cardName,
            email: userDoc.email,
            phone: userDoc.phone,
          },
        },
      },
      redirect: 'if_required',
    });

    if (res.error) {
      setCardError(
        res.error.message ??
          'Something went wrong. Please try again or contact support.'
      );
    } else {
      queryClient.invalidateQueries(['paymentMethods']);
      const id: string =
        (res.setupIntent.payment_method as any)?.id ??
        res.setupIntent.payment_method;

      if (id) {
        const cards = [...(userDoc.stripe.cards || []), { payment_method: id, created: Date.now() }];
        const stripe = {...userDoc.stripe, cards}
        await updateUserByKey(userDoc!.uid, 'stripe', stripe)
      }  

      dismiss(id);
    }
    setIsLoading(false);
  };
  
  return (
    <>
      {cardError && (
        <div className="my-2 text-[1.3rem] font-semibold text-brand-red">
          Error: {cardError}
        </div>
      )}
      <div className="mb-5 mt-5">
        <label className="text[14.88px] pb-2 text-[#30313e]">Full Name</label>
        <Input
          full
          stripe
          type="text"
          focus="default"
          autoComplete="name"
          placeholder="John Doe"
          onChange={(ev) => setCardName(ev.target.value)}
        />
      </div>
      <PaymentElement
        options={{ fields: { billingDetails: { name: 'never' } } }}
      />
      <div className="pb-[2.5rem] pt-5 text-brand-lightest-black">
        Payment is powered by Stripe and we do not store sensitive payment
        information on our servers.
      </div>
      <div className="flex grow flex-col justify-end font-semibold text-brand-secondary sm:flex-row">
        <Button type="text" text="Cancel" onClick={() => dismiss()} />
        <Button
          type="secondary"
          text="Save Payment"
          loading={isLoading}
          onClick={() => handleSubmit()}
        />
      </div>
    </>
  );
};

interface AddPaymentMethodModalProps {
  isOpen: boolean;
  dismiss: (paymentMethodId?: string) => void;
}

const AddPaymentMethodModal = ({
  isOpen,
  dismiss,
}: AddPaymentMethodModalProps) => {
  const { stripePromise } = useStripeContext();
  const { userDoc } = useAuth();
  const { data, isLoading } = useQuery(
    ['paymentSetupIntent'],
    () => createSetupIntent(),
    { enabled: !!userDoc?.uid }
  );
  if (!userDoc) return null;

  return (
    <BaseModal
      isOpen={isOpen}
      dismiss={dismiss}
      title={
        <h1 className="text-[2.4rem] font-semibold">Add Payment Method</h1>
      }
    >
      <div className="flex max-h-[75vh] flex-col gap-[1.6rem] sm:w-[50rem] sm:p-[2.4rem]">
        {isLoading ? (
          <div className="m-auto h-[10rem] w-[10rem]">
            <Spinner />
          </div>
        ) : (
          <div className="h-full overflow-y-scroll px-4">
            <Elements
              stripe={stripePromise}
              options={{
                ...STRIPE_ELEMENT_OPTIONS,
                clientSecret: data?.data,
              }}
            >
              <PaymentForm userDoc={userDoc} dismiss={dismiss} />
            </Elements>
          </div>
        )}
      </div>
    </BaseModal>
  );
};

export default AddPaymentMethodModal;
