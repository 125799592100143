import Link from 'next/link';
import { cva, VariantProps } from 'class-variance-authority';
import Spinner from './Spinner';
import { UrlObject } from 'url';

export const ButtonStyles = cva(
  'select-none cursor-pointer gap-x-2 flex items-center  whitespace-nowrap rounded-full transition-colors text-[1.5rem]',
  {
    variants: {
      type: {
        primary:
          'font-semibold text-brand-white bg-gradient-to-br from-[#F08322] to-[#F06222] hover:from-[#F06222] hover:to-[#F06222] hover:shadow-md',
        secondary:
          'font-semibold border-2 bg-brand-secondary text-brand-white border-brand-secondary hover:bg-transparent hover:text-brand-secondary',
        sso: 'gap-[1rem] bg-brand-white border-2 border-brand-secondary font-semibold text-input hover:bg-brand-lightest-gray',
        tertiary:
          'font-semibold bg-brand-white border-2 border-brand-secondary text-brand-secondary hover:bg-brand-secondary hover:text-brand-white',
        decline:
          'font-semibold bg-brand-white border-2 border-brand-red text-brand-red',
        success: 'font-semibold bg-brand-green text-brand-white',
        error:
          'font-semibold bg-brand-red border-2 border-brand-red text-brand-white hover:bg-red-600',

        text: 'text-brand-secondary font-semibold hover:text-brand-primary focus:outline-none',
        dark: 'font-semibold bg-brand-black text-brand-white hover:bg-brand-lightest-black',
        gray: 'font-semibold bg-brand-lightest-gray text-brand-secondary hover:bg-brand-lightest-gray',
        affirm:
          'font-semibold text-brand-white bg-brand-affirm rounded-xl mx-auto',
      },
      height: {
        large: 'h-[5.6rem]',
        normal: 'h-[5rem]',
        small: 'h-[4.4rem]',
        extraSmall: 'h-min-content',
      },
      width: {
        normal: 'px-[4.4rem]',
        small: 'px-[1.6rem]',
        smaller: 'px-[1rem]',
        extraSmall: 'px-[0.4rem]',
        fluid: 'w-full p-[1.6rem]',
        min: 'w-fit px-0',
        fixed: 'w-[15rem]',
      },
      align: {
        left: 'justify-start pl-[1.6rem]',
        center: 'justify-center',
        right: 'justify-end pr-[1.6rem]',
      },
      loading: {
        true: 'bg-brand-lightest-gray hover:bg-brand-lightest-gray',
      },
      disabled: {
        true: '!bg-none !hover:shadow-none !border-none !text-brand-gray !hover:text-brand-gray !cursor-not-allowed !bg-brand-lighter-gray !hover:bg-brand-lighter-gray',
      },
      noXPadding: {
        true: 'px-0',
      },
    },
    defaultVariants: {
      type: 'primary',
      height: 'large',
      width: 'normal',
      align: 'center',
    },
  }
);

export interface ButtonProps extends VariantProps<typeof ButtonStyles> {
  type?:
    | 'primary'
    | 'secondary'
    | 'text'
    | 'tertiary'
    | 'sso'
    | 'dark'
    | 'decline'
    | 'error'
    | 'success'
    | 'gray'
    | 'affirm';
  buttonType?: 'button' | 'submit' | 'reset';
  leadingIcon?: React.ReactNode;
  trailingIcon?: React.ReactNode;
  text?: string;
  title?: string;
  href?: string | UrlObject;
  target?: string;
  height?: 'normal' | 'small' | 'extraSmall';
  onClick?: (e?: Event) => void;
  align?: 'left' | 'center' | 'right';
  tabIndex?: string;
  disabled?: boolean;
  noXPadding?: boolean;
  loading?: boolean;
  ariaText?: string;
  className?: string;
}
const Button = ({
  href,
  target,
  text,
  title,
  leadingIcon,
  trailingIcon,
  type = 'primary',
  buttonType = 'button',
  height = 'normal',
  width = 'normal',
  className,
  onClick,
  align,
  loading,
  disabled,
  noXPadding,
  ariaText,
}: ButtonProps) => {
  const handleClick = (e: any) => {
    onClick?.(e);
  };

  const InnerContents = () =>
    loading ? (
      <div className="h-8 w-8">
        <Spinner />
      </div>
    ) : (
      <>
        {leadingIcon}
        {text}
        {trailingIcon}
      </>
    );

  return href ? (
    <Link
      className={`${ButtonStyles({
        type,
        height,
        width,
        align,
        loading,
        noXPadding,
        disabled,
      })} ${className ?? ''}`}
      href={href}
      title={title}
      target={target}
      onClick={handleClick}
      scroll={true}
      aria-label={text ?? ariaText}
    >
      <InnerContents />
    </Link>
  ) : (
    <button
      type={buttonType}
      className={ButtonStyles({
        type,
        height,
        width,
        align,
        noXPadding,
        disabled,
      })}
      onClick={(e: any) => {
        handleClick(e);
      }}
      disabled={disabled || loading}
    >
      <InnerContents />
    </button>
  );
};

export default Button;
