import { db, functions, httpsCallable, storage } from '@util/firebase';
import {
  arrayRemove,
  doc,
  orderBy,
  query,
  startAfter,
  updateDoc,
} from '@firebase/firestore';
import {
  collection,
  CollectionReference,
  setDoc,
  where,
  getDoc,
  limit,
  getDocs,
} from 'firebase/firestore';
import { ChatDocument, ChatMessageDocument } from '@util/types/firestore/chat';
import { ref, uploadBytes } from 'firebase/storage';
import { ContactUsForm } from 'pages/contact-us';
import { findPhoneNumbersInText } from 'libphonenumber-js';
import { OrderDocument } from '@util/types/firestore/order';

export const messagesRef = collection(
  db,
  'messages'
) as CollectionReference<ChatDocument>;

export function getChatsByUidQuery(uid: string) {
  return query(
    messagesRef,
    where('uids', 'array-contains', uid),
    orderBy('last_time', 'desc')
  );
}

export function getChatsByChatIds(chatIds: string[]) {
  return query(
    messagesRef,
    where('id', 'in', chatIds),
    orderBy('last_time', 'desc')
  );
}

export function getChatById(chat_id: string) {
  return doc(messagesRef, chat_id);
}

export async function getChatDocumentById(chat_id: string) {
  const snapshot = await getDoc(getChatById(chat_id));
  const chat: ChatDocument | undefined = snapshot.data();
  if (!chat) {
    return null;
  }
  return chat;
}

export async function getChatsForOrderDetails(order: OrderDocument) {
  const promises = [getChatsByOrderId(order.id)];
  if (order.product_ids && order.seller_arr)
    promises.push(
      getChatsByProductIds(order.product_ids, order.seller_arr, order.buyer_id)
    );
  return (await Promise.all(promises))
    .flat()
    .sort((a, b) => b.last_time - a.last_time);
}

async function getChatsByProductIds(
  products: string[],
  seller_ids: string[],
  buyer_id: string
) {
  const q = query(
    messagesRef,
    where('product_id', 'in', products),
    where('seller_id', 'in', seller_ids),
    where('buyer_id', '==', buyer_id)
    // orderBy('last_time', 'desc') // dont wanna create index
  );
  const docs = await getDocs(q);
  return docs.docs.map((doc) => doc.data());
}

async function getChatsByOrderId(order_id: string) {
  const q = query(
    messagesRef,
    where('order_id', '==', order_id),
    orderBy('last_time', 'desc')
  );
  const docs = await getDocs(q);
  return docs.docs.map((doc) => doc.data());
}

export async function getTwentyChats(lastChatTime?: number) {
  if (lastChatTime) {
    return await getNextTwenty(lastChatTime);
  }
  const q = query(messagesRef, orderBy('last_time', 'desc'), limit(20));
  const docs = await getDocs(q);
  return docs.docs.map((doc) => doc.data());
}

export async function getNextTwenty(lastChatTime: number) {
  const q = query(
    messagesRef,
    orderBy('last_time', 'desc'),
    startAfter(lastChatTime),
    limit(20)
  );
  const docs = await getDocs(q);
  return docs.docs.map((doc) => doc.data());
}

export function sendMessageToChat(
  message: ChatMessageDocument,
  chat: ChatDocument,
  currUserId?: string
) {
  if (!message) return;
  const redactedMessage =
    //currUserId === process.env.NEXT_PUBLIC_SUPPORT_ID ? message.content : 
    redactPhonesAndEmailsFromStr(message.content, chat);
  message.content = redactedMessage;
  const newChat = { ...chat };
  if (!newChat.unread) {
    newChat.unread = {};
  }

  if (
    message.uid !== process.env.NEXT_PUBLIC_SUPPORT_ID &&
    chat.uids &&
    currUserId
  ) {
    const recipient = currUserId === chat.uids[0] ? chat.uids[1] : chat.uids[0];
    newChat.unread = { [recipient]: true, [currUserId]: false };
  }

  if (newChat.messages) {
    newChat.messages.push(message);
  } else {
    newChat.messages = [message];
  }
  const now = Date.now();
  message.created_at = now;
  newChat.last_time = now;
  return setDoc(doc(messagesRef, chat.id), newChat);
}

export async function createChat(chat: ChatDocument) {
  const docRef = doc(messagesRef);
  const now = Date.now();
  chat.id = docRef.id;
  chat.last_time = now;
  chat.created_at = now;
  await setDoc(docRef, chat);
  return docRef.id;
}

export async function getChat(chat_id: string) {
  const docRef = doc(messagesRef, chat_id);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data() as ChatDocument;
  } else {
    return null;
  }
}

export async function updateChat(chat: ChatDocument): Promise<void> {
  const docRef = doc(messagesRef, chat.id);
  await updateDoc(docRef, chat);
}

export async function getNextCaseNumber() {
  // get highest case_num from messages, add 1
  const q = query(
    messagesRef,
    orderBy('case_num', 'desc'),
    where('case_num', '!=', null),
    limit(1)
  );
  const querySnapshot = await getDocs(q);
  if (querySnapshot.empty) {
    return 1;
  }
  const doc = querySnapshot.docs[0];
  const data = doc.data();
  return data.case_num! + 1;
}

export async function markAsRead(chat_id: string, uid: string) {
  const docRef = doc(messagesRef, chat_id);
  const key = `unread.${uid}`;
  await updateDoc(docRef, { [key]: false });
}

export async function flagChat(chat_id: string, flagged: boolean) {
  const docRef = doc(messagesRef, chat_id);
  await updateDoc(docRef, { flagged });
}

export async function hideChat(chat_id: string, uid: string) {
  const docRef = doc(messagesRef, chat_id);
  await updateDoc(docRef, { uids: arrayRemove(uid) });
}

export async function deleteMessage(
  chat_id: string,
  message: ChatMessageDocument
) {
  const docRef = doc(messagesRef, chat_id);
  await updateDoc(docRef, { messages: arrayRemove(message) });
}

export async function uploadChatImage(
  data: Blob,
  user_id: string,
  chat_id: string
) {
  const path = `${user_id}/messages/${chat_id}/${Date.now()}`;
  const imagesRef = ref(storage, path);
  const res = await uploadBytes(imagesRef, data);
  return res.metadata.fullPath;
}

export async function contactUs(form: ContactUsForm) {
  const res = await httpsCallable<ContactUsForm, { data: 'success' }>(
    functions,
    'contactUs'
  )(form);
  return res.data.data;
}

export async function subscribeToList(email: string) {
  const res = await httpsCallable<{ email: string }>(
    functions,
    'subscribeToList'
  )({ email });
  return res.data;
}

// Optional chat argument to flag a chat
export function redactPhonesAndEmailsFromStr(str: string, chat?: ChatDocument) {
  const res = findPhoneNumbersInText(str, 'US');
  const hasForbiddenWord = new RegExp(
    /^.*(venmo|zelle|paypal|pay pal|cash app).*$/
  ).test(str.toLowerCase());

  if (res.length || hasForbiddenWord) {
    // TODO: Send message from support once we know this works
    // const message = new ChatMessage(
    //   this.supportId,
    //   'Please note buying or selling outside of Gear Focus is a violation of our policies and will result in the ban of your account.'
    // );
    // sendMessageToChat(chat.id, message);
    if (chat) {
      flagChat(chat.id!, true);
    }
    res.forEach((r) => {
      const sub = str.substring(r.startsAt, r.endsAt);
      const redacted = new Array(sub.length).fill('█').join('');
      str = str!.replace(sub, redacted);
    });
  }
  const emailRegex = /\w+@\w+\.\w{2,3}/g;
  str = str.replace(emailRegex, '████████');
  return str;
}
