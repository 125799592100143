export const Page = () => (
  <svg
    width="22"
    height="23"
    viewBox="0 0 22 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.8405 2.3335H7.15884C3.82217 2.3335 1.83301 4.32266 1.83301 7.65933V15.3318C1.83301 18.6777 3.82217 20.6668 7.15884 20.6668H14.8313C18.168 20.6668 20.1572 18.6777 20.1572 15.341V7.65933C20.1663 4.32266 18.1772 2.3335 14.8405 2.3335ZM15.583 16.3127H6.41634C6.04051 16.3127 5.72884 16.001 5.72884 15.6252C5.72884 15.2493 6.04051 14.9377 6.41634 14.9377H15.583C15.9588 14.9377 16.2705 15.2493 16.2705 15.6252C16.2705 16.001 15.9588 16.3127 15.583 16.3127ZM15.583 12.1877H6.41634C6.04051 12.1877 5.72884 11.876 5.72884 11.5002C5.72884 11.1243 6.04051 10.8127 6.41634 10.8127H15.583C15.9588 10.8127 16.2705 11.1243 16.2705 11.5002C16.2705 11.876 15.9588 12.1877 15.583 12.1877ZM15.583 8.06266H6.41634C6.04051 8.06266 5.72884 7.751 5.72884 7.37516C5.72884 6.99933 6.04051 6.68766 6.41634 6.68766H15.583C15.9588 6.68766 16.2705 6.99933 16.2705 7.37516C16.2705 7.751 15.9588 8.06266 15.583 8.06266Z"
      fill="white"
    />
  </svg>
);
