import { z } from 'zod';
import { addressDocumentSchema } from './address';

const badgeCountsSchema = z.object({
  quick_replies: z.number(),
  as_described: z.number(),
  fast_shipper: z.number(),
});

export const roleSchema = z.object({
  admin: z.boolean().optional(),
  auctions: z.boolean().optional(),
  support: z.boolean().optional(),
  experiences: z.boolean().optional(),
  blog: z.boolean().optional(),
  seo: z.boolean().optional(),
  integrations: z.boolean().optional(),
});

export const metricSchema = z.object({
  first_purchase: z.number(), // timesteamp of the first item purchased
  last_purchase: z.number(), // timesteamp of the last item purchased
  ltv_purchased: z.number(), // lifetime sum of purchases in dollars
  num_purchased: z.number(),
  num_refunded: z.number(),
  ltv_sold: z.number(), // lifetime sum of sales in dollars
  num_sold: z.number(),
  first_sold: z.number(), // time stamp of the first item sold
  last_sold: z.number(), // time stamp of the last item sold
  last_listed: z.number(), // time stamp of the last item listed
  live_listings: z.number(),
  dropped_price: z.boolean(),
});

export const noteSchema = z.object({
  created: z.number(),
  value: z.string(),
  uid: z.string()
})

export const userDocumentSchema = z.object({
  permissions: z.object({
    is_verified: z.boolean().default(false),
    is_banned: z.boolean().default(false),
    is_seller: z.boolean().default(false),
    is_fraudulent: z.boolean().default(false),
    is_email_verified: z.boolean().default(false),
    is_phone_verified: z.boolean().default(false),
    is_restricted: z.boolean().default(true),
    is_monitored: z.boolean().default(false),
    can_message: z.boolean().default(false),
    can_buy: z.boolean().default(false),
    can_sell: z.boolean().default(false),
  }),
  account_activated: z.boolean().default(false), //If the user has gone through process to become a seller
  account_id: z.string().optional(), //Stripe Seller ID
  addresses: z.array(addressDocumentSchema).optional(),
  author: z.string().optional(), // old site userId
  badge_counts: z.array(badgeCountsSchema),
  badges: z.array(z.any()),
  bio: z.string().optional(),
  brands: z.array(z.any()),
  business_type: z
    .union([z.literal('individual'), z.literal('company')])
    .optional(),
  country: z.string(),
  created: z.number(),
  deleted: z.boolean().optional(),
  email: z.string(),
  favorites: z.array(z.string()).optional(),
  first: z.string().optional(),
  following: z.array(z.string()),
  from_web: z.boolean(),
  hide_status: z.boolean(),
  last: z.string().optional(),
  last_active: z.number(),
  last_login: z.number().optional().nullable(),
  last_app_active: z.string().optional(),
  name: z.string(),
  old_seller_activated: z.boolean().optional(), //Time to remove?
  on_vacation: z.boolean().default(false),
  opted_out: z.boolean(),
  phone: z.string(),
  photo: z.string(),
  store_photo: z.string().optional(),
  rating: z.number(),
  reviews: z.number(),
  roles: roleSchema.optional(),
  state: z.string().optional(),
  uid: z.string(),
  username: z.string(),
  username_changed: z.number(),
  username_slug: z.string().optional(),
  customer_id: z.string().optional(),
  shipping_policy: z.string().optional(),
  return_policy: z.string().optional(),
  tax_states: z.array(z.string()).optional(),
  metrics: metricSchema.partial().optional(),
  stripe: z.object({
    cards: z.array(z.object({
      created: z.number(),
      payment_method: z.string()
    })).optional(),
    customer_id: z.string().optional(),
    account_id: z.string().optional(),
    last_updated: z.number().optional(),
    payouts_enables: z.boolean().optional(),
    comapany: z.object({}).optional(),
    individual: z.object({}).optional(),
    external_accounts: z.array(z.string()).optional(),
    requirements: z.object({
      disabled_reason: z.string().optional(),
      eventually_due: z.array(z.string()).optional(),
      currently_due: z.array(z.string()).optional(),
      past_due: z.array(z.string()).optional(),
      errors: z.array(z.object({
        code: z.string(),
        reason: z.string(),
        requirement: z.string()
      })).optional(),
      pending_verification: z.array(z.string()).optional(),
    }).optional(),
    future_requirements: z.object({}).optional(),
  }),
  notes: z.array(noteSchema).optional(),
});

export const updateFavoritesArgsSchema = z.object({
  uid: z.string(),
  productId: z.string(),
  remove: z.boolean().optional(),
});

export const updateAddressesArgsSchema = z.object({
  uid: z.string(),
  address: addressDocumentSchema,
  remove: z.boolean().optional(),
});

export type Role = z.infer<typeof roleSchema>;
export type UserDocument = z.infer<typeof userDocumentSchema>;
export type BadgeCounts = z.infer<typeof badgeCountsSchema>;
export type UserAddress = z.infer<typeof addressDocumentSchema>;
