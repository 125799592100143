export const ChatFeature = () => (
  <svg
    width="66"
    height="58"
    viewBox="0 0 66 58"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M64.9999 47.4667H43.2665C43.1332 47.4667 43.0665 47.4667 42.9332 47.6L24.9999 57C24.3332 57.3333 23.5332 56.6667 23.8665 55.8667L27.2665 48.7333C27.4665 48.1333 27.1332 47.4667 26.4665 47.4667H14.2665C13.8665 47.4667 13.5332 47.1333 13.5332 46.7333V14.7333C13.5332 14.3333 13.8665 14 14.2665 14H64.9332C65.3332 14 65.6665 14.3333 65.6665 14.7333V46.6667C65.7999 47.1333 65.4665 47.4667 64.9999 47.4667Z"
      fill="#D9D9D9"
    />
    <path
      d="M21.9995 22.8671H57.1995C57.7328 22.8671 58.2662 22.5337 58.4662 22.0671C58.7328 21.2004 58.1328 20.4004 57.2662 20.4004H22.1328C21.5995 20.4004 21.0662 20.7337 20.8662 21.2004C20.5328 22.0671 21.1995 22.8671 21.9995 22.8671Z"
      fill="white"
    />
    <path
      d="M57.3333 28.7344H22.1333C21.6 28.7344 21.0667 29.0677 20.8667 29.5344C20.6 30.401 21.2 31.201 22.0667 31.201H57.2667C57.8 31.201 58.3333 30.8677 58.5333 30.401C58.8 29.601 58.1333 28.7344 57.3333 28.7344Z"
      fill="white"
    />
    <path
      d="M57.3333 36.9336H22.1333C21.6 36.9336 21.0667 37.2669 20.8667 37.7336C20.6 38.6003 21.2 39.4003 22.0667 39.4003H57.2667C57.8 39.4003 58.3333 39.0669 58.5333 38.6003C58.8 37.7336 58.1333 36.9336 57.3333 36.9336Z"
      fill="white"
    />
    <path
      d="M0.732888 23.4669H15.3996C15.4662 23.4669 15.5329 23.4669 15.5996 23.5336L27.6662 29.8669C28.1329 30.0669 28.6662 29.6669 28.3996 29.1336L26.1996 24.3336C26.0662 23.9336 26.2662 23.4669 26.7329 23.4669H34.9329C35.2662 23.4669 35.4662 23.2669 35.4662 22.9336V1.46693C35.4662 1.13359 35.2662 0.933594 34.9329 0.933594H0.799554C0.466221 0.933594 0.266221 1.13359 0.266221 1.46693V22.9336C0.199554 23.2003 0.466221 23.4669 0.732888 23.4669Z"
      fill="#808080"
    />
    <path
      d="M8.67885 7H27.3211C27.6867 7 28 7.46154 28 8C28 8.53846 27.6867 9 27.3211 9H8.67885C8.31332 9 8 8.53846 8 8C8.05222 7.38461 8.31332 7 8.67885 7Z"
      fill="white"
    />
    <path
      d="M8.67885 15H27.3211C27.6867 15 28 15.4615 28 16C28 16.5385 27.6867 17 27.3211 17H8.67885C8.31332 17 8 16.5385 8 16C8 15.4615 8.31332 15 8.67885 15Z"
      fill="white"
    />
  </svg>
);
