export const Hand = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.125 9.375V5.3125C13.125 4.8981 13.2897 4.50067 13.5827 4.20765C13.8757 3.91462 14.2731 3.75 14.6875 3.75C15.1019 3.75 15.4994 3.91462 15.7924 4.20765C16.0854 4.50067 16.25 4.8981 16.25 5.3125V11.875C16.25 12.6958 16.0884 13.5085 15.7743 14.2668C15.4602 15.0251 14.9998 15.7141 14.4195 16.2944C13.8391 16.8748 13.1501 17.3352 12.3918 17.6492C11.6335 17.9633 10.8208 18.125 10 18.125C6.54692 18.125 5.00005 16.25 2.45317 10.8828C2.24597 10.5233 2.19007 10.0962 2.29775 9.69553C2.40544 9.29482 2.6679 8.95329 3.02739 8.74609C3.38688 8.53889 3.81396 8.48299 4.21467 8.59067C4.61539 8.69836 4.95691 8.96082 5.16411 9.32031L6.87505 12.2891V4.0625C6.87505 3.6481 7.03967 3.25067 7.33269 2.95765C7.62572 2.66462 8.02315 2.5 8.43755 2.5C8.85195 2.5 9.24938 2.66462 9.5424 2.95765C9.83543 3.25067 10 3.6481 10 4.0625V9.375"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.125 5.3125V2.8125C13.125 2.3981 12.9604 2.00067 12.6674 1.70765C12.3743 1.41462 11.9769 1.25 11.5625 1.25C11.1481 1.25 10.7507 1.41462 10.4576 1.70765C10.1646 2.00067 10 2.3981 10 2.8125V4.0625"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
