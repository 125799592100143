import { ChatDocument } from '@util/types/firestore/chat';
import { onSnapshot } from 'firebase/firestore';
import { useState, useMemo, useEffect } from 'react';
import { getChatsByChatIds } from '@util/firestore/messages/';

const useRealtimeChatsByChatIds = (chatIds?: string[]) => {
  const [realtimeChats, setChats] = useState<ChatDocument[] | null>(null);
  const chatsQuery = useMemo(
    () => getChatsByChatIds(chatIds ?? ['n/a']),
    [chatIds]
  );

  useEffect(() => {
    const unsubscribe = onSnapshot(chatsQuery, (snapshot) => {
      const realTimeData = snapshot.docs.map((doc) => doc.data());
      setChats(realTimeData);
    });
    return () => {
      unsubscribe();
    };
  }, [chatsQuery]);

  return {
    realtimeChats,
  };
};

export default useRealtimeChatsByChatIds;
