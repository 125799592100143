import { useErrorBoundary } from 'react-error-boundary';

export default function AppErrorBoundary({
  message,
  id,
}: {
  message: string;
  id: string;
}) {
  const { resetBoundary } = useErrorBoundary();
  return (
    <div className="flex h-screen items-center justify-center bg-gray-100">
      <div className="space-y-4 p-4">
        <div className="flex flex-col items-center space-y-8 sm:flex-row sm:items-center sm:space-x-8 sm:space-y-0">
          <p className="text-9xl font-semibold text-brand-secondary">Oops!</p>
          <div className="space-y-2">
            <h1 id="pageTitle" className="flex items-center space-x-2">
              <svg
                aria-hidden="true"
                className="h-6 w-6 text-brand-secondary"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                />
              </svg>
              <span className="text-xl font-medium text-gray-600 sm:text-2xl">
                You ran into an error!
              </span>
            </h1>
            <p className="text-xl font-normal text-gray-600">
              Please contact support if you believe this to be an issue.
            </p>
            <p className="text-xl font-normal text-gray-600">Error ID: {id}</p>
            <p className="text-xl font-normal text-red-500 ">{message}</p>
            <p className="text-xl font-normal">
              <button
                onClick={resetBoundary}
                className="text-blue-600 hover:underline"
              >
                Return home.
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
