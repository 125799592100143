import { ChatFeatureIcon } from '@c/icons';
import { useInfiniteQuery } from '@tanstack/react-query';
import { TabItem } from '@ui/Tabs';
import { getTwentyChats } from '@util/firestore/messages';
import { useTabs } from '@util/hooks/useTabs';
import { ChatDocument } from '@util/types/firestore/chat';
import { useAuth } from 'context/AuthContext';
import MessagePreview from './MessagePreview';
import useRealtimeChatsByChatIds from '@util/hooks/useRealtimeChatsByChatIds';
import { useMemo } from 'react';

const Conversations = ({
  conversations,
  onConversationSelected,
  selectedChat,
}: {
  conversations: ChatDocument[];
  onConversationSelected: (chat: ChatDocument) => void;
  selectedChat: ChatDocument | undefined;
}) => {
  return (
    <>
      {!conversations.length && (
        <div className="flex h-full w-full flex-col items-center justify-center gap-[1.6rem] lg:max-h-[60rem]">
          <ChatFeatureIcon />
          <h3 className="text-[2.4rem] font-bold ">No Messages</h3>
          <p>Your messages will appear here</p>
        </div>
      )}
      {!!conversations.length &&
        conversations.map((conversation, index) => {
          if (conversation.id) {
            return (
              <MessagePreview
                chat={conversation}
                selected={selectedChat?.id === conversation.id}
                key={conversation.id + '-' + index}
                onClick={() => {
                  if (conversation.id) {
                    onConversationSelected(conversation);
                  }
                }}
              />
            );
          }
        })}
    </>
  );
};

type ConversationListProps = {
  onConversationSelected: (chat: ChatDocument) => void;
  conversations: ChatDocument[] | null;
  selectedChat: ChatDocument | undefined;
  variant: 'messages' | 'offers' | 'orderDetail';
};

export default function ConversationList({
  onConversationSelected,
  selectedChat,
  conversations,
  variant,
}: ConversationListProps) {
  const { userDoc } = useAuth();
  const {
    data: adminChats,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteQuery({
    queryKey: ['adminChats'],
    enabled: !!userDoc?.roles?.admin && variant === 'messages',
    queryFn: ({ pageParam }) => getTwentyChats(pageParam),
    getNextPageParam: (lastPage) => {
      if (lastPage.length < 20) return undefined;
      return lastPage[lastPage.length - 1].last_time;
    },
  });

  //  Listen to the first 10 admin chats in real time
  const firstTenIds = useMemo(() => {
    if (!adminChats?.pages) return ['n/a'];
    return adminChats?.pages
      .flat()
      .slice(0, 10)
      .map((chat) => chat.id!);
  }, [adminChats?.pages]);
  const realtimeAdminChats = useRealtimeChatsByChatIds(firstTenIds ?? ['n/a']);

  const sellerConverstaions = conversations?.filter(
    (conversation) =>
      conversation.seller_id === userDoc?.uid &&
      conversation.uids?.includes(userDoc?.uid)
  );

  const buyerConversations = conversations?.filter(
    (conversation) =>
      conversation.buyer_id === userDoc?.uid &&
      conversation.uids?.includes(userDoc?.uid)
  );

  function onScroll(ev: React.UIEvent<HTMLDivElement, UIEvent>) {
    if (isLoading || isFetchingNextPage || !hasNextPage) return;
    const el = ev.target as HTMLDivElement;
    // if scroll is at top of the page, refresh the chats
    if (el.scrollTop === 0) {
      refetch();
      return;
    }

    const threshold = el.clientHeight * 0.5;
    const thresholdReached =
      el.scrollTop + el.clientHeight >= el.scrollHeight - threshold;
    if (thresholdReached) fetchNextPage();
  }

  const tabItems: TabItem[] = [
    {
      title: 'Buying',
      unreads: userDoc
        ? buyerConversations?.reduce(
            (acc, chat) => (chat.unread?.[userDoc.uid] ? acc + 1 : acc),
            0
          )
        : undefined,
      contents: (
        <div className="grow overflow-y-auto">
          {!!buyerConversations && (
            <Conversations
              conversations={buyerConversations}
              onConversationSelected={onConversationSelected}
              selectedChat={selectedChat}
            />
          )}
        </div>
      ),
    },
    {
      title: 'Selling',
      unreads: userDoc
        ? sellerConverstaions?.reduce(
            (acc, chat) => (chat.unread?.[userDoc.uid] ? acc + 1 : acc),
            0
          )
        : undefined,
      contents: (
        <div className="grow overflow-y-auto">
          {!!sellerConverstaions && (
            <Conversations
              conversations={sellerConverstaions}
              onConversationSelected={onConversationSelected}
              selectedChat={selectedChat}
            />
          )}
        </div>
      ),
    },
    {
      title: 'All',
      contents: (
        <div
          className="grow overflow-y-auto overscroll-y-contain"
          onScroll={onScroll}
        >
          {!!conversations && (
            <Conversations
              conversations={
                userDoc?.roles?.admin && variant === 'messages'
                  ? [
                      ...(realtimeAdminChats.realtimeChats ?? []),
                      ...(adminChats?.pages.flat().slice(10) ?? []),
                    ]
                  : conversations ?? []
              }
              onConversationSelected={onConversationSelected}
              selectedChat={selectedChat}
            />
          )}
        </div>
      ),
    },
  ];

  

  const { currentBody, tabs } = useTabs({ tabItems, full: true, initial: 2 });
  if (!userDoc) return null;

  return (
    <div className="flex h-full flex-col overflow-y-auto border-0 lg:border-r-[1px]">
      <div className="shrink">{tabs}</div>
      {currentBody}
    </div>
  );
}
