import { doc, getDoc } from '@firebase/firestore';
import { db } from '@util/firebase';
import { ApiResponse } from '@util/firestore/products';
import { Cart, cartSchema } from '@util/types/firestore/carts';
import { setDoc } from 'firebase/firestore';
import { GetCartArgs, SetCartArgs } from './cart.types';

export async function getCartState(
  args: GetCartArgs
): Promise<ApiResponse<Cart | null>> {
  try {
    const snapshot = await getDoc(doc(db, 'carts', args.uid));
    const data = cartSchema.parse(snapshot.data());
    return { results: data };
  } catch (e) {
    return { results: null };
  }
}

export async function setCartState(args: SetCartArgs) {
  await setDoc(doc(db, 'carts', args.newCartState.uid), args.newCartState);
}
