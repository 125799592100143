export const MiniShoppingBag = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0004 2.5C13.0612 2.5 14.0787 2.92143 14.8288 3.67157C15.5789 4.42172 16.0004 5.43913 16.0004 6.5H18.0354C18.5536 6.49993 19.0515 6.70099 19.4244 7.06081C19.7973 7.42064 20.016 7.91114 20.0344 8.429L20.4624 20.429C20.4719 20.6974 20.4273 20.9649 20.3312 21.2157C20.2351 21.4665 20.0894 21.6953 19.903 21.8886C19.7165 22.0819 19.493 22.2356 19.2459 22.3407C18.9987 22.4457 18.7329 22.4999 18.4644 22.5H5.53637C5.26781 22.4999 5.00202 22.4457 4.75486 22.3407C4.5077 22.2356 4.28422 22.0819 4.09776 21.8886C3.91129 21.6953 3.76567 21.4665 3.66956 21.2157C3.57345 20.9649 3.52884 20.6974 3.53837 20.429L3.96637 8.429C3.98477 7.91114 4.20344 7.42064 4.57632 7.06081C4.9492 6.70099 5.44719 6.49993 5.96537 6.5H8.00037C8.00037 5.43913 8.4218 4.42172 9.17194 3.67157C9.92209 2.92143 10.9395 2.5 12.0004 2.5ZM8.00037 8.5H5.96537L5.53637 20.5H18.4644L18.0354 8.5H16.0004V9.5C16.0001 9.75488 15.9025 10 15.7275 10.1854C15.5526 10.3707 15.3134 10.4822 15.059 10.4972C14.8045 10.5121 14.554 10.4293 14.3585 10.2657C14.1631 10.1021 14.0375 9.8701 14.0074 9.617L14.0004 9.5V8.5H10.0004V9.5C10.0001 9.75488 9.90249 10 9.72752 10.1854C9.55255 10.3707 9.31342 10.4822 9.05898 10.4972C8.80453 10.5121 8.55399 10.4293 8.35854 10.2657C8.16308 10.1021 8.03747 9.8701 8.00737 9.617L8.00037 9.5V8.5ZM12.0004 4.5C11.4958 4.49984 11.0098 4.69041 10.6398 5.0335C10.2698 5.37659 10.0432 5.84684 10.0054 6.35L10.0004 6.5H14.0004C14.0004 5.96957 13.7897 5.46086 13.4146 5.08579C13.0395 4.71071 12.5308 4.5 12.0004 4.5Z"
      fill="#808080"
    />
  </svg>
);
