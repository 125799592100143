export const CONDITIONS = [
  'new',
  'refurbished',
  'unew',
  'uexcellent',
  'ugood',
  'upoor',
] as const;
export type Condition = (typeof CONDITIONS)[number];

export const CONDITION_LABEL_MAP: Record<Condition, string> = {
  new: 'New',
  refurbished: 'Refurbished',
  unew: 'Used - Like New',
  uexcellent: 'Used - Excellent',
  ugood: 'Used - Good',
  upoor: 'Used - Poor',
};

export const LABEL_CONDITION_MAP: Record<string, Condition> = {
  New: 'new',
  Refurbished: 'refurbished',
  'Used - Like New': 'unew',
  'Used - Excellent': 'uexcellent',
  'Used - Good': 'ugood',
  'Used - Poor': 'upoor',
};
