import { z } from 'zod';

export const addressDocumentSchema = z.object({
  name: z.string().min(1),
  phone: z.string().min(1),
  city_locality: z.string().min(1),
  country_code: z.string().min(1),
  postal_code: z.string().min(1),
  address_line1: z.string().min(1),
  address_line2: z.string().optional(),
  state_province: z.string().default(''),
  is_default: z.boolean().optional().default(false),
  is_shop_location: z.boolean().optional(),
});

export type AddressDocument = z.infer<typeof addressDocumentSchema>;
