import { cva, VariantProps } from 'class-variance-authority';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect } from 'react';

interface DrawerProps extends VariantProps<typeof drawerStyles> {
  dismiss: () => void;
  show: boolean;
  children: React.ReactNode;
  side?: 'left' | 'right';
}

const drawerStyles = cva(
  'fixed bg-brand-white h-screen w-full max-w-[80vw] sm:max-w-[42rem] overflow-clip',
  {
    variants: {
      side: {
        left: 'left-0',
        right: 'right-0',
      },
      top: {
        0: 'top-0',
        inherit: 'top-inherit',
      },
    },
    defaultVariants: {
      side: 'left',
      top: 0,
    },
  }
);

const BaseDrawer = ({ show, dismiss, children, side, top }: DrawerProps) => {
  useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [show]);
  const topStyle = top === 0 ? 'top-0' : 'top-inherit';
  const xOffset = side === 'right' ? '+100%' : '-100%';
  return (
    <div className="fixed z-[60]">
      <AnimatePresence>
        {show && (
          <motion.div
            key="drawer"
            //   slide in from left
            initial={{ x: xOffset }}
            animate={{ x: 0 }}
            //   slide out to left
            exit={{ x: xOffset }}
            transition={{ duration: 0.5 }}
            className={`${drawerStyles({ side, top })} z-10`}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
      {/* dimmer overlay */}
      <AnimatePresence>
        {show && (
          <motion.div
            className={`fixed left-0 top-0 h-full w-full bg-brand-black ${topStyle}`}
            onClick={() => {
              dismiss();
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.5 }}
            exit={{ opacity: 0 }}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default BaseDrawer;
