import { carrierCodeSchema } from '@util/maps/carriers';
import { z } from 'zod';
import { addressDocumentSchema } from './address';
import { paymentDocumentSchema } from './payment';

export const OrderStates = [
  'Awaiting Shipment',
  'In Transit',
  'Delivered',
  'Complete',
  'Cancelled',
  'Refunded',
  'Delivery Error',
  'On Hold',
  'Processing',
] as const;

export type OrderStateType = (typeof OrderStates)[number];

export const OrderNoteSchema = z
  .object({
    text: z.string(),
    created: z.number().optional().default(Date.now()),
    updated: z.date().optional(),
  })
  .optional();

export type OrderNoteType = z.infer<typeof OrderNoteSchema>;

export const orderItemDocumentSchema = z.object({
  id: z.string(),
  qty: z.number(),
  state: z.number().min(0).max(12), //0-Awaiting Shipment, 1-In Transit, 2-Delivered, 3-Complete, 4-Cancelled (Seller Couldn't fulfill Order), 5-Refunded (Buyer asks, we issue refund), 6-Delivery Error, 7-On Hold, 8-Processing
  product_cost: z.number(),
  product_tax: z.number().optional(),
  refund_amount: z.number().optional(),
  refund_reason: z.string().optional(),
  buyer_shipping_cost: z.number(),
  account_id: z.string().optional(),
  delivery_time: z.number().optional(),
  notified_time: z
    .object({
      first: z.number().optional(),
      second: z.number().optional(),
      third: z.number().optional(),
    })
    .optional(), // 96, 168, 240 hours => 4, 7, 10 days
  insurance_amount: z.number().optional(),
  seller_shipping_cost: z.number().optional(),
  label_cost: z.number().optional(),
  ship_engine_pi: z.string().optional(),
  label_id: z.string().optional(),
  carrier: carrierCodeSchema.optional(),
  tracking_number: z.string().optional(),
  label_pdf: z.string().optional(),
  label_png: z.string().optional(),
  rate_id: z.string().optional(),
  seller_id: z.string().optional(),
  variations: z
    .object({
      letter: z.string().optional(),
      number: z.number().optional(),
    })
    .optional(),
});

export const orderDocumentSchema = z.object({
  address: addressDocumentSchema,
  buyer_id: z.string(),
  coupon: z
    .object({
      id: z.string(),
      code: z.string().optional(), // shouldn't be optional, but the backend isn't sending it
      name: z.string().optional(),
      amount_off: z.number().optional().nullable(),
      percent_off: z.number().optional().nullable(),
      off: z.number().optional(),
    })
    .optional(),
  customer_id: z.string().optional(),
  created: z.number().default(Date.now()),
  donate: z.boolean().default(false),
  from_web: z.boolean().default(true),
  has_promo: z.boolean().default(false),
  id: z.string(),
  is_offer: z.boolean().default(false),
  is_auction: z.boolean().default(false),
  item_count: z.number().default(1),
  notes: z.array(OrderNoteSchema).optional(),
  order_num: z.number().min(0), // 5 digits starting at 10000
  payment: paymentDocumentSchema,
  payment_id: z.string().optional().default(''),
  product_ids: z.array(z.string()).optional(),
  reviewed_sellers: z.array(z.string()).optional().default([]),
  seller_arr: z.array(z.string()).default([]),
  sellers: z.object({}).catchall(z.array(orderItemDocumentSchema)).optional(), //TODO: remove optional
  shipping_total: z.number(),
  subtotal: z.number(),
  tax: z.number().optional(),
  tns: z.array(z.string()).optional().default([]),
  total: z.number(),
  total_state: z.number().min(0).max(5).default(0), // 0-Order Placed 1-Delivered 2-Complete, 3- On Hold, 4- Return Requested
  tracking_error: z.number().optional(),
  updated: z.number().optional(),
  states: z.array(z.number()).optional(),
});

export type OrderItemDocument = z.infer<typeof orderItemDocumentSchema>;
export type OrderDocument = z.infer<typeof orderDocumentSchema>;
