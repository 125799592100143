import { realtimeDatabase } from '@util/firebase';
import { ref, set, onValue, onDisconnect } from 'firebase/database';
import { useEffect, useState } from 'react';
import { timeAgo } from '..';

interface OnlineStatus {
  state: 'online' | 'offline';
  last_changed: number;
}

export const useOnlineStatus = (uid?: string) => {
  // set online status
  function setOnlineStatus(uid: string) {
    const statusRef = ref(realtimeDatabase, `/status/${uid}`);
    const last_changed = new Date().getTime(); // todo: change this to use firebase server timestamp
    set(statusRef, {
      state: 'online',
      last_changed,
    });
    const infoConnectedRef = ref(realtimeDatabase, '.info/connected');
    onValue(infoConnectedRef, (snapshot) => {
      const isOnline = snapshot.val();
      if (isOnline) {
        onDisconnect(statusRef).set({
          state: 'offline',
          last_changed,
        });
      }
    });
  }

  const [status, setStatus] = useState<OnlineStatus | null>(null);

  useEffect(() => {
    // get online status
    function getOnlineStatus() {
      const statusRef = ref(realtimeDatabase, '/status/' + uid);
      return onValue(statusRef, (snapshot) => {
        const data = snapshot.val();
        setStatus(data);
      });
    }

    if (uid) {
      const unsubscribe = getOnlineStatus();
      return () => {
        unsubscribe();
      };
    }
  }, [uid]);

  const StatusLabel = () => {
    return !!status?.last_changed ? (
      <div className="flex items-center gap-[0.6rem]">
        {status?.state === 'online' ? (
          <>
            <i className="h-[0.6rem] w-[0.6rem] rounded-full bg-brand-green"></i>
            <span className="text-[1.3rem] font-normal">Online</span>
          </>
        ) : (
          <>
            <i className="h-[0.6rem] w-[0.6rem] rounded-full bg-brand-gray"></i>
            <span className="text-[1.3rem] font-normal">
              Last seen {timeAgo(status?.last_changed)}
            </span>
          </>
        )}
      </div>
    ) : null;
  };

  return { setOnlineStatus, status, StatusLabel };
};
