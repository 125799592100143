export const Bag = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.00002 5.41602H13C15.8334 5.41602 16.1167 6.74101 16.3084 8.35768L17.0584 14.6077C17.3 16.6577 16.6667 18.3327 13.75 18.3327H6.25835C3.33335 18.3327 2.70002 16.6577 2.95002 14.6077L3.70003 8.35768C3.88336 6.74101 4.16669 5.41602 7.00002 5.41602Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.6665 6.66602V3.74935C6.6665 2.49935 7.49984 1.66602 8.74984 1.66602H11.2498C12.4998 1.66602 13.3332 2.49935 13.3332 3.74935V6.66602"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const BagFeature = () => (
  <svg
    width="81"
    height="80"
    viewBox="0 0 81 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M59.1939 21.9746L58.8814 28.4996L57.8877 49.0059C56.6469 48.4599 55.3058 48.1789 53.9502 48.1809C52.6558 48.1808 51.3741 48.4357 50.1783 48.931C48.9824 49.4263 47.8958 50.1523 46.9806 51.0675C46.0653 51.9828 45.3393 53.0693 44.844 54.2652C44.3487 55.461 44.0939 56.7427 44.0939 58.0371C44.0909 59.1331 44.2748 60.2217 44.6377 61.2559H25.9814C24.1246 61.2559 22.3389 60.5412 20.9949 59.26C19.6508 57.9788 18.8515 56.2294 18.7627 54.3746L17.5127 28.4996L17.2002 21.9746H59.1939Z"
      fill="#EF9021"
    />
    <path
      d="M48.3753 27.8531C48.2316 27.8532 48.0894 27.825 47.9566 27.77C47.8239 27.7151 47.7033 27.6345 47.6017 27.533C47.5001 27.4314 47.4195 27.3108 47.3646 27.178C47.3097 27.0453 47.2814 26.903 47.2815 26.7594V18.1938C47.2797 16.8084 46.7285 15.4804 45.7489 14.5009C44.7693 13.5214 43.4412 12.9704 42.0559 12.9688H34.334C32.9487 12.9704 31.6206 13.5214 30.641 14.5009C29.6614 15.4804 29.1102 16.8084 29.1084 18.1938V26.7563C29.1084 27.0463 28.9932 27.3245 28.788 27.5296C28.5829 27.7348 28.3047 27.85 28.0146 27.85C27.7246 27.85 27.4464 27.7348 27.2413 27.5296C27.0361 27.3245 26.9209 27.0463 26.9209 26.7563V18.1938C26.9232 16.2284 27.705 14.3443 29.0947 12.9547C30.4845 11.565 32.3687 10.7834 34.334 10.7812H42.0553C44.0206 10.7834 45.9048 11.565 47.2945 12.9547C48.6843 14.3443 49.4661 16.2284 49.4684 18.1938V26.7563C49.4688 26.9001 49.4408 27.0426 49.3861 27.1756C49.3314 27.3086 49.2509 27.4296 49.1494 27.5314C49.0478 27.6333 48.9272 27.7142 48.7944 27.7694C48.6615 27.8246 48.5191 27.853 48.3753 27.8531Z"
      fill="#242E5F"
    />
    <path
      d="M63.8 58.0379C63.7996 60.3675 62.9737 62.6217 61.4688 64.4C59.964 66.1784 57.8776 67.366 55.5801 67.7519C53.2827 68.1378 50.9228 67.6971 48.9195 66.508C46.9162 65.3189 45.3991 63.4583 44.6375 61.2567C44.2746 60.2224 44.0908 59.1339 44.0938 58.0379C44.0937 56.7435 44.3486 55.4618 44.8439 54.266C45.3392 53.0701 46.0652 51.9836 46.9804 51.0683C47.8957 50.153 48.9823 49.427 50.1781 48.9317C51.374 48.4365 52.6557 48.1816 53.95 48.1817C55.3056 48.1797 56.6467 48.4607 57.8875 49.0067C59.6455 49.7727 61.1415 51.0354 62.1918 52.6398C63.2421 54.2441 63.8011 56.1203 63.8 58.0379Z"
      fill="#242E5F"
    />
    <path
      d="M58.6251 56.9411H55.0407V53.3555C55.0407 53.0654 54.9255 52.7872 54.7203 52.5821C54.5152 52.377 54.237 52.2617 53.947 52.2617C53.6569 52.2617 53.3787 52.377 53.1736 52.5821C52.9684 52.7872 52.8532 53.0654 52.8532 53.3555V56.9411H49.2676C48.9775 56.9411 48.6993 57.0563 48.4942 57.2614C48.2891 57.4666 48.1738 57.7448 48.1738 58.0348C48.1738 58.3249 48.2891 58.6031 48.4942 58.8082C48.6993 59.0134 48.9775 59.1286 49.2676 59.1286H52.8532V62.7142C52.8532 63.0043 52.9684 63.2825 53.1736 63.4876C53.3787 63.6927 53.6569 63.808 53.947 63.808C54.237 63.808 54.5152 63.6927 54.7203 63.4876C54.9255 63.2825 55.0407 63.0043 55.0407 62.7142V59.1286H58.6251C58.9152 59.1286 59.1934 59.0134 59.3985 58.8082C59.6036 58.6031 59.7188 58.3249 59.7188 58.0348C59.7188 57.7448 59.6036 57.4666 59.3985 57.2614C59.1934 57.0563 58.9152 56.9411 58.6251 56.9411Z"
      fill="white"
    />
  </svg>
);
