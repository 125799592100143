export const CameraFeature = () => (
  <svg
    width="88"
    height="63"
    viewBox="0 0 88 63"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M83.9426 17.6971L84 56.2715C84.0003 56.9876 83.6819 57.6746 83.1147 58.1817C82.5475 58.6888 81.7778 58.9747 80.9745 58.9765L63 59C68.06 50.5619 70.925 37.2803 70.9082 27.0426C70.9037 23.0024 70.4432 18.9729 69.5337 15.0149L80.9082 15C81.7115 14.9998 82.4821 15.2836 83.051 15.7892C83.6198 16.2948 83.9405 16.981 83.9426 17.6971Z"
      fill="#EF9021"
    />
    <path
      d="M58.4206 12.4373L29.3076 12.3227L32.1386 2.86371C32.3646 2.12059 32.8214 1.46961 33.4423 1.00599C34.0631 0.542378 34.8155 0.290467 35.5891 0.287109H35.6028L52.2197 0.352635C52.9957 0.359103 53.7491 0.615591 54.3691 1.08435C54.9891 1.5531 55.4429 2.20936 55.6637 2.95654L58.4206 12.4373Z"
      fill="#242E5F"
    />
    <path
      d="M84.0605 62.0418H3.71176C2.72768 62.0407 1.78421 61.6476 1.08836 60.9487C0.392513 60.2499 0.00110725 59.3024 0 58.3141V15.7746C0.00110782 14.7863 0.392513 13.8388 1.08836 13.1399C1.78421 12.4411 2.72768 12.048 3.71176 12.0469H84.0605C85.0446 12.048 85.9881 12.4411 86.6839 13.1399C87.3798 13.8388 87.7712 14.7863 87.7723 15.7746V58.3141C87.7712 59.3024 87.3798 60.2499 86.6839 60.9487C85.9881 61.6476 85.0446 62.0407 84.0605 62.0418ZM3.71176 12.4854C2.84346 12.4864 2.01099 12.8333 1.397 13.4499C0.783017 14.0665 0.437661 14.9025 0.436678 15.7746V58.3141C0.437661 59.1861 0.783017 60.0221 1.397 60.6388C2.01099 61.2554 2.84346 61.6022 3.71176 61.6032H84.0605C84.9288 61.6022 85.7613 61.2554 86.3753 60.6388C86.9893 60.0221 87.3346 59.1861 87.3356 58.3141V15.7746C87.3346 14.9025 86.9893 14.0665 86.3753 13.4499C85.7613 12.8333 84.9288 12.4864 84.0605 12.4854H3.71176Z"
      fill="#242E5F"
    />
    <path
      d="M47.3803 8.87222H40.3935C39.9013 8.87222 39.4292 8.67585 39.0812 8.32631C38.7331 7.97677 38.5376 7.5027 38.5376 7.00838C38.5376 6.51405 38.7331 6.03998 39.0812 5.69044C39.4292 5.3409 39.9013 5.14453 40.3935 5.14453H47.3803C47.8725 5.14453 48.3446 5.3409 48.6926 5.69044C49.0407 6.03998 49.2362 6.51405 49.2362 7.00838C49.2362 7.5027 49.0407 7.97677 48.6926 8.32631C48.3446 8.67585 47.8725 8.87222 47.3803 8.87222Z"
      fill="white"
    />
    <path
      d="M43.8872 53.7127C40.6053 53.7127 37.3971 52.7354 34.6682 50.9042C31.9394 49.073 29.8125 46.4703 28.5566 43.4252C27.3007 40.3801 26.972 37.0293 27.6123 33.7966C28.2526 30.5639 29.833 27.5945 32.1537 25.2639C34.4744 22.9332 37.4311 21.3461 40.6499 20.703C43.8688 20.06 47.2053 20.39 50.2374 21.6514C53.2695 22.9127 55.8611 25.0487 57.6844 27.7892C59.5078 30.5298 60.481 33.7518 60.481 37.0478C60.476 41.4661 58.7262 45.702 55.6153 48.8262C52.5045 51.9504 48.2867 53.7077 43.8872 53.7127ZM43.8872 20.8214C40.6917 20.8214 37.5679 21.773 34.9108 23.556C32.2538 25.339 30.1829 27.8732 28.96 30.8382C27.7371 33.8032 27.4172 37.0658 28.0406 40.2134C28.664 43.361 30.2028 46.2523 32.4624 48.5216C34.7221 50.7909 37.601 52.3363 40.7351 52.9624C43.8693 53.5885 47.118 53.2672 50.0703 52.039C53.0226 50.8109 55.546 48.7311 57.3214 46.0627C59.0967 43.3943 60.0443 40.2571 60.0443 37.0478C60.0395 32.7458 58.3357 28.6213 55.3067 25.5793C52.2777 22.5374 48.1709 20.8262 43.8872 20.8214Z"
      fill="#242E5F"
    />
    <path
      d="M43.8867 48.0135C41.7275 48.0135 39.6168 47.3705 37.8215 46.1658C36.0263 44.9611 34.627 43.2488 33.8007 41.2454C32.9745 39.242 32.7583 37.0376 33.1795 34.9108C33.6007 32.784 34.6405 30.8305 36.1672 29.2972C37.694 27.7638 39.6392 26.7196 41.7569 26.2966C43.8746 25.8736 46.0696 26.0907 48.0644 26.9205C50.0592 27.7503 51.7642 29.1556 52.9638 30.9586C54.1634 32.7616 54.8037 34.8813 54.8037 37.0497C54.8004 39.9565 53.6492 42.7433 51.6025 44.7987C49.5559 46.8541 46.7811 48.0102 43.8867 48.0135ZM43.8867 26.5245C41.8139 26.5245 39.7876 27.1418 38.0642 28.2983C36.3407 29.4548 34.9974 31.0987 34.2042 33.0219C33.4109 34.9451 33.2034 37.0614 33.6078 39.1031C34.0122 41.1448 35.0103 43.0202 36.476 44.4922C37.9417 45.9642 39.8091 46.9666 41.8421 47.3727C43.8751 47.7789 45.9823 47.5704 47.8973 46.7738C49.8123 45.9772 51.4491 44.6281 52.6007 42.8972C53.7523 41.1664 54.367 39.1314 54.367 37.0497C54.3638 34.2592 53.2587 31.5839 51.2939 29.6107C49.3291 27.6376 46.6653 26.5276 43.8867 26.5245Z"
      fill="#242E5F"
    />
    <path
      d="M43.8877 45.3798C48.4699 45.3798 52.1846 41.6492 52.1846 37.0473C52.1846 32.4454 48.4699 28.7148 43.8877 28.7148C39.3055 28.7148 35.5908 32.4454 35.5908 37.0473C35.5908 41.6492 39.3055 45.3798 43.8877 45.3798Z"
      fill="#242E5F"
    />
    <path
      d="M38.8652 37.0483C38.8654 35.7275 39.3404 34.4511 40.2028 33.454C41.0653 32.4569 42.2573 31.806 43.5595 31.621C42.7855 31.5105 41.9969 31.5682 41.2471 31.7903C40.4973 32.0123 39.8037 32.3935 39.2133 32.9081C38.6229 33.4227 38.1494 34.0587 37.8249 34.773C37.5004 35.4872 37.3325 36.2632 37.3325 37.0483C37.3325 37.8334 37.5004 38.6093 37.8249 39.3236C38.1494 40.0379 38.6229 40.6739 39.2133 41.1885C39.8037 41.7031 40.4973 42.0843 41.2471 42.3063C41.9969 42.5284 42.7855 42.5861 43.5595 42.4756C42.2573 42.2906 41.0653 41.6397 40.2028 40.6426C39.3404 39.6455 38.8654 38.3691 38.8652 37.0483Z"
      fill="white"
    />
    <path
      d="M80.1308 12.4902H69.2139V12.271C69.2139 10.8171 69.789 9.42273 70.8126 8.39467C71.8363 7.36662 73.2247 6.78906 74.6723 6.78906C76.12 6.78906 77.5084 7.36662 78.5321 8.39467C79.5557 9.42273 80.1308 10.8171 80.1308 12.271V12.4902ZM69.6552 12.0517H79.6894C79.6331 10.7533 79.0799 9.52688 78.1452 8.62813C77.2105 7.72939 75.9664 7.22771 74.6723 7.22771C73.3783 7.22771 72.1342 7.72939 71.1995 8.62813C70.2647 9.52688 69.7115 10.7533 69.6552 12.0517Z"
      fill="#242E5F"
    />
    <path
      d="M5.45937 17.5326C6.0623 17.5326 6.55107 17.0417 6.55107 16.4362C6.55107 15.8307 6.0623 15.3398 5.45937 15.3398C4.85644 15.3398 4.36768 15.8307 4.36768 16.4362C4.36768 17.0417 4.85644 17.5326 5.45937 17.5326Z"
      fill="#242E5F"
    />
    <path
      d="M11.1366 17.5326C11.7395 17.5326 12.2283 17.0417 12.2283 16.4362C12.2283 15.8307 11.7395 15.3398 11.1366 15.3398C10.5337 15.3398 10.0449 15.8307 10.0449 16.4362C10.0449 17.0417 10.5337 17.5326 11.1366 17.5326Z"
      fill="#242E5F"
    />
    <path
      d="M16.8129 17.5326C17.4158 17.5326 17.9046 17.0417 17.9046 16.4362C17.9046 15.8307 17.4158 15.3398 16.8129 15.3398C16.21 15.3398 15.7212 15.8307 15.7212 16.4362C15.7212 17.0417 16.21 17.5326 16.8129 17.5326Z"
      fill="#242E5F"
    />
  </svg>
);
