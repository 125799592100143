export const Cancel = () => (
  <svg
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25 4.6875C20.9826 4.6875 17.0554 5.87881 13.715 8.11077C10.3746 10.3427 7.77111 13.5151 6.23371 17.2267C4.6963 20.9384 4.29405 25.0225 5.07781 28.9628C5.86157 32.903 7.79615 36.5224 10.6369 39.3631C13.4777 42.2039 17.097 44.1384 21.0372 44.9222C24.9775 45.706 29.0616 45.3037 32.7733 43.7663C36.4849 42.2289 39.6573 39.6254 41.8892 36.285C44.1212 32.9446 45.3125 29.0174 45.3125 25C45.3022 19.616 43.1588 14.4554 39.3517 10.6483C35.5446 6.8412 30.384 4.69782 25 4.6875ZM32.3633 30.1367C32.6567 30.433 32.8213 30.833 32.8213 31.25C32.8213 31.667 32.6567 32.067 32.3633 32.3633C32.0646 32.6521 31.6654 32.8135 31.25 32.8135C30.8346 32.8135 30.4354 32.6521 30.1367 32.3633L25 27.207L19.8633 32.3633C19.5646 32.6521 19.1654 32.8135 18.75 32.8135C18.3346 32.8135 17.9354 32.6521 17.6367 32.3633C17.3433 32.067 17.1787 31.667 17.1787 31.25C17.1787 30.833 17.3433 30.433 17.6367 30.1367L22.793 25L17.6367 19.8633C17.3876 19.5597 17.2602 19.1743 17.2795 18.782C17.2988 18.3897 17.4633 18.0187 17.741 17.741C18.0187 17.4632 18.3898 17.2988 18.782 17.2795C19.1743 17.2602 19.5597 17.3876 19.8633 17.6367L25 22.793L30.1367 17.6367C30.4403 17.3876 30.8257 17.2602 31.218 17.2795C31.6103 17.2988 31.9813 17.4632 32.2591 17.741C32.5368 18.0187 32.7013 18.3897 32.7205 18.782C32.7398 19.1743 32.6124 19.5597 32.3633 19.8633L27.207 25L32.3633 30.1367Z"
      fill="#FF4242"
    />
  </svg>
);
