import React, { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';

import Select from '@ui/Select';
import Button from '@ui/Button';

import AddPaymentMethodModal from '@c/modals/AddPaymentMethodModal';

import { getPaymentMethods } from '@util/firestore/payments';

interface SelectOption<T> {
    label: string;
    value: T;
    id: string;
}

interface SelectPaymentMethodProps {
    onPaymentMethodChange: (id: string) => void;
}

function SelectPaymentCard({ onPaymentMethodChange }: SelectPaymentMethodProps) {

  const [paymentMethodId, setPaymentMethodId] = useState('');
  const [addPaymentMethod, setAddPaymentMethod] = useState<boolean>(false);

  const { data: paymentData } = useQuery(['paymentMethods'], () =>
    getPaymentMethods()
  );

  useEffect(() => {
    if (paymentData && paymentData[0]?.id) {
      setPaymentMethodId(paymentData[0].id);
      onPaymentMethodChange(paymentData[0].id);
    }
  }, [paymentData, onPaymentMethodChange]);

  const transformedPaymentData: SelectOption<string>[] = (paymentData ?? []).map(paymentMethod => ({
    label: paymentMethod.brand && paymentMethod.last4 ? `${paymentMethod.brand} - ${paymentMethod.last4}` : '',
    value: paymentMethod.id ?? '',
    id: paymentMethod.id ?? '',
  }));

  const handlePaymenthSelect = (e: any) => {
    const selectedPaymentMethodId = e.value;
    setPaymentMethodId(selectedPaymentMethodId);
    onPaymentMethodChange(selectedPaymentMethodId);
  };

  return (
    <>
      <Select
        disabled={!paymentData?.length}
        allowEmpty={false}
        placeholder="Select payment method"
        options={transformedPaymentData}
        value={paymentMethodId || (paymentData?.[0]?.last4 ?? '')}
        onChange={(e) => {
          handlePaymenthSelect(e);
        }}
      />

      <div className="flex w-full justify-center">
        <Button
          type="text"
          text="Add New Payment Method"
          onClick={() => {
            setAddPaymentMethod(true);
          }}
        />
      </div>
      
      {addPaymentMethod && (
        <AddPaymentMethodModal isOpen={addPaymentMethod} dismiss={() => {
            setAddPaymentMethod(false)
        }} />
      )}
    </>
  );
}

export default SelectPaymentCard;
