import { GearIcon } from '@c/icons';

const FancySpinner = () => {
  return (
    <div>
      {/* spinning gear */}
      <div className="h-fit w-fit animate-spin text-brand-secondary">
        <GearIcon height={100} width={100} />
      </div>
      {/* shadow */}
      <div className="mx-auto h-6 w-[8rem] rounded-[100%] bg-brand-secondary-light bg-opacity-80 blur-sm" />
    </div>
  );
};

export default FancySpinner;
