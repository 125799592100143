import { BaseIconProps } from './types';

export const Computer = ({ height = 24, width = 24 }: BaseIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 20C1.45 20 0.979333 19.8043 0.588 19.413C0.196 19.021 0 18.55 0 18H4C3.45 18 2.97933 17.8043 2.588 17.413C2.196 17.021 2 16.55 2 16V5C2 4.45 2.196 3.979 2.588 3.587C2.97933 3.19567 3.45 3 4 3H20C20.55 3 21.021 3.19567 21.413 3.587C21.8043 3.979 22 4.45 22 5V16C22 16.55 21.8043 17.021 21.413 17.413C21.021 17.8043 20.55 18 20 18H24C24 18.55 23.8043 19.021 23.413 19.413C23.021 19.8043 22.55 20 22 20H2ZM12 19C12.2833 19 12.521 18.904 12.713 18.712C12.9043 18.5207 13 18.2833 13 18C13 17.7167 12.9043 17.4793 12.713 17.288C12.521 17.096 12.2833 17 12 17C11.7167 17 11.4793 17.096 11.288 17.288C11.096 17.4793 11 17.7167 11 18C11 18.2833 11.096 18.5207 11.288 18.712C11.4793 18.904 11.7167 19 12 19ZM4 16H20V5H4V16ZM4 16V5V16Z"
        fill="#EF9021"
      />
    </svg>
  );
};

export const Drone = ({ height = 24, width = 24 }: BaseIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 8V7H17V8H20V9H19V11H15.7L14.199 8.4H9.855L8.355 11H5V9H4V8H7V7H0V8H3V9H2V14H5V13H8.167L8.383 13.374C7.0661 14.0418 5.95963 15.0609 5.18607 16.3186C4.41251 17.5763 4.00201 19.0235 4 20.5C4 20.6326 4.05268 20.7598 4.14645 20.8536C4.24021 20.9473 4.36739 21 4.5 21C4.63261 21 4.75979 20.9473 4.85355 20.8536C4.94732 20.7598 5 20.6326 5 20.5C5.00174 19.199 5.36598 17.9241 6.05186 16.8186C6.73773 15.713 7.71811 14.8204 8.883 14.241L9.855 15.924H14.199L15.159 14.261C16.3129 14.8448 17.2826 15.7368 17.9606 16.838C18.6386 17.9393 18.9984 19.2068 19 20.5C19 20.6326 19.0527 20.7598 19.1464 20.8536C19.2402 20.9473 19.3674 21 19.5 21C19.6326 21 19.7598 20.9473 19.8536 20.8536C19.9473 20.7598 20 20.6326 20 20.5C19.9983 19.0314 19.5924 17.5915 18.8269 16.3381C18.0614 15.0848 16.9658 14.0662 15.66 13.394L15.887 13H19V14H22V9H21V8H24Z"
        fill="#EF9021"
      />
    </svg>
  );
};

export const Lighting = ({ height = 24, width = 24 }: BaseIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.00018 1V3H9.00018V6.4L4.11018 4.38L1.43018 10.84L6.97018 13.14L11.9402 16.82L13.7902 17.59L17.6202 8.35L15.7702 7.58L11.0002 6.87V3H14.0002V1H6.00018ZM21.8102 6.29L19.5002 7.25L20.2602 9.1L22.5702 8.14L21.8102 6.29ZM19.7802 13.57L19.0002 15.42L21.7902 16.57L22.5502 14.72L19.7802 13.57ZM16.1902 18.93L14.3402 19.69L15.3002 22L17.1502 21.23L16.1902 18.93Z"
        fill="#EF9021"
      />
    </svg>
  );
};

export const VideoCamera = ({ height = 24, width = 24 }: BaseIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 16H15L11.55 11.5L9.25 14.5L7.7 12.5L5 16ZM4 20C3.45 20 2.97933 19.8043 2.588 19.413C2.196 19.021 2 18.55 2 18V6C2 5.45 2.196 4.97933 2.588 4.588C2.97933 4.196 3.45 4 4 4H16C16.55 4 17.021 4.196 17.413 4.588C17.8043 4.97933 18 5.45 18 6V10.5L22 6.5V17.5L18 13.5V18C18 18.55 17.8043 19.021 17.413 19.413C17.021 19.8043 16.55 20 16 20H4Z"
        fill="#EF9021"
      />
    </svg>
  );
};

export const CameraLens = ({ height = 24, width = 24 }: BaseIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.2 8.375L14.7 2.375C16.2167 2.775 17.5627 3.50833 18.738 4.575C19.9127 5.64167 20.7667 6.90833 21.3 8.375H11.2ZM8.425 10.875L5 4.875C5.9 3.99167 6.946 3.29167 8.138 2.775C9.32933 2.25833 10.6167 2 12 2C12.2167 2 12.4667 2.01233 12.75 2.037C13.0333 2.06233 13.2917 2.09167 13.525 2.125L8.425 10.875ZM2.325 14.5C2.225 14.1 2.146 13.6917 2.088 13.275C2.02933 12.8583 2 12.4333 2 12C2 10.8167 2.19167 9.7 2.575 8.65C2.95833 7.6 3.5 6.64167 4.2 5.775L9.25 14.5H2.325ZM9.325 21.625C7.80833 21.225 6.45833 20.4917 5.275 19.425C4.09167 18.3583 3.23333 17.0917 2.7 15.625H12.775L9.325 21.625ZM12 22C11.75 22 11.496 21.9833 11.238 21.95C10.9793 21.9167 10.7333 21.8833 10.5 21.85L15.575 13.125L19 19.125C18.1 20.0083 17.0543 20.7083 15.863 21.225C14.671 21.7417 13.3833 22 12 22ZM19.8 18.225L14.75 9.5H21.675C21.775 9.9 21.8543 10.3083 21.913 10.725C21.971 11.1417 22 11.5667 22 12C22 13.1667 21.796 14.2833 21.388 15.35C20.9793 16.4167 20.45 17.375 19.8 18.225Z"
        fill="#EF9021"
      />
    </svg>
  );
};

export const DigitalCamera = ({ height = 24, width = 24 }: BaseIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 17.5C13.25 17.5 14.3127 17.0627 15.188 16.188C16.0627 15.3127 16.5 14.25 16.5 13C16.5 11.75 16.0627 10.6873 15.188 9.812C14.3127 8.93733 13.25 8.5 12 8.5C10.75 8.5 9.68733 8.93733 8.812 9.812C7.93733 10.6873 7.5 11.75 7.5 13C7.5 14.25 7.93733 15.3127 8.812 16.188C9.68733 17.0627 10.75 17.5 12 17.5ZM4 21C3.45 21 2.97933 20.8043 2.588 20.413C2.196 20.021 2 19.55 2 19V7C2 6.45 2.196 5.97933 2.588 5.588C2.97933 5.196 3.45 5 4 5H7.15L9 3H15L16.85 5H20C20.55 5 21.021 5.196 21.413 5.588C21.8043 5.97933 22 6.45 22 7V19C22 19.55 21.8043 20.021 21.413 20.413C21.021 20.8043 20.55 21 20 21H4Z"
        fill="#EF9021"
      />
    </svg>
  );
};
