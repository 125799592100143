import { addressDocumentSchema } from '@util/types/firestore/address';
import { orderDocumentSchema } from '@util/types/firestore/order';
import { StripeAddress } from '@util/types/firestore/stripe';
import { z } from 'zod';

const metadataSchema = z
  .object({
    buyer_id: z.string(),
    from_web: z.string(),
    order_id: z.string(),
    donation: z.string(),
    shipping_cost: z.string(),
  })
  .catchall(z.string())
  .partial();

export const createPaymentArgsSchema = z.object({
  amount: z.number().positive(),
  order: orderDocumentSchema,
  metadata: metadataSchema.optional(),
  currency: z.string().optional(),
  affirm: z.boolean().optional(),
});

export const minimalOrderDocumentSchema = orderDocumentSchema.pick({
  address: true,
  buyer_id: true,
  customer_id: true,
  item_count: true,
  payment: true,
  product_ids: true,
  sellers: true,
  shipping_total: true,
  subtotal: true,
  total: true,
  id: true,
  order_num: true,
  donate: true,
  coupon: true,
  tax: true,
});

export const updatePaymentArgsSchema = z.object({
  pi_id: z.string(),
  address: addressDocumentSchema,
  amount: z.number().positive(),
  order: minimalOrderDocumentSchema,
  metadata: metadataSchema,
  currency: z.string().optional(),
  affirm: z.boolean().optional(),
});

export const paymentSchema = z.object({
  id: z.string(),
  //0-card 1-apple pay, 2-paypal, 3-google pay, 4-mx balance, 5-klarna, 6-Affirm
  type: z.number().int().min(0).max(6),
  name: z.string().optional(),
  brand: z.string().optional(),
  last4: z.string().optional(),
  saved: z.boolean().optional(),
});

export type MinimalOrderDocument = z.infer<typeof minimalOrderDocumentSchema>;
export type UpdatePaymentArgs = z.infer<typeof updatePaymentArgsSchema>;
export type CreatePaymentArgs = z.infer<typeof createPaymentArgsSchema>;
export type Payment = z.infer<typeof paymentSchema>;

export type StripeTransaction = {
  id?: string;
  type: 'payment' | 'transfer' | 'payout';
  net: number;
  created: number;
  description?: string;
  source?: string;
  orderId?: string;
  orderNum?: string;
  destination?: string;
  currency?: string;
};
export type PayoutResponse = {
  id: string;
  object: string;
  amount: number;
  arrival_date: number;
  automatic: boolean;
  balance_transaction: string;
  created: number;
  currency: string;
  description?: any;
  destination: string;
  failure_balance_transaction?: any;
  failure_code?: any;
  failure_message?: any;
  livemode: boolean;
  metadata: Object;
  method: string;
  original_payout?: any;
  reversed_by?: any;
  source_type: string;
  statement_descriptor: string;
  status: string;
  type: string;
};

export type StripeAccountResponse = {
  id?: string;
  business_type?: 'individual' | 'company';
  country: string;
  business_profile?: {
    mcc: string;
    name?: string;
    product_description?: string;
    support_address?: null;
    support_email?: null;
    support_phone?: string;
    support_url?: null;
    url?: string;
  };
  company: {
    name: string;
    address: StripeAddress;
    tax_id: string;
    phone?: string;
    directors_provided?: boolean;
    executives_provided?: boolean;
    owners_provided?: boolean;
    tax_id_provided?: boolean;
    verification?: Object;
  };
  capabilities?: {
    card_payments: {
      requested: boolean;
    };
    transfers: {
      requested: boolean;
    };
  };
  charges_enabled?: boolean;
  created?: number;
  default_currency?: string;
  details_submitted?: boolean;
  external_account?: string;
  external_accounts?: {
    object: string;
    data: ExternalAccount[];
    has_more: boolean;
    url: string;
  };
  metadata?: { uid: string; fee?: number };
  payouts_enabled?: boolean;
  requirements?: {
    current_deadline: null;
    currently_due: string[];
    disabled_reason: null;
    errors: any[];
    eventually_due: string[];
    past_due: string[];
    pending_verification: any[];
  };
  settings?: {
    bacs_debit_payments: Object;
    branding: Object;
    card_payments: Object;
    dashboard: Object;
    payments: Object;
    payouts: Object;
    sepa_debit_payments: Object;
  };
  tos_acceptance?: {
    date: number;
    ip: string;
    user_agent: null;
  };
  type?: string;
  object?: string;
};

export type ExternalAccount = {
  id: string;
  object: string;
  name: string;
  account: string;
  account_holder_name: string;
  account_holder_type: null;
  available_payout_methods: string[];
  bank_name: string;
  country: string;
  currency: string;
  default_for_currency: boolean;
  fingerprint: string;
  last4: string;
  metadata: Object;
  type?: string;
  funding?: string;
  card?: {
    id: string;
    brand: string;
    last4: string;
    exp_month: string;
    exp_year: string;
    object: 'card';
    funding?: string;
  };
  exp_month?: number;
  exp_year?: number;
  address_zip?: string;
  bank_account?: StripeBankAccount;
  routing_number: string;
  status: string;
  brand: string;
};
export type StripeBankAccount = {
  account_holder_name: string;
  bank_name: string;
  country: string;
  currency: string;
  id: string;
  last4: string;
  name: string;
  object: 'bank_account';
  routing_number: string;
};
