import { ArrowUpRightIcon, ChatFeatureIcon, SaleFeatureIcon } from '@c/icons';
import MobileChatModal from '@c/modals/MobileChatModal';
import ProductInfo from '@c/products/ProductInfo';
import { useQuery } from '@tanstack/react-query';
import Button from '@ui/Button';
import Spinner from '@ui/Spinner';
import { markAsRead } from '@util/firestore/messages';
import { getOrderById } from '@util/firestore/orders';
import { getProductById } from '@util/firestore/products';
import { isTablet } from '@util/index';
import { ChatDocument } from '@util/types/firestore/chat';
import { getProductUrl } from '@util/urlHelpers';
import { useAuth } from 'context/AuthContext';
import { useChat } from 'context/ChatContext';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import ConversationList from './ConversationList';
import MessageOffer from './MessageOffer';
import MessageWindow from './MessageWindow';

export const OrderPreview = ({ orderId }: { orderId: string }) => {
  const { data: order, isLoading: isLoadingOrder } = useQuery(
    ['order', orderId],
    () => getOrderById(orderId),
    {
      enabled: !!orderId,
    }
  );
  const { setChatOpen } = useChat();
  const { userDoc } = useAuth();
  if (isLoadingOrder) {
    return (
      <div className="mx-auto my-4 h-[3rem] w-11/12 animate-pulse rounded-2xl bg-brand-gray" />
    );
  }
  if (!order) {
    return <div>Order not found</div>;
  }
  const getHref = () => {
    if (userDoc && userDoc.uid === order.buyer_id) {
      return `/dashboard/purchases/${order.id}`;
    }
    return `/dashboard/orders/${order.id}`;
  };

  return (
    <div className="flex items-center justify-between gap-[1.2rem] bg-brand-lightest-gray px-[1.6rem] py-[1.2rem]">
      <div className="flex flex-col gap-[0.8rem]">
        <Link href={getHref()} className="font-medium text-brand-gray">
          Order #{order.order_num}
        </Link>
        <p className="text-[1.4rem] text-brand-gray">
          {order.product_ids!.length} item(s)
        </p>
      </div>
      <Button
        type="text"
        leadingIcon={<ArrowUpRightIcon />}
        width="extraSmall"
        height="small"
        onClick={() => setChatOpen(false)}
        href={getHref()}
      />
    </div>
  );
};

export const ProductPreview = ({ productId }: { productId: string }) => {
  const { data: product, isLoading: isLoadingProduct } = useQuery(
    ['product', productId],
    () => getProductById(productId),
    {
      enabled: !!productId,
    }
  );
  if (isLoadingProduct) {
    return (
      <div className="m-auto h-[10rem] w-[10rem]">
        <Spinner />
      </div>
    );
  }
  if (!product) {
    return <div>Product not found</div>;
  }

  return (
    <div className="flex items-center justify-between gap-[1.2rem] bg-brand-lightest-gray px-[1.6rem] py-[1.2rem]">
      <ProductInfo product={product} />
      <Button
        type="text"
        leadingIcon={<ArrowUpRightIcon />}
        width="extraSmall"
        height="small"
        href={getProductUrl(product.slug)}
      />
    </div>
  );
};

const getEmptyState = (variant: 'messages' | 'offers' | 'orderDetail') => {
  if (variant === 'offers') {
    return (
      <div className="flex h-full w-full flex-col items-center justify-center gap-[1.6rem] text-center">
        <SaleFeatureIcon />
        <p className="font-medium text-brand-gray">
          Select an offer to view the details
        </p>
      </div>
    );
  }
  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-[1.6rem] text-center">
      <ChatFeatureIcon />
      <p className="font-medium text-brand-gray">
        Select a chat to view the conversation
      </p>
    </div>
  );
};

interface MessageCenterProps {
  chats: ChatDocument[];
  variant: 'messages' | 'offers' | 'orderDetail';
  isShared?: boolean;
}
export default function MessageCenter({
  chats,
  variant,
  isShared,
}: MessageCenterProps) {
  const { user } = useAuth();
  const router = useRouter();

  const [selectedChat, setSelectedChat] = React.useState<
    ChatDocument | undefined
  >(isShared ? chats[0] : undefined);

  const updateSelectedChat = (chat: ChatDocument) => {
    setSelectedChat(chat); // TODO: needs a useCallback or similar
    if (chat?.id && user?.uid && chat.unread?.[user.uid]) {
      markAsRead(chat.id, user.uid);
    }
  };

  // Chats won't show new messages without this but I'd rather not have it
  useEffect(() => {
    if (chats && chats.length > 0 && selectedChat) {
      updateSelectedChat(selectedChat);
    }
  }, [chats, updateSelectedChat]);

  return (
    <>
      {/* Desktop */}
      <div className=" hidden h-full min-h-0  w-full grow overflow-hidden rounded-xl border-[1px] bg-brand-white lg:flex lg:h-[calc(100vh_-_15rem)]">
        {!isShared && (
          <div className="w-full grow lg:max-w-[39rem]">
            <ConversationList
              conversations={chats}
              onConversationSelected={updateSelectedChat}
              selectedChat={selectedChat}
              variant={variant}
            />
          </div>
        )}
        <div className="w-full grow">
          {selectedChat && !isTablet() ? (
            <MessageWindow
              chat={selectedChat}
              key={selectedChat.id}
              previewSlot={
                variant === 'offers' ? (
                  <MessageOffer offer_id={selectedChat.offer_id} />
                ) : selectedChat.product_id ? (
                  <ProductPreview productId={selectedChat.product_id} />
                ) : selectedChat.order_id ? (
                  <OrderPreview orderId={selectedChat.order_id}></OrderPreview>
                ) : null
              }
              goBack={() => {
                router.push('/dashboard/messages');
              }}
            />
          ) : (
            getEmptyState(variant)
          )}
        </div>
      </div>
      {/* Mobile */}
      <div className="h-full overflow-hidden rounded-xl bg-brand-white lg:hidden">
        {selectedChat && (
          <MobileChatModal
            isOpen={selectedChat && isTablet() ? true : false}
            goBack={() => {
              setSelectedChat(undefined);
            }}
            chat={selectedChat}
            previewSlot={
              variant === 'offers' ? (
                <MessageOffer offer_id={selectedChat.offer_id} />
              ) : selectedChat.product_id ? (
                <ProductPreview productId={selectedChat.product_id} />
              ) : selectedChat.order_id ? (
                <OrderPreview orderId={selectedChat.order_id}></OrderPreview>
              ) : null
            }
          />
        )}
        <div className={`${selectedChat ? 'hidden' : 'h-full'}`}>
          <ConversationList
            conversations={chats}
            onConversationSelected={(uid) => updateSelectedChat(uid)}
            selectedChat={selectedChat}
            variant={variant}
          />
        </div>
      </div>
    </>
  );
}
