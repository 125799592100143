export const Share = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 12.5C6.38071 12.5 7.5 11.3807 7.5 10C7.5 8.61929 6.38071 7.5 5 7.5C3.61929 7.5 2.5 8.61929 2.5 10C2.5 11.3807 3.61929 12.5 5 12.5Z"
      stroke="#080C0F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.75 18.125C15.1307 18.125 16.25 17.0057 16.25 15.625C16.25 14.2443 15.1307 13.125 13.75 13.125C12.3693 13.125 11.25 14.2443 11.25 15.625C11.25 17.0057 12.3693 18.125 13.75 18.125Z"
      stroke="#080C0F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.75 6.875C15.1307 6.875 16.25 5.75571 16.25 4.375C16.25 2.99429 15.1307 1.875 13.75 1.875C12.3693 1.875 11.25 2.99429 11.25 4.375C11.25 5.75571 12.3693 6.875 13.75 6.875Z"
      stroke="#080C0F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.6504 5.72583L7.10352 8.6477"
      stroke="#080C0F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.10352 11.3508L11.6504 14.2727"
      stroke="#080C0F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ShareAlt = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    fill="currentColor"
    width="20"
    height="20"
  >
    <title>Share</title>
    <path
      d="M336 192h40a40 40 0 0140 40v192a40 40 0 01-40 40H136a40 40 0 01-40-40V232a40 40 0 0140-40h40M336 128l-80-80-80 80M256 321V48"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="32"
    />
  </svg>
);

export const Clipboard = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.75 12H17.25"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
    ></path>
    <path
      d="M9.75 8.25C10.1642 8.25 10.5 7.91421 10.5 7.5C10.5 7.08579 10.1642 6.75 9.75 6.75C9.33579 6.75 9 7.08579 9 7.5C9 7.91421 9.33579 8.25 9.75 8.25Z"
      fill="currentColor"
    ></path>
    <path
      d="M14.25 8.25C14.6642 8.25 15 7.91421 15 7.5C15 7.08579 14.6642 6.75 14.25 6.75C13.8358 6.75 13.5 7.08579 13.5 7.5C13.5 7.91421 13.8358 8.25 14.25 8.25Z"
      fill="currentColor"
    ></path>
    <path
      d="M9.75 17.25C10.1642 17.25 10.5 16.9142 10.5 16.5C10.5 16.0858 10.1642 15.75 9.75 15.75C9.33579 15.75 9 16.0858 9 16.5C9 16.9142 9.33579 17.25 9.75 17.25Z"
      fill="currentColor"
    ></path>
    <path
      d="M14.25 17.25C14.6642 17.25 15 16.9142 15 16.5C15 16.0858 14.6642 15.75 14.25 15.75C13.8358 15.75 13.5 16.0858 13.5 16.5C13.5 16.9142 13.8358 17.25 14.25 17.25Z"
      fill="currentColor"
    ></path>
    <path
      d="M17.25 6.75H14.25V8.25H17.25C19.32 8.25 21 9.93 21 12C21 14.07 19.32 15.75 17.25 15.75H14.25V17.25H17.25C20.145 17.25 22.5 14.895 22.5 12C22.5 9.105 20.145 6.75 17.25 6.75Z"
      fill="currentColor"
    ></path>
    <path
      d="M6.75 15.75C4.68 15.75 3 14.07 3 12C3 9.93 4.68 8.25 6.75 8.25H9.75V6.75H6.75C3.855 6.75 1.5 9.105 1.5 12C1.5 14.895 3.855 17.25 6.75 17.25H9.75V15.75H6.75Z"
      fill="currentColor"
    ></path>
  </svg>
);
