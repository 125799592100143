import { db, functions, httpsCallable } from '@util/firebase';
import { ChatDocument, ChatMessageDocument } from '@util/types/firestore/chat';
import { OfferDocument } from '@util/types/firestore/offers';
import {
  query,
  where,
  getDocs,
  collection,
  CollectionReference,
  getDoc,
  doc,
  setDoc,
  updateDoc,
  getCountFromServer,
} from 'firebase/firestore';
import { formatCurrency } from '@util/index';
import { OrderDocument } from '@util/types/firestore/order';
import { redactPhonesAndEmailsFromStr } from '../messages';

const messagesRef = collection(
  db,
  'messages'
) as CollectionReference<ChatDocument>;
const offersRef = collection(
  db,
  'offers'
) as CollectionReference<OfferDocument>;
const getDocRef = (id: string) => doc<OfferDocument>(offersRef, id);

export const getOfferId = () => {
  const newDocRef = doc(offersRef);
  const id = newDocRef.id;
  return id;
};

export async function createOffer(offer: OfferDocument) {
  if (!offer.is_auction) {
    const message: ChatMessageDocument = {
      uid: offer.buyer_id,
      content: offer.message!,
      is_img: false,
      created_at: Date.now(),
      skip_notification: true,
    };
    const chat: ChatDocument = {
      product_id: offer.product_id,
      buyer_id: offer.buyer_id,
      seller_id: offer.seller_id,
      is_offer: true,
      uids: offer.uids,
      is_bot: false,
      flagged: false,
      from_web: true,
      last_time: Date.now(),
      created_at: Date.now(),
      messages: [message],
      unread: {
        [offer.buyer_id]: true,
        [offer.seller_id]: true,
      },
    };
    offer.chat_id = await createChat(chat);

    const chatRef = doc(messagesRef, offer.chat_id);
    await updateDoc(chatRef, { offer_id: offer.id });
  }
  const docRef = getDocRef(offer.id!);
  await setDoc(docRef, offer);
}

export async function createOfferIntent(amount: number, customer?: string) {
  const res = await httpsCallable<
    { amount: number; customer?: string },
    { pi_id: string; data: string }
  >(
    functions,
    'createOfferIntent'
  )({ amount, customer });
  return res.data;
}

export async function updateOfferIntent(args: OfferDocument) {
  const res = await httpsCallable<OfferDocument, OfferDocument>(
    functions,
    'updateOfferIntent'
  )(args);
  return res.data;
}

export async function acceptOffer(offer_id: string) {
  const res = await httpsCallable<{ offer_id: string }, OrderDocument>(
    functions,
    'acceptOffer'
  )({ offer_id });
  return res.data;
}

export async function createChat(chat: ChatDocument) {
  const docRef = doc(messagesRef);
  chat.id = docRef.id;
  await setDoc(docRef, chat);
  return docRef.id;
}

export async function getOffer(offer_id: string): Promise<OfferDocument> {
  const offerDoc = await getDoc(doc(offersRef, offer_id));
  return (offerDoc.data() as OfferDocument) ?? null;
}

export const getOfferMessage = (
  productTitle: string,
  price: number,
  shipping: number,
  tax: number,
  customMsg?: string
): string => {
  const priceStr = formatCurrency(price);
  const shippingStr = formatCurrency(shipping);
  const taxStr = formatCurrency(tax);
  const total = formatCurrency(price + shipping + tax);
  let message = `New Offer: ${productTitle} for ${total} (${priceStr} Offer + ${shippingStr} Shipping + ${taxStr} Tax)`;
  if (customMsg)
    message +=
      ' with the following message: ' + redactPhonesAndEmailsFromStr(customMsg);
  return message;
};

export async function getOfferChats(uid: string) {
  const q = query(
    messagesRef,
    where('uids', 'array-contains', uid),
    where('is_offer', '==', true)
  );
  const { docs } = await getDocs(q);
  return docs.map((d) => d.data());
}

export function setOfferState(id: string, state: number) {
  return updateDoc(getDocRef(id), { state });
}

export async function getOfferById(id: string) {
  const doc = await getDoc(getDocRef(id));
  return doc.data();
}
export async function getPendingOfferCount(seller_id: string) {
  const q = query(
    offersRef,
    where('seller_id', '==', seller_id),
    where('is_auction', '==', false),
    where('state', '==', 0)
  );
  const res = await getCountFromServer(q);
  return res.data()?.count ?? 0;
}

export async function getActiveOffers(uid: string) {
  const q = query(
    offersRef,
    where('buyer_id', '==', uid),
    where('state', '==', 0)
  );
  const { docs } = await getDocs(q);
  return docs.map((d) => d.data());
}
