import { functions, httpsCallable } from '@util/firebase';
import { GetTaxArgs, GetTaxResponse } from './types';

// total arg is the subtotal
export async function getTax(args: GetTaxArgs): Promise<GetTaxResponse> {
  const response = await httpsCallable<GetTaxArgs, GetTaxResponse>(
    functions,
    'getTax'
  )(args);
  return response.data;
}
