import BaseDrawer from '@c/drawers/BaseDrawer';
import CloseButton from '@c/icons/buttons/CloseButton';
import { markAsRead } from '@util/firestore/messages';
import { ChatDocument } from '@util/types/firestore/chat';
import { useAuth } from 'context/AuthContext';
import { useChat } from 'context/ChatContext';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import ConversationList from './dashboard/ConversationList';
import { OrderPreview, ProductPreview } from './dashboard/MessageCenter';
import MessageOffer from './dashboard/MessageOffer';
import MessageWindow from './dashboard/MessageWindow';

interface ChatProps {
  chat?: ChatDocument;
}

const Chat = ({ chat }: ChatProps) => {
  const { user } = useAuth();
  const { chatOpen, setChatOpen, chats } = useChat();
  const [selectedChat, setSelectedChat] = useState<ChatDocument | undefined>(
    chat
  );
  useEffect(() => {
    if (selectedChat) {
      setSelectedChat(chats?.find((chat) => chat.id === selectedChat.id));
      if (
        selectedChat.id &&
        user?.uid &&
        selectedChat.unread?.[user.uid] &&
        chatOpen
      )
        markAsRead(selectedChat.id, user.uid);
    }
  }, [selectedChat, chats, chatOpen, user?.uid]);

  return (
    <BaseDrawer
      show={chatOpen}
      side={'right'}
      dismiss={() => setChatOpen(false)}
    >
      <div className="flex h-full flex-col">
        {!selectedChat ? (
          <>
            <div className="flex shrink justify-between px-[1.6rem] pt-[2.4rem] xl:px-[2.4rem]">
              <h5 className="font-semibold">All Messages</h5>
              <CloseButton onClick={() => setChatOpen((prev) => !prev)} />
            </div>
            <div className="h-full grow overflow-y-auto">
              <ConversationList
                conversations={chats}
                onConversationSelected={(chat) => setSelectedChat(chat)}
                selectedChat={selectedChat}
                variant="messages"
              />
            </div>
            <div className="flex w-full shrink justify-center py-[1.6rem]">
              <Link
                href="/dashboard/messages"
                className="w-fit text-center font-medium text-brand-primary"
                onClick={() => {
                  setChatOpen(false);
                }}
              >
                View all messages
              </Link>
            </div>
          </>
        ) : (
          <>
            <div className="h-full grow overflow-y-auto">
              <MessageWindow
                chat={selectedChat}
                key={selectedChat.id}
                previewSlot={
                  selectedChat.offer_id ? (
                    <MessageOffer offer_id={selectedChat.offer_id} />
                  ) : selectedChat.product_id ? (
                    <ProductPreview productId={selectedChat.product_id} />
                  ) : selectedChat.order_id ? (
                    <OrderPreview
                      orderId={selectedChat.order_id}
                    ></OrderPreview>
                  ) : null
                }
                goBack={() => {
                  setSelectedChat(undefined);
                }}
              />
            </div>
          </>
        )}
      </div>
    </BaseDrawer>
  );
};

export default Chat;
