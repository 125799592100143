import { GearIcon, ClockOutlineIcon } from '@c/icons';
import { SUPPORT_ID } from '@c/modals/HelpRequestModal';
import { Popover } from '@headlessui/react';
import { useQuery } from '@tanstack/react-query';
import Avatar from '@ui/Avatar';
import Button from '@ui/Button';
import SafeImage from '@ui/SafeImage';
import { deleteMessage } from '@util/firestore/messages';
import { getUserById } from '@util/firestore/users';
import { formatTimestamp } from '@util/index';
import { ChatDocument, ChatMessageDocument } from '@util/types/firestore/chat';
import { UserDocument } from '@util/types/firestore/users';
import { useAuth } from 'context/AuthContext';
import Link from 'next/link';
import { useState } from 'react';

const IMG_SIZE = 256;

const MessageImage = ({
  img,
  fallbackImg,
  onClick,
}: {
  img: string;
  fallbackImg?: string;
  onClick: (src: string) => void;
}) => {
  return (
    <SafeImage
      src={img}
      key={img}
      height={IMG_SIZE}
      width={IMG_SIZE}
      alt={'img'}
      fallbackSrc={fallbackImg}
      className="cursor-pointer"
      onClick={() => onClick(img)}
    />
  );
};

interface MessageBubbleProps {
  chat: ChatDocument;
  chatMessage: ChatMessageDocument;
  fallbackImg?: string;
  onImageClick: (image: string) => void;
}

interface MessageProps {
  message: ChatMessageDocument;
  timestamp: number;
  isSupport?: boolean;
  user?: UserDocument;
  fallbackImg?: string;
  onImageClick: (image: string) => void;
}

const MessageOutbound = ({
  message,
  timestamp,
  fallbackImg,
  onImageClick,
  user,
  isSupport,
}: MessageProps) => (
  <div className="flex max-w-[80%] flex-col items-end gap-[1.6rem] justify-self-end">
    {!message.is_img ? (
      <div className="flex flex-col items-end gap-[0.4rem]">
        <div className="flex gap-[0.8rem]">
          <div className="flex flex-col self-end rounded-chatRight bg-brand-lightest-gray p-[1.6rem] text-right text-[1.6rem]">
            <p className="text-[1.3rem]">{message.content}</p>  
          </div>
          {user?.roles?.admin ? (
            <div className="flex h-min items-center justify-center rounded-full bg-brand-secondary p-3 text-brand-white">
              <GearIcon />
            </div>
          ) : isSupport ? (
            <Link
              className="shrink-0 "
              href={`/shop-seller/${user?.username_slug}`}
            >
              <Avatar size="extraSmall" user={user} />
            </Link>
          ) : null}
        </div>
        {!!timestamp && (
          <p className="mr-4 text-[1rem] text-brand-gray">
            {formatTimestamp(timestamp)}
          </p>
        )}
      </div>
    ) : (
      <MessageImage
        img={message.thumbnail ?? message.content}
        fallbackImg={fallbackImg}
        onClick={onImageClick}
      />
    )}
  </div>
);

const MessageInbound = ({
  message,
  timestamp,
  isSupport,
  user,
  onImageClick,
}: MessageProps) => (
  <div className="flex max-w-[80%] items-end  gap-[1.2rem] self-start py-[1.6rem] text-[1.6rem]">
    {isSupport || message.uid === 'GearBot' ? (
      <div className="flex h-min items-center justify-center rounded-full bg-brand-secondary p-3 text-brand-white">
        <GearIcon />
      </div>
    ) : (
      <Link
        className="shrink-0 pb-[1.9rem]"
        href={`/shop-seller/${user?.username_slug}`}
      >
        <Avatar size="extraSmall" user={user} />
      </Link>
    )}
    <div className="flex flex-col items-start gap-[1.6rem]">
      {!message.is_img ? (
        <div className="flex flex-col gap-[0.4rem]">
          <div
            className={`flex flex-col gap-[0.4rem] rounded-chatLeft  p-[1.6rem] text-left ${
              isSupport
                ? 'bg-brand-light-black text-brand-white'
                : 'bg-brand-primary text-brand-white'
            }`}
          >
            <p className="text-[1.3rem]">{message.content}</p>
          </div>
          {!!timestamp && (
            <p className="mr-4 self-end text-[1rem] text-brand-gray">
              {formatTimestamp(timestamp)}
            </p>
          )}
        </div>
      ) : (
        <MessageImage
          img={message.thumbnail ?? message.content}
          onClick={onImageClick}
        />
      )}
    </div>
  </div>
);

export default function MessageBubble({
  chat,
  chatMessage,
  fallbackImg,
  onImageClick,
}: MessageBubbleProps) {
  const { userDoc } = useAuth();

  const { data: messageUser } = useQuery(
    ['user', chatMessage.uid],
    () => getUserById(chatMessage.uid),
    {
      enabled: !!chatMessage.uid,
    }
  );
  const [menuOpen, setMenuOpen] = useState(false);

  if (!userDoc) return null;

  const onRightClick = (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (userDoc?.roles?.support) {
      ev.preventDefault();
      ev.stopPropagation();
      setMenuOpen(true);
    }
  };

  const isOutbound = chatMessage.uid === userDoc?.uid;

  return (
    <div onContextMenu={onRightClick} className="grid">
      {isOutbound ? (
        <MessageOutbound
          message={chatMessage}
          timestamp={chatMessage.created_at ?? 0}
          fallbackImg={fallbackImg}
          user={messageUser}
          isSupport={userDoc.roles?.admin}
          onImageClick={onImageClick}
        />
      ) : (
        <MessageInbound
          message={chatMessage}
          timestamp={chatMessage.created_at ?? 0}
          isSupport={chatMessage.uid === SUPPORT_ID}
          user={messageUser}
          fallbackImg={fallbackImg}
          onImageClick={onImageClick}
        />
      )}
      {menuOpen && (
        <Popover className={isOutbound ? 'text-right' : ''}>
          <Popover.Panel static>
            <Button
              width="fluid"
              align={isOutbound ? 'right' : 'left'}
              type="text"
              text="Delete message"
              noXPadding
              onClick={() => deleteMessage(chat.id!, chatMessage)}
            />
            <Button
              width="fluid"
              align={isOutbound ? 'right' : 'left'}
              type="text"
              text="Cancel"
              noXPadding
              onClick={() => setMenuOpen(false)}
            />
          </Popover.Panel>
        </Popover>
      )}
    </div>
  );
}
