import React from 'react';
import { cva, VariantProps } from 'class-variance-authority';

const styles = cva('block mb-[0.8rem] items-center ', {
  variants: {
    required: {
      true: 'after:content-["*"] after:ml-0.5 after:text-red-500',
    },
  },
  defaultVariants: {
    required: false,
  },
});

interface Props extends VariantProps<typeof styles> {
  value: string;
  children: React.ReactNode;
  errorMessage?: string;
  labelClasses?: string;
}

const FormLabel = ({
  children,
  value,
  errorMessage,
  required,
  labelClasses,
}: Props) => {
  return (
    <div className="block w-full text-input">
      <div className="flex justify-between">
        <span className={styles({ required }) + ' ' + labelClasses}>
          {value}
        </span>
        {!!errorMessage && (
          <span className="text-[1.2rem] text-brand-red">{errorMessage}</span>
        )}
      </div>
      {children}
    </div>
  );
};

export default FormLabel;
