import { useQuery } from '@tanstack/react-query';
import { getPendingOfferCount } from '@util/firestore/offers';
import { getPendingOrders } from '@util/firestore/orders';
import { useAuth } from 'context/AuthContext';
import { useChat } from 'context/ChatContext';

export const useNotifications = () => {
  const { userDoc } = useAuth();

  //  Messages
  const { getUnreadCount } = useChat();
  const unreadCount = getUnreadCount();

  // Orders
  const { data: pendingOrders } = useQuery(
    ['pendingOrdersCount', userDoc?.uid],
    () => getPendingOrders(userDoc?.uid ?? '')
  );
  const pendingOrdersCount = userDoc?.uid
    ? pendingOrders?.reduce(
        (acc, order) =>
          order.sellers![userDoc.uid].some((i) => i.state === 0)
            ? acc + 1
            : acc,
        0
      )
    : 0;

  // Offers
  const { data: pendingOffersCount } = useQuery(
    ['pendingOffersCount', userDoc?.uid],
    () => getPendingOfferCount(userDoc?.uid ?? '')
  );

  const requiresStripeDetails = userDoc?.stripe?.requirements?.currently_due?.length;

  // Total
  const total =
    unreadCount +
    (pendingOrdersCount ?? 0) +
    (pendingOffersCount ?? 0) +
    (requiresStripeDetails ? requiresStripeDetails : 0);

  return {
    unreadCount,
    pendingOrdersCount,
    pendingOffersCount,
    total,
    requiresStripeDetails,
  };
};
