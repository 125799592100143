import { BaseIconProps } from './types';

export function Search({ width = 20, height = 20 }: BaseIconProps) {
  return (
    <svg viewBox={`0 0 20 20`} height={height} width={width} fill="none">
      <path
        d="M9.06235 15.6251C12.6867 15.6251 15.6248 12.687 15.6248 9.06262C15.6248 5.43825 12.6867 2.50012 9.06235 2.50012C5.43798 2.50012 2.49985 5.43825 2.49985 9.06262C2.49985 12.687 5.43798 15.6251 9.06235 15.6251Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.7036 13.7034L17.5005 17.5002"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
