import SafeImage from '@ui/SafeImage';
import { ProductDocument } from '@util/types/firestore/products';
import { getProductUrl } from '@util/urlHelpers';
import Link from 'next/link';

interface ProductInfoProps {
  product: ProductDocument;
}
const ProductInfo = ({ product }: ProductInfoProps) => (
  <div className="flex items-center gap-[1.2rem]">
    <Link href={getProductUrl(product.slug)} className="shrink-0">
      <SafeImage
        key={product.images[0]?.thumb}
        className="h-[6.4rem] w-[6.4rem] rounded-2xl "
        alt={product.title}
        src={product.images[0]?.thumb}
      />
    </Link>
    <div className="flex flex-col gap-[0.2rem]">
      <Link
        className=" leading-[2.2rem] text-brand-lightest-black"
        href={getProductUrl(product.slug)}
        title={`Shop ${product.title}`}
      >
        {product.title}
      </Link>
      <span className="font-semibold  leading-[2.2rem] text-brand-lightest-black">
        $
        {product.price.toLocaleString(undefined, {
          minimumFractionDigits: 2,
        })}
      </span>
    </div>
  </div>
);

export default ProductInfo;
