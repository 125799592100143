import { ChevronLeftIcon, CloseIcon, PaperclipIcon, SendIcon } from '@c/icons';
import { SUPPORT_ID } from '@c/modals/HelpRequestModal';
import ProductViewerModal from '@c/modals/ProductViewerModal';
import { useQuery } from '@tanstack/react-query';
import Avatar from '@ui/Avatar';
import Button from '@ui/Button';
import SafeImage from '@ui/SafeImage';
import {
  flagChat,
  getChatById,
  hideChat,
  uploadChatImage,
} from '@util/firestore/messages';
import { getOffer } from '@util/firestore/offers';
import { getUserDocument } from '@util/firestore/users';
import { useOnlineStatus } from '@util/hooks/useOnlineStatus';
import {
  ChatDocument,
  ChatMessageDocument,
  ChatMessagesDocumentSchema,
} from '@util/types/firestore/chat';
import { getCdnImageUrls } from '@util/urlHelpers';
import { useAuth } from 'context/AuthContext';
import { useChat } from 'context/ChatContext';
import { useToastContext } from 'context/ToastContext';
import { onSnapshot } from 'firebase/firestore';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import MessageBubble from './MessageBubble';
import MessageFromBot from './MessageFromBot'

export interface MessageWindowProps {
  goBack: () => void;
  chat?: ChatDocument;
  previewSlot?: React.ReactNode;
}

export default function MessageWindow({
  goBack,
  chat,
  previewSlot,
}: MessageWindowProps) {
  const { sendMessage } = useChat();
  const router = useRouter();
  const [message, setMessage] = React.useState<string>('');
  const { userDoc } = useAuth();
  const messageWindowRef = React.useRef<HTMLDivElement>(null);
  const [file, setFile] = React.useState<File>();
  const [fallBackImages, setFallBackImages] = React.useState(
    new Map<string, string>()
  );
  
  const [imageModal, setImageModal] = React.useState<{
    isOpen: boolean;
    index: number;
    images: { full: string; thumb: string }[];
  }>({ isOpen: false, index: 0, images: [] });
  
  const { data: offer } = useQuery({
    queryKey: ['offer', chat?.offer_id ?? ''],
    queryFn: () => getOffer(chat?.offer_id ?? ''),
    enabled: !!chat?.offer_id,
  });

  const recipient = !chat
    ? undefined
    : chat.uids?.[0] === userDoc?.uid
    ? chat.uids?.[1]
    : chat.uids?.[0];
  const { data: recipientDoc } = useQuery({
    queryKey: ['user', recipient],
    queryFn: () =>
      getUserDocument({
        uid: recipient ?? '',
      }),
    enabled: !!recipient,
  });

  const handleSubmit = async () => {
    if (!userDoc || !chat?.id) return;
    const messageDoc = ChatMessagesDocumentSchema.parse({
      uid: userDoc.uid,
      content: message,
      created_at: new Date().getTime(),
    });
    // Send another message with the image url
    if (file) {
      const messageDocCopy = ChatMessagesDocumentSchema.parse({
        ...messageDoc,
      });
      const path = await uploadChatImage(file, userDoc.uid, chat.id);
      const { full, thumb } = getCdnImageUrls(path);
      messageDocCopy.thumbnail = thumb;
      // Create a local image to use as a fallback since the cdn isn't available for a second after uploading
      const dataUrl = URL.createObjectURL(file);
      setFallBackImages((prev) => new Map(prev).set(chat.id!, dataUrl));
      messageDocCopy.content = full;
      messageDocCopy.is_img = true;
      sendMessage(messageDocCopy, chat.id);
      setFile(undefined);
    } else {
      if (messageDoc.content) {
        sendMessage(messageDoc, chat.id);
        setMessage('');
      }
    }
    // scroll to bottom
    setTimeout(() => {
      if (messageWindowRef.current) {
        messageWindowRef.current.scrollTop =
          messageWindowRef.current.scrollHeight;
      }
    });
  };

  useEffect(() => {
    setTimeout(() => {
      if (messageWindowRef.current) {
        messageWindowRef.current.scrollTop =
          messageWindowRef.current.scrollHeight;
      }
    });
  }, []);

  const onImageClick = (src: string) => {
    let index: number | undefined;
    const images: { thumb: string; full: string }[] = [];
    messages
      ?.filter((m) => m.is_img)
      .forEach((msg, i) => {
        if (index === undefined && src === msg.thumbnail) index = i;
        images.push({ thumb: msg.thumbnail ?? msg.content, full: msg.content });
      });
    if (index === undefined) index = 0;
    setImageModal({ images, index, isOpen: true });
  };

  const { StatusLabel } = useOnlineStatus(recipientDoc?.uid ?? '');

  // subscribe to real time messages
  const [messages, setMessages] = useState<ChatMessageDocument[] | null>(null);
  const chatsQuery = useMemo(() => getChatById(chat?.id ?? ''), [chat]);

  useEffect(() => {
    const unsubscribe = onSnapshot(chatsQuery, (snapshot) => {
      const data = snapshot.data();
      setMessages(data?.messages ?? []);
      setTimeout(() => {
        if (messageWindowRef.current) {
          messageWindowRef.current.scrollTop =
            messageWindowRef.current.scrollHeight;
        }
      });
    });
    return () => {
      unsubscribe();
    };
  }, [chatsQuery]);

  const textInput = useRef<HTMLTextAreaElement | null>(null);

  const autoIncreaseHeight = () => {
    if (textInput.current) {
      textInput.current.style.height = 'auto';
      textInput.current.style.height = `${textInput.current.scrollHeight}px`;
    }
  };
  const { showSuccessToast } = useToastContext();
  if (!userDoc || !chat) return null;

  return (
    <div className="flex h-full w-full flex-col">
      <div className="border-b border-brand-lightest-gray shadow-sm">
        <div className="flex justify-between gap-[1.6rem] p-[1.6rem]">
          <div className="flex w-full">
            <Button
              leadingIcon={<ChevronLeftIcon />}
              type="text"
              width="small"
              onClick={goBack}
            />  

            {recipientDoc && recipientDoc.uid !== SUPPORT_ID && (
              <Link
                className="flex items-center"
                href={`/shop-seller/${recipientDoc.username_slug}`}
              >
                <Avatar size="small" user={recipientDoc} />
                <div className="ml-4">
                  <h3 className="font-semibold">{recipientDoc.username}</h3>
                  <StatusLabel />
                </div>
              </Link>
            )}
            {recipientDoc && recipientDoc.uid === SUPPORT_ID && (
              <span className="flex flex-col">
                <h3 className="font-semibold">Gear Focus Support</h3>
                <StatusLabel />
              </span>
            )}

            {userDoc?.roles?.support && (
              <div className="ml-auto flex items-center gap-[1.6rem]">
                {userDoc?.roles?.admin && (
                  <Button
                    height="small"
                    type="tertiary"
                    width="small"
                    text="Backend"
                    href={`https://console.firebase.google.com/project/gear-focus/firestore/data/~2Fmessages~2F${chat.id}`}
                    target="_blank"
                  />
                )}
                <Button
                  height="small"
                  type="tertiary"
                  width="small"
                  text={chat.flagged ? 'Unflag' : 'Flag'}
                  onClick={() => flagChat(chat.id!, !chat.flagged)}
                />
                <Button
                  height="small"
                  type="tertiary"
                  width="small"
                  text="Hide"
                  onClick={() =>
                    hideChat(chat.id!, userDoc.uid).then(() => {
                      //TODO: remove this
                      router.refresh();
                    })
                  }
                />
                <Button
                  height="small"
                  type="tertiary"
                  width="small"
                  text="Share"
                  onClick={() =>
                    // copy to clipboard
                    navigator.clipboard
                      .writeText(
                        `${window.location.origin}/dashboard/messages/${chat.id}`
                      )
                      .then(() => {
                        showSuccessToast('Copied to clipboard');
                      })
                  }
                />
              </div>
            )}
          </div>
        </div>

        {!!previewSlot && (
          <>
            <hr />
            {previewSlot}
          </>
        )}
      </div>
      {/* messages */}
      <div
        ref={messageWindowRef}
        className="relative flex h-full w-full flex-col items-center overflow-y-scroll pb-[.6rem] pl-[1.6rem] pr-[.6rem] pt-4"
      >
        <div className="relative flex w-full grow flex-col justify-start gap-[1.6rem]">
          {chat.is_bot && messages ? (
            <MessageFromBot 
              chat={chat}
              messages={messages}
              key={chat.created_at}
            />
          ) : (
            messages?.map((message, i) => (
              <MessageBubble
                chat={chat}
                key={message.created_at + '-' + i ?? i}
                chatMessage={message}
                fallbackImg={fallBackImages.get(chat.id!)}
                onImageClick={(src) => onImageClick(src)}
              />
            ))
          )}

          {imageModal.isOpen && (
            <ProductViewerModal
              isOpen={imageModal.isOpen}
              dismiss={() => setImageModal({ ...imageModal, isOpen: false })}
              images={imageModal.images}
              initialImage={imageModal.index}
            />
          )}

          {offer?.state === 2 && (
            <span className="py-6 text-center font-bold text-brand-gray">
              Offer has ended.{' '}
              {offer.buyer_id === userDoc.uid ? 'Seller' : 'You'} declined the
              offer.
            </span>
          )}
        </div>
      </div>
      <div className={`flex items-center gap-[1.6rem] border-t border-brand-lightest-gray bg-brand-white p-[1.6rem] ${chat.is_bot ? 'opacity-50' : ''}`}>
        {!file && (
          <textarea
            disabled={chat.is_bot}
            ref={textInput}
            className={`relative flex grow flex-row items-center justify-between gap-[0.8rem] overflow-hidden rounded-2xl bg-brand-darker-white px-[2rem] text-[1.6rem]`}
            value={message}
            onChange={(e) => {
              autoIncreaseHeight();
              setMessage(e.target.value);
            }}
            placeholder={`${chat.is_bot ? 'You cannot reply to an automated message' : 'Write your message here...'}`}
            onKeyDown={(e) => {
              if (e.key !== 'Enter') return;
              handleSubmit();
            }}
          />
        )}

        <input
          id="file-input"
          type="file"
          accept="image/*"
          className="hidden"
          disabled={chat.is_bot}
          onChange={(e) => {
            if (!e.target.files) return;
            setFile(e.target.files[0]);
          }}
        />
        {file ? (
          <div className="relative flex w-full justify-center">
            <div className="relative">
              <SafeImage
                src={URL.createObjectURL(file)}
                alt="preview"
                height={64}
                width={64}
                className="h-[6.4rem] min-h-[6.4rem]  w-[6.4rem] min-w-[6.4rem] rounded-2xl"
              />

              <button
                disabled={chat.is_bot}
                className="absolute right-0 top-0 flex h-[2.4rem] w-[2.4rem] items-center justify-center rounded-full bg-brand-red text-white"
                onClick={() => {
                  setFile(undefined);
                }}
              >
                <CloseIcon />
              </button>
            </div>
          </div>
        ) : (
          <button
            disabled={chat.is_bot}
            onClick={() => {
              const input = document.querySelector(
                '#file-input'
              ) as HTMLInputElement;
              input.click();
            }}
            className="h-[6.4rem]"
          >
            <div className="relative">
              <PaperclipIcon />
            </div>
          </button>
        )}

        <button
          disabled={chat.is_bot}
          type="button"
          className=
            {`flex aspect-square h-[4.4rem] flex-col items-center justify-center rounded-full
            border-2 border-brand-secondary bg-brand-secondary text-white transition-colors
            ${chat.is_bot ? '' : 'hover:bg-transparent hover:text-brand-secondary'}`}
          onClick={handleSubmit}
        >
          <SendIcon />
        </button>
      </div>
    </div>
  );
}
