import Button from '@ui/Button';
import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { useAuth } from 'context/AuthContext';
import BaseModal from './BaseModal';
import { daysUntilExpiration } from '@util/index';

import PaymentProcessingModal from '@c/modals/PaymentProcessing';
import SelectPaymentCard from '@c/cards/SelectPaymentCard';

import { ProductDocument } from '@util/types/firestore/products';
import { ChatDocument } from '@util/types/firestore/chat';

import { bumpProduct, updateStatus, renewProduct } from '@util/firestore/products';
import { updateChat } from '@util/firestore/messages';
import { chargeCreditCard } from '@util/firestore/payments';

interface ConfirmModalProps {
  product: ProductDocument;
  chat?: ChatDocument;
  index?: number | null;
  isOpen: boolean;
  dismiss: (confirm: boolean) => void;
}

function RenewListingModal({
  product,
  chat,
  index,
  isOpen,
  dismiss,
}: ConfirmModalProps) {
  const { userDoc } = useAuth();
  const router = useRouter();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [bumpModal, setBumpModal] = useState<boolean>(false);
  const [paymentMethodId, setPaymentMethodId] = useState<string>('');

  const days = daysUntilExpiration(product.date_added ?? product.created)

  const renewListing = async (status = "Renew Listing") => {
    if (!product) return;

    setLoading(true)

    try {
      if (chat && chat.messages && (index !== null && index !== undefined)) {
        const message = chat.messages[index]
  
        const updatedMessage = {
          ...message,
          action: { type: 'renewal', disabled: true, click: 'Renew Listing' }
        };
    
        const updatedMessages = [
          ...chat.messages.slice(0, index),
          updatedMessage,
          ...chat.messages.slice(index + 1)
        ];
  
        const updatedChat = { ...chat, messages: updatedMessages };
        await updateChat(updatedChat);
      } else {
        console.log("Not From Chat Message")
      }
  
      await updateStatus(product.id, { created_at: Date.now(), status: status})
      await renewProduct(product.id)
  
      router.push(`/products/${product.slug}?r=${Date.now()}`);
    } catch (e) {
      console.log("Error Renewing Listnig", e)
    }

    setLoading(false)
  }

  const handleBumpConfirmation = async () => {
    setLoading(true)

    try {
      if (userDoc && userDoc.customer_id && paymentMethodId) {
        const res = await chargeCreditCard(paymentMethodId, userDoc.customer_id, 0.99, 'Gear Focus Bump')
    
        if (res) {
          await bumpProduct(product.id)
          await renewListing("Bump Listing")
        }
      }
    } catch (e) {
      console.log("Error Bumping Listing", e)
    }

    setLoading(false)    
  }

  return (
    <BaseModal isOpen={isOpen} hideCloseIcon>
    {!bumpModal ? (
      <div className="flex flex-col items-center gap-[2.4rem] rounded-3xl"> 
          <div className="flex flex-col items-center gap-[0.8rem]">
            <h2 className="text-center text-[2.4rem] font-semibold leading-[3.2rem] text-brand-light-black">
              Oh no! Your listing {days < 1 ? 'has expired!' : 'is about to expire.'}
            </h2>
            <span className="text-center leading-[2.2rem] text-brand-gray">
              Good thing you can renew your '{product.title}' listing for another 30 days.
            </span>
          </div>

          <div className="bg-gray-100 p-6 rounded-lg">
            <p className="mb-4">
              Renewing your listing keeps it active, ensuring continued exposure to potential buyers. For just $0.99, you can also bump your listing to the top, giving it prime visibility and increasing the chances of a quick sale.  
            </p>
            <p className="mb-4">
              Here's why you should consider bumping:
            </p>
            <ul className="list-disc pl-6 mb-4">
              <li className="mb-2"><span className="font-semibold">Stay Competitive:</span> Keep your listing visible among newer listings and maintain relevance in the marketplace.</li>
              <li className="mb-2"><span className="font-semibold">Increase Exposure:</span> Move your listing back to the top of search results, attracting more views from interested buyers.</li>
              <li className="mb-2"><span className="font-semibold">Faster Sales:</span> With increased visibility, you're more likely to receive inquiries and offers sooner, accelerating the selling process.</li>
            </ul>
            <div className="flex flex-col items-center py-5">
              <Button
                type="primary"
                text="Bump For $0.99"
                loading={isLoading}
                onClick={() => {
                  setBumpModal(true);
                }}
              />
            </div>
          </div>

          <div className="flex items-center justify-center text-brand-secondary">
            <Button type="text" height="extraSmall" text="Close"loading={isLoading} onClick={() => dismiss(false)} />
            <Button type="text" height="extraSmall" text="Renew Listing" loading={isLoading} onClick={() => renewListing() } />
          </div>
      </div>
    ) : ( 
      <div className="flex flex-col items-center gap-[2.4rem] rounded-3xl">
        <div className="flex flex-col items-center gap-[0.8rem]">
            <h2 className="text-center text-[2.4rem] font-semibold leading-[3.2rem] text-brand-light-black">
              Great! Let's confirm bumping your listing!
            </h2>
            <span className="text-center leading-[2.2rem] text-brand-gray">
              By confirming, you authorize a one-time payment of $0.99 to be charged to the credit card on file.
            </span>
        </div>
          
          <div className="bg-gray-100 p-6 rounded-lg">
            <p className="pb-5">
              <span className="font-semibold">Note:</span> Bumping your listing will move it to the top, increasing visibility and chances of a quick sale.
            </p>
            
            <SelectPaymentCard 
              onPaymentMethodChange={(id) => {
                setPaymentMethodId(id)
              }}
            />
          </div>

        <div className="flex justify-center">
          <Button
            type="primary"
            text="Confirm Bump"
            disabled={!paymentMethodId}
            loading={isLoading}
            onClick={() => {
              handleBumpConfirmation();
            }}
          />
          <Button
            type="text"
            text="No Thanks"
            loading={isLoading}
            onClick={() => {
              setBumpModal(false);
            }}
          />
        </div>
      
        <PaymentProcessingModal open={isLoading} />
      </div>
    )}
    </BaseModal>
  );
}

export default RenewListingModal;
