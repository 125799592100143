import { SvgIconProps } from './types';
export function Heart({ pathProps, svgProps }: SvgIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={pathProps?.fill ?? 'none'}
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M12.5345 19.8662L20.1283 12.2725C21.9939 10.3975 22.2658 7.33187 20.5033 5.37249C20.0612 4.87872 19.5232 4.48026 18.922 4.20145C18.3208 3.92264 17.669 3.76933 17.0066 3.75091C16.3441 3.73248 15.6848 3.84932 15.0691 4.09428C14.4533 4.33924 13.8939 4.70718 13.4251 5.17562L12.0001 6.60999L10.772 5.37249C8.89701 3.50687 5.83138 3.23499 3.872 4.99749C3.37823 5.43951 2.97977 5.97756 2.70096 6.57877C2.42215 7.17999 2.26885 7.83173 2.25042 8.49419C2.23199 9.15665 2.34883 9.81591 2.59379 10.4317C2.83875 11.0475 3.20669 11.6068 3.67513 12.0756L11.4658 19.8662C11.608 20.0071 11.8 20.0861 12.0001 20.0861C12.2003 20.0861 12.3923 20.0071 12.5345 19.8662V19.8662Z"
        stroke={pathProps?.stroke ?? 'currentColor'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function HeartStraight() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.35634 13.2429L13.4188 8.18036C14.6626 6.93036 14.8438 4.88661 13.6688 3.58036C13.3742 3.25118 13.0155 2.98554 12.6146 2.79966C12.2138 2.61379 11.7793 2.51159 11.3377 2.4993C10.8961 2.48702 10.4566 2.56491 10.046 2.72822C9.63552 2.89152 9.26261 3.13682 8.95009 3.44911L8.00009 4.40536L7.18134 3.58036C5.93134 2.33661 3.88759 2.15536 2.58134 3.33036C2.25215 3.62504 1.98651 3.98374 1.80064 4.38455C1.61476 4.78535 1.51256 5.21985 1.50028 5.66149C1.488 6.10313 1.56589 6.54264 1.72919 6.95316C1.8925 7.36368 2.13779 7.73659 2.45009 8.04911L7.64384 13.2429C7.73863 13.3367 7.86666 13.3894 8.00009 13.3894C8.13351 13.3894 8.26154 13.3367 8.35634 13.2429V13.2429Z"
        stroke="#808080"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
