import {
  MultipleQueriesQuery,
  MultipleQueriesResponse,
} from '@algolia/client-search';
import algoliasearch from 'algoliasearch/lite';

const algoliaClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_ID!,
  process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_KEY!
);
export const searchClient = {
  ...algoliaClient,
  // Why? skip empty reads.
  search<TObject>(
    requests: readonly MultipleQueriesQuery[]
  ): Readonly<Promise<MultipleQueriesResponse<TObject>>> {
    if (requests.every(({ params }) => !params?.query)) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
          processingTimeMS: 0,
          hitsPerPage: 0,
          exhaustiveNbHits: false,
          query: '',
          params: '',
        })),
      });
    }
    return algoliaClient.search(requests);
  },
};
