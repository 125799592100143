import { TabButton, TabItem } from '@ui/Tabs';
import { ReactNode, useState } from 'react';
type UseTabsProps = {
  full?: boolean;
  tabItems: TabItem[];
  initial?: number;
};
export function useTabs({ tabItems, full, initial }: UseTabsProps): {
  currentBody: ReactNode;
  tabs: ReactNode;
} {
  const [current, setCurrent] = useState(initial ?? 0);
  const currentBody = tabItems[current]?.contents;
  const tabs: ReactNode = (() => {
    return (
      <div
        className={`flex justify-between overflow-x-auto w-${
          full ? 'full' : 'min'
        }`}
      >
        {tabItems.map((item, i) => {
          const isCurrent = current === i;
          return (
            <TabButton
              key={item.title}
              title={item.title}
              onClick={() => setCurrent(i)}
              isCurrent={isCurrent}
              unreads={item.unreads}
            />
          );
        })}
      </div>
    );
  })();
  return { currentBody, tabs };
}
